
import { Growl } from "primereact/growl";
import React from 'react';
import {
    DEFAULT_PAGE_SIZE,
    DEFAULT_SORT_BY_FOR_OPL_ORDER_QUEUE,
    HIDDEN_FIELDS_MAIN_QUEUE,
    QueueType,
    REFRESH_TABLE_INTERVAL
} from "../../config/constants";
import DealerSupportService from "../../services/DealerSupportService";
import QueueOrderNotificationService from "../../services/QueueOrderNotificationService";
import DealerSupportOrderDataTable from "../../shared/orderDataTable/DealerSupportOrderDataTable";
import OrderFilterLoadService from "../../shared/OrderFilterLoadService";
import OrderFilterPanel, { defaultOnFilterChange } from "../../shared/OrderFilterPanel";
import { createParams } from "../../shared/OrderTableCommon";
import TakeLastFromQueueBtn from "../../shared/TakeLastFromQueueBtn";
import { filtersNotEmptySkipModificationDateTimeFrom, onError, showQueueNewOrdersNotifications, updateTableWithNewData } from "../../shared/Utils";
import '../../static/css/App.css';

class DealerSupportOrders extends React.Component {

    state = {
        orders: [],
        loading: false,
        first: 1,
        rows: DEFAULT_PAGE_SIZE, // Ilość wierszy na stronie
        totalRecords: 0,// Ilośc wszystkich elementów w bazie
        filters: {},
        area: {},
        sortBy: DEFAULT_SORT_BY_FOR_OPL_ORDER_QUEUE,
        filter: false,
        previousModificationTime: new Date(),
        pageNumber: 1,
        startIndex: 0,
        brandSuggestions: null

    }

    constructor(props) {
        super(props);
        this.onPage = this.onPage.bind(this);

    }


    refreshTable() {
        this.getOrders(this.state.filters, this.state.sortBy, this.state.pageNumber, this.state.startIndex, (data) => {
            showQueueNewOrdersNotifications(this,QueueOrderNotificationService.getNewOrdersFromDsMainQueue);
        });

    }

    componentDidMount() {
        this.setState({ filters:  new OrderFilterLoadService().getFilter(QueueType.MAIN), loading: true},()=>{
            this.resetIntervalAndRefreshTable();

            this.setState({
                filter:filtersNotEmptySkipModificationDateTimeFrom(this.state.filters)
            });

        });
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    resetInterval() {
        clearInterval(this.interval);
        this.interval = setInterval(() => {
            this.refreshTable();
        }
        , REFRESH_TABLE_INTERVAL);
    }

    resetIntervalAndRefreshTable() {
        this.resetInterval();
        this.refreshTable();
    }

    onPage(event) {
        this.setState({
            loading: true
        });

        const startIndex = event.first;
        const pageNumber = startIndex / event.rows + 1;

        this.setState({ pageNumber: pageNumber, startIndex: startIndex, rows: event.rows }, () => {
            this.getOrders(this.state.filters, this.state.sortBy, this.state.pageNumber, this.state.startIndex);
        });
    }


    getOrders(filters, sortBy, pageNumber, startIndex, onSuccess) {

        this.getOrdersWithUpdateTableCallback(filters, sortBy, pageNumber, startIndex, onSuccess,
            (page, startIndex) => {
                updateTableWithNewData(this, page, startIndex);
            });
    }


    getOrdersWithUpdateTableCallback(filters, sortBy, pageNumber, startIndex,
        onSuccess, updateTableWithData) {

        const params = createParams(filters, sortBy);
        DealerSupportService.getMainQueueOrders(params, pageNumber, this.state.rows,
            (page) => {
                if (updateTableWithData) {
                    updateTableWithData(page, startIndex);
                }
                if (onSuccess) {
                    onSuccess(page.content);
                }
            },
            (error) => {
                onError(this, error);
            });
    }


    render() {
        const filterColumns = {

        };
        return (
            <div>
                { <OrderFilterPanel
                    // visible="sortField,serviceId"
                    visibleObject={filterColumns}
                    filters={this.state.filters} filter={this.state.filter}
                    onFilterChange={defaultOnFilterChange(this)}
                    onCleanButton={() => {
                        new OrderFilterLoadService().resetFilter((filters) => {
                            this.setState({filters:filters},()=>{
                                this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                            });
                        },QueueType.MAIN);
                    }}
                    onFilter={() => {
                        this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                    }}
                    onSortFieldChange={(val, property) => {
                        this.setState({ [property]: val });
                    }}
                />}


                <Growl ref={(el) => this.growl = el} position="topright"></Growl>
                <TakeLastFromQueueBtn getOrderId=
                    {(onSuccess, onError) => { 
                        return DealerSupportService.pullFromQueueAndAssign(onSuccess, onError) }} />

                <DealerSupportOrderDataTable showAll={false} visible={"location,assignAndOpenOrder"} hidden={HIDDEN_FIELDS_MAIN_QUEUE}
                    totalRecords={this.state.totalRecords}
                    toogleFilter={() => this.setState({ filter: !this.state.filter })}
                    headerTitle={"Lista fotomontaży"} orders={this.state.orders}
                    rows={this.state.rows}
                    first={this.state.first} loading={this.state.loading} onPage={this.onPage}
                    onSort={(sortBy) => {
                        this.setState({ sortBy: sortBy }, () => {
                            this.resetIntervalAndRefreshTable();
                        });
                    }}
                    refreshTable={() => {
                        this.resetIntervalAndRefreshTable();
                    }}

                />
            </div>

        );
    }


}

export default DealerSupportOrders;

