import RestService from "./RestService";

const PATH = "/queue/order/notification";

const QueueOrderNotificationService = {


    getNewOrdersFromDsMainQueue(modificationDateTime, onSuccess, onError) {
        let params = {"modificationDateTime": modificationDateTime};
        RestService.getWithParams(PATH + "/dealerSupport", params, onSuccess, onError);
    },

    getNewOrdersFromWckuMainQueue(modificationDateTime, onSuccess, onError) {
        let params = {"modificationDateTime": modificationDateTime};
        RestService.getWithParams(PATH + "/wcku", params, onSuccess, onError);
    },
    getNewOrdersFromOkiActivationMainQueue(modificationDateTime, onSuccess, onError) {
        let params = {"modificationDateTime": modificationDateTime};
        RestService.getWithParams(PATH + "/okiActivation", params, onSuccess, onError);
    },
    getNewOrdersFromOkiReplacementMainQueue(modificationDateTime, onSuccess, onError) {
        let params = {"modificationDateTime": modificationDateTime};
        RestService.getWithParams(PATH + "/okiReplacement", params, onSuccess, onError);
    },

    getNewOrdersFromMonitoringAllQueue(modificationDateTime, onSuccess, onError) {
        let params = {"modificationDateTime": modificationDateTime};
        RestService.getWithParams(PATH + "/monitoring/all", params, onSuccess, onError);
    },
    getNewOrdersFromMonitoringToTakeQueue(modificationDateTime, onSuccess, onError) {
        let params = {"modificationDateTime": modificationDateTime};
        RestService.getWithParams(PATH + "/monitoring/toTake", params, onSuccess, onError);
    },

    getNewOrdersFromMonitoringTodayQueue(modificationDateTime, onSuccess, onError) {
        let params = {"modificationDateTime": modificationDateTime};
        RestService.getWithParams(PATH + "/monitoring/today", params, onSuccess, onError);
    },

    getNewOrdersFromMonitoringLateQueue(modificationDateTime, onSuccess, onError) {
        let params = {"modificationDateTime": modificationDateTime};
        RestService.getWithParams(PATH + "/monitoring/late", params, onSuccess, onError);
    },

    getNewOrdersFromDsBotMainQueue(modificationDateTime, onSuccess, onError) {
        let params = {"modificationDateTime": modificationDateTime};
        RestService.getWithParams(PATH + "/dealerSupport/bot", params, onSuccess, onError);
    },

    getNewOrdersFromOkiBotMainQueue(modificationDateTime, onSuccess, onError) {
        let params = {"modificationDateTime": modificationDateTime};
        RestService.getWithParams(PATH + "/okiPreview/bot", params, onSuccess, onError);
    },

    getNewOrdersFromOntppMainQueue(modificationDateTime, onSuccess, onError) {
        let params = {"modificationDateTime": modificationDateTime};
        RestService.getWithParams(PATH + "/ontpp", params, onSuccess, onError);
    },

};

export default QueueOrderNotificationService;

