import {PickListSubList} from "primereact/components/picklist/PickListSubList";
import {InputText} from "primereact/inputtext";
import classNames from 'classnames';
import {PickListItem} from "primereact/components/picklist/PickListItem";
import React from "react";
import PropTypes from 'prop-types';

export class FilteredPickListSubList extends PickListSubList {
    static defaultProps = {
        list: null,
        selection: null,
        header: null,
        className: null,
        listClassName: null,
        style: null,
        showControls: true,
        metaKeySelection: true,
        tabIndex: null,
        itemTemplate: null,
        onItemClick: null,
        onSelectionChange: null,
        searchBy: null
    };

    static propTypes = {
        list: PropTypes.array,
        selection: PropTypes.array,
        header: PropTypes.string,
        className: PropTypes.string,
        listClassName: PropTypes.string,
        style: PropTypes.object,
        showControls: PropTypes.bool,
        metaKeySelection: PropTypes.bool,
        tabIndex: PropTypes.string,
        itemTemplate: PropTypes.func,
        onItemClick: PropTypes.func,
        onSelectionChange: PropTypes.func,
        searchBy: PropTypes.string
    };

    constructor() {
        super();
        this.state = {
            searchInput: "",
            list: []
        };
    }

    filterList(searchText) {
        const searchResult = this.props.list.filter(group => {
            return group.verboseName.toLowerCase().includes(searchText.toLowerCase());
        });
        this.setState({list: searchResult});
    }

    setItemElements(list) {
        return list.map((item) => {
            return <PickListItem key={JSON.stringify(item)} value={item} template={this.props.itemTemplate}
                selected={this.isSelected(item)} onClick={this.onItemClick}
                onKeyDown={this.onItemKeyDown} tabIndex={this.props.tabIndex}/>;
        });
    }

    render() {
        let header = null;
        const wrapperClassName = classNames('p-picklist-listwrapper', this.props.className, {
            'p-picklist-listwrapper-nocontrols': !this.props.showControls
        });
        const listClassName = classNames('p-picklist-list', this.props.listClassName);

        if (this.props.header) {
            header = <div className="p-picklist-caption">{this.props.header}</div>;
        }

        if (this.state.searchInput === "" && this.props.list) {
            this.state.list = this.props.list;
        }

        let filter = null;
        if (this.props.searchBy) {
            filter = <div className="p-picklist-filter-container">
                <InputText className="p-picklist-filter" placeholder="Szukaj" value={this.state.searchInput}
                    type={"search"}
                    onChange={(e) => {
                        const searchText = e.target.value;
                        this.setState({searchInput: searchText});
                        this.filterList(searchText);
                    }}/>
            </div>;
        }

        return <div className={wrapperClassName}>
            {header}
            {filter}
            <ul className={listClassName} style={this.props.style} >
                {this.setItemElements(this.state.list)}
            </ul>
        </div>;
    }
}
