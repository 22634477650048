import '../../static/css/App.css';
import React from 'react';
import Dictionary from "./Dictionary";
import ServiceService from "../../services/dict/ServiceService";


class Service extends React.Component {

    render() {
        return (
            <Dictionary
                title="Typy usług"
                fetchValues={(onSuccess, onError) => {
                    ServiceService.getAll((data) => {
                        onSuccess(data);
                    },
                    (error) => {
                        onError(error);
                    });
                }} />
        );
    }

}

export default Service;

