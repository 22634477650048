import {JSONUtil} from "./Utils";
import * as EmailValidator from "email-validator";
import validator from "validator";
import passwordValidator from "password-validator";

const EMPTY_FIELD_MSG = "Pole nie może być puste.";
const TO_SHORT_LENGHT = "Proszę podać wartość dłuższą niż ";
const TWO_FIELDS_SHOULD_BE_THE_SAME = "Pola nie są takie same.";
const INCORRECT_EMAIL_MSG = "Niepoprawny adres email.";
const INCORRECT_PASSWORD = "Hasło musi zawierać minumum 8 znaków w tym małe litery, cyfry i znaki specjalne.";

class ValidationService {



    data = {
        validators: []
    };


    passwordValidator() {
        return (val) => {
            //https://www.npmjs.com/package/password-validator
            const schema = new passwordValidator();
            schema
                .is().min(8) // Minimum length 8
                .is().max(100) // Maximum length 100
                .has().lowercase() // Must have lowercase letters
                .has().digits() // Must have digits
                .has().symbols()
                .has().not().spaces() // Should not have spaces
                .is().not().oneOf(['Passw0rd', 'Password123']); // Blacklist these values

            return {
                isValid: val ? schema.validate(val) : false
            };

        };
    }
    validPassword(name, value, msg) {
        this.data.validators.push(
            {
                value: value, msg: msg ? msg : INCORRECT_PASSWORD,
                name: name,
                validator: this.passwordValidator()
            });
    }
    mobilePhoneValidator() {
        return (val) => {
            return {
                isValid: val?validator.isMobilePhone(val):true
            };

        };
    }
    emailValidator() {
        return (val) => {
            return {
                isValid: EmailValidator.validate(val)
            };

        };
    }
    validEmail(name, value, msg) {
        this.data.validators.push(
            {
                value: value, msg: msg ? msg : INCORRECT_EMAIL_MSG,
                name: name,
                validator: this.emailValidator()
            });
    }


    notEmptyValidator() {
        return (val) => {
            return {
                isValid: !!val
            };

        };
    }


    notEmpty(name, value, msg) {
        this.data.validators.push(
            {
                value: value, msg: msg ? msg : EMPTY_FIELD_MSG,
                name: name,
                validator: this.notEmptyValidator()
            });
    }

    lengthGreaterThan(attr1, name, value, msg) {
        this.data.validators.push(
            {
                value: value, msg: msg ? msg : `${TO_SHORT_LENGHT + attr1} znaków.`,
                name: name,
                validator: this.lengthGreaterThanValidator(),
                attr1: attr1
            });
    }

    lengthGreaterThanValidator() {
        return (val, attr1) => {
            if (!attr1) {
                console.log("Error (lengthGreaterThanValidator): no required attr1 !!!!");
            }
            const isValid = val && val.length > attr1;
            return {
                isValid: isValid
            };
        };
    }

    twoFieldsEqual( name, value, value2, msg) {
        this.data.validators.push(
            {
                value: value, msg: msg ? msg : TWO_FIELDS_SHOULD_BE_THE_SAME,
                name: name,
                validator: this.twoFieldsEqualValidator(),
                attr1: value2,
            });
    }

    twoFieldsEqualValidator() {
        return (val, attr1) => {
            const isValid = val && attr1 && val ===attr1;
            return {
                isValid: isValid
            };
        };
    }



    /**
     *
     *
     * Example:
     let validationService = new ValidationService();
     validationService.notEmpty("login", this.state.login);
     validationService.lengthGreaterThan(5, "password1", this.state.password1);
     validationService.lengthGreaterThan(5, "password2", this.state.password2);
     validationService.lengthGreaterThan(2, "firstName", this.state.firstName);
     validationService.lengthGreaterThan(2, "lastName", this.state.lastName);

     ** Error object {
          "error": {
            "login": "Pole nie może być puste.",
            "password1": "Proszę podać wartość dłuższą niż 5 znaków.",
            "password2": "Proszę podać wartość dłuższą niż 5 znaków.",
            "firstName": "Proszę podać wartość dłuższą niż 2 znaków.",
            "lastName": "Proszę podać wartość dłuższą niż 2 znaków.",
            "email": "Pole nie może być puste."
          },
          "isValid": false
        }
     */
    validate() {
        let isValid = true;
        let errorObj = {};

        this.data.validators.forEach((item) => {
            const errorPath = `error.${item.name}`;
            const result = item.validator(item.value, item.attr1, item.attr2);
            console.log(`result2 = ${JSON.stringify(result)}`);
            if (!result.isValid) {
                isValid = false;
                errorObj = JSONUtil.getNestedJsonObject(errorObj, item.msg, errorPath);
            }
        });

        errorObj["isValid"] = isValid;
        console.log(`errorObj = ${JSON.stringify(errorObj)}`);
        return errorObj;
    }

}

export default ValidationService;
