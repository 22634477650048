import RestService from "./RestService";

const PATH = "/cache/reset";

const CacheService = {

    reset(onSuccess, onError) {
        RestService.delete(PATH,onSuccess,onError);
    },

};

export default CacheService;

