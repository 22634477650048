import React from "react";
import '../static/css/App.css';

import OrderRejectionReasonDropdown from "./dropdown/OrderRejectionReasonDropdown";
import {rejectAndForwardToInstaller} from "../config/constants";

class OrderRejectionReasonDiv extends React.Component {

    render() {


        return (
            <>
                {this.props.selectedOperation && this.props.selectedOperation === rejectAndForwardToInstaller &&
                <div className="p-grid nogutter p-col-12">
                    <label className="p-col-3 o-label" htmlFor="in">Wybierz powód:</label>
                    <span className="o-value">
                        <OrderRejectionReasonDropdown className={"width250"} onChange={(event) => {
                            this.setState({orderRejectionReason: event.value});
                            this.props.onChange(event);
                        }}/>
                    </span>
                </div>
                }
            </>


        );

    }
}


export default OrderRejectionReasonDiv;
