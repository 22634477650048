import '../static/css/App.css';
import RestService from "./RestService";

const PATH = "/heartbeat";

const HeartbeatService = {
    heartbeat() {
        return RestService.heartbeat(PATH);
    },
};

export default HeartbeatService;
