import React, {Component} from 'react';
import {Dropdown} from "primereact/dropdown";
import OrderRejectionReasonService from "../../services/dict/OrderRejectionReasonService";


class OrderRejectionReasonDropdown extends Component {


    state = {
        items: [],
        item:{}
    };

    componentDidMount() {
        OrderRejectionReasonService.getAll((items) => {
            const all = items.map(item => {
                return {"name": item.verboseName, "key": item.id};
            });
            let item={};
            if (all && all.length>0){
                item = all[0];
            }
            this.setState({items: all,item:item});
            this.props.onChange({value:item});
        }, (error) => {
            console.log(`OrderRejectionReasonService.getByCompanyId()->error${JSON.stringify(error)}`);
        });

    }


    render() {

        return (
            <Dropdown showClear={false} className={this.props.className} optionLabel="name" value={this.state.item}
                      options={this.state.items}
                      onChange={(event) => {
                          this.setState({item: event.value});
                          return this.props.onChange(event);
                      }}/>

        );
    }
}

export default OrderRejectionReasonDropdown;