import '../../static/css/App.css';
import React from 'react';
import {Panel} from "primereact/panel";
import {Messages} from "primereact/messages";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import MonitoringSettingsService from "../../services/MonitoringSettingsService";
import {Button} from "primereact/button";
import {DATE_TIME_FORMAT, defaultDetail, defaultSummary} from "../../config/constants";
import {InputText} from "primereact/inputtext";
import Moment from "react-moment";

class MonitoringSettings extends React.Component {
    addUrl = "/monitoring/settings/ragResponse/add"
    state = {
        values: [],
        rowIdsToSendUpdateToServer: new Set(),
        ragResponseTime: null,
        currentServerRagResponseTime: null,
        ragResponseTimeEditable: false,
        ragResponsesEditUsername: null,
        ragResponsesEditTimestamp: null,
        ragResponseTimeEditUsername: null,
        ragResponseTimeEditTimestamp: null
    }

    constructor(props) {
        super(props);
        this.messageEditor = this.messageEditor.bind(this);
        this.inputTextEditor = this.inputTextEditor.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.orderingTemplate = this.orderingTemplate.bind(this);
    }


    componentDidMount() {
        this.getValues();
    }

    showError = (summary, detail) => {
        this.messages.show({severity: 'error', summary: summary, detail: detail});
    }

    compareOrdering(left, right) {
        if (left.ordering < right.ordering) {
            return -1;
        }
        if (left.ordering > right.ordering) {
            return 1;
        }
        return 0;
    }

    getValues() {
        this.getRagResponses();
        this.getGlobalConfigValues();
    }

    getRagResponses() {
        MonitoringSettingsService.read((data) => {
                this.setState({
                    values: data.sort(this.compareOrdering),
                });
            },
            (error) => {
                this.setState({
                    loading: false
                });
                console.log(`Error: ${error}`);
            });
    }

    getGlobalConfigValues() {
        MonitoringSettingsService.readGlobalConfig((data) => {
                let ragResponseTime = data.find((it) => it.name === "RAG_RESPONSE_TIME");
                let ragResponsesEditUsername = data.find((it) => it.name === "RAG_RESPONSES_EDIT_USERNAME");
                let ragResponsesEditTimestamp = data.find((it) => it.name === "RAG_RESPONSES_EDIT_TIMESTAMP");
                let ragResponseTimeEditUsername = data.find((it) => it.name === "RAG_RESPONSE_TIME_EDIT_USERNAME");
                let ragResponseTimeEditTimestamp = data.find((it) => it.name === "RAG_RESPONSE_TIME_EDIT_TIMESTAMP");
                this.setState({
                    ragResponseTime: ragResponseTime ? ragResponseTime.verboseName : null,
                    currentServerRagResponseTime: ragResponseTime ? ragResponseTime.verboseName : null,
                    ragResponsesEditUsername: ragResponsesEditUsername ? ragResponsesEditUsername.verboseName : null,
                    ragResponsesEditTimestamp: ragResponsesEditTimestamp ? ragResponsesEditTimestamp.verboseName : null,
                    ragResponseTimeEditUsername: ragResponseTimeEditUsername ? ragResponseTimeEditUsername.verboseName : null,
                    ragResponseTimeEditTimestamp: ragResponseTimeEditTimestamp ? ragResponseTimeEditTimestamp.verboseName : null
                });
            },
            (error) => {
                this.setState({
                    loading: false
                });
                console.log(`Error: ${error}`);
            });
    }

    moveOrderingUp = (data) => {
        MonitoringSettingsService.moveOrderingUp(data["id"], () => {
            var newValues = this.state.values;
            var thisIndex = newValues.findIndex(value => value.id === data["id"]);
            var secondIndex = newValues.findIndex(value => value.ordering === data["ordering"] - 1);
            newValues[thisIndex].ordering = newValues[thisIndex].ordering - 1;
            newValues[secondIndex].ordering = newValues[secondIndex].ordering + 1;
            this.setState({values: newValues.sort(this.compareOrdering)});
            this.getGlobalConfigValues();
        }, () => {
            this.showError(defaultSummary, defaultDetail);
        });
    }

    moveOrderingDown = (data) => {
        MonitoringSettingsService.moveOrderingDown(data["id"], () => {
            var newValues = this.state.values;
            var thisIndex = newValues.findIndex(value => value.id === data["id"]);
            var secondIndex = newValues.findIndex(value => value.ordering === data["ordering"] + 1);
            newValues[thisIndex].ordering = newValues[thisIndex].ordering + 1;
            newValues[secondIndex].ordering = newValues[secondIndex].ordering - 1;
            this.setState({values: newValues.sort(this.compareOrdering)});
            this.getGlobalConfigValues();
        }, () => {
            this.showError(defaultSummary, defaultDetail);
        });
    }

    updateValue = (data) => {
        MonitoringSettingsService.update(data, () => {
            const rowIdsToSendUpdateToServer = new Set(this.state.rowIdsToSendUpdateToServer);
            rowIdsToSendUpdateToServer.delete(data["id"]);
            this.setState({rowIdsToSendUpdateToServer: rowIdsToSendUpdateToServer});
        }, () => {
            this.showError(defaultSummary, defaultDetail);
        });
    }

    removeItem = (data) => {
        MonitoringSettingsService.delete(data, () => {
            this.getRagResponses();
        }, () => {
            this.showError(defaultSummary, defaultDetail);
        });
    }

    actionTemplate(rowData) {
        return <div>
            <Button type="button" disabled={this.state && !this.state.rowIdsToSendUpdateToServer.has(rowData["id"])}
                    icon="pi pi-save"
                    className="p-button-success" onClick={() => {
                this.updateValue(rowData);
            }}/>
            <Button type="button" style={{"marginLeft": "10px"}} icon="pi pi-minus"
                    className="p-button-success" onClick={() => {
                this.removeItem(rowData);
            }}/>
        </div>;
    }

    orderingTemplate(rowData) {
        return <div>
            <Button type="button" disabled={rowData["ordering"] === 0} icon="pi pi-arrow-up"
                    className="p-button-info" onClick={() => {
                this.moveOrderingUp(rowData);
            }}/>
            <Button type="button"
                    disabled={this.state && this.state.values && rowData["ordering"] === this.state.values.length - 1}
                    style={{"marginLeft": "10px"}} icon="pi pi-arrow-down"
                    className="p-button-info" onClick={() => {
                this.moveOrderingDown(rowData);
            }}/>
        </div>;
    }

    messageEditor(props) {
        return this.inputTextEditor(props, 'message');
    }

    inputTextEditor(props, field) {
        return <InputText type="text" value={props.rowData[field]}
                          onChange={(e) => this.onEditorValueChange(props, e.target.value)}/>;
    }

    formatTimestamp(dateTimeString) {
        // var d = new Date(dateString);
        return <Moment format={DATE_TIME_FORMAT}>
            {dateTimeString}
        </Moment>
    }

    onEditorValueChange(props, value) {
        const updatedValues = [...props.value];
        updatedValues[props.rowIndex][props.field] = value;
        const rowIdsToSendUpdateToServer = new Set(this.state.rowIdsToSendUpdateToServer);
        const id = updatedValues[props.rowIndex]["id"];
        rowIdsToSendUpdateToServer.add(id);
        this.setState({values: updatedValues, rowIdsToSendUpdateToServer: rowIdsToSendUpdateToServer});
    }

    editRagResponseTimeButton() {
        return <Button className="p-button-info" label="Edytuj" onClick={() => {
            this.setState({ragResponseTimeEditable: true});
        }}/>
    }

    cancelEditRagResponseTimeButton() {
        return <Button className="p-button-warning" label="Anuluj" onClick={() => {
            this.setState({ragResponseTimeEditable: false, ragResponseTime: this.state.currentServerRagResponseTime});
        }}/>
    }

    saveRagResponseTimeButton() {
        return <Button className="p-button-success" label="Zapisz" onClick={() => {
            MonitoringSettingsService.updateRagResponseTime({
                name: 'RAG_RESPONSE_TIME',
                verboseName: this.state.ragResponseTime
            }, () => {
                this.setState({ragResponseTimeEditable: false})
                this.getGlobalConfigValues();
            }, () => {
                this.showError(defaultSummary, defaultDetail);
            });
        }}/>
    }

    render() {
        const header = <div className="p-clearfix" style={{'lineHeight': '1.87em'}}>
            Słownik odpowiedzi na RAG
            <Button icon="pi pi-plus" style={{'float': 'right', 'marginLeft': '10px'}}
                    onClick={() => this.props.history.push(this.addUrl)}/>
            <Button
                icon="pi pi-refresh" style={{'float': 'right'}}
                onClick={() => this.getRagResponses()}/>
        </div>;
        return (
            <div className="content-section implementation">
                <div className="p-grid nogutter p-col-12">
                    <Messages ref={(el) => this.messages = el}/>
                </div>
                <Panel header="Czas obsługi">
                    <p>Limit czasu obsługi RAG (zlecenia "Do podjęcia") w minutach</p>
                    <div><InputText disabled={!this.state.ragResponseTimeEditable} keyfilter="int" min="1"
                                    max="2000000" placeholder="min" value={this.state.ragResponseTime}
                                    onChange={(e) => this.setState({ragResponseTime: e.target.value})}/>
                        {this.state.ragResponseTimeEditable ? this.saveRagResponseTimeButton() : this.editRagResponseTimeButton()}
                        {this.state.ragResponseTimeEditable ? this.cancelEditRagResponseTimeButton() : null}
                    </div>
                    <p>Ostatnio zmieniane przez: <b>{this.state.ragResponseTimeEditUsername}</b> w
                        dniu <b>{this.formatTimestamp(this.state.ragResponseTimeEditTimestamp)}</b></p>
                </Panel>
                <DataTable value={this.state.values} editable={true} header={header} sortField="ordering"
                           defaultSortOrder="1">
                    <Column body={this.orderingTemplate} header="Kolejność"
                            style={{textAlign: 'center', width: '8em'}}/>
                    <Column field="message" header="Wiadomość" style={{textAlign: 'center'}}
                            editor={this.messageEditor}/>
                    <Column body={this.actionTemplate} style={{textAlign: 'center', width: '8em'}}/>
                </DataTable>
                <p>Ostatnio zmieniane przez: <b>{this.state.ragResponsesEditUsername}</b> w
                    dniu <b>{this.formatTimestamp(this.state.ragResponsesEditTimestamp)}</b></p>
            </div>
        )
    }

}

export default MonitoringSettings;