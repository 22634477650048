import {DateTimeField} from "./Utils";
import React from "react";
import _uniqueId from 'lodash/uniqueId';
import {Button} from "primereact/button";
import ReactTooltip from "react-tooltip";


//https://stackoverflow.com/questions/39501289/in-reactjs-how-to-copy-text-to-clipboard
//https://stackoverflow.com/questions/49236100/copy-text-from-span-to-clipboard
export const copyToClipboard = function (id) {
    copyValueToClipboard(document.getElementById(id).textContent);
};


export const copyValueToClipboard = function (copyText) {
    var textArea = document.createElement("textarea");
    textArea.value = copyText;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
};


export const StringRow = ({label, value}) =>
    <div className="rTableRow">
        <div className="rTableCell argumentHeader">{label}</div>
        <div className="rTableCell">{value}</div>
    </div>;

export const StringRowWithDistinction = ({label, value, valueClassName, tooltipText}) =>
    <div className="rTableRow">
        <div data-tip="" data-for={label}>
            <div className="rTableCell argumentHeader">{label}</div>
            <ReactTooltip id={label} place="top" effect="float">
                {tooltipText}
            </ReactTooltip>
        </div>
        <div className={valueClassName}>{value}</div>
    </div>;

export const StringRowWithCopyBtn = ({label, value}) => {
    const id = _uniqueId('prefix-');
    return (
        <div className="rTableRow">
            <div className="rTableCell argumentHeader">{label}</div>
            <div className="rTableCell">
                <span id={id}>{value}</span>
                {value === "" || value === null ?
                    null
                    : <button style={{"marginLeft": "0.15em"}} className="p-button pi pi-copy" onClick={() => copyToClipboard(id)}/>}
            </div>
        </div>);
};

export const StringRowChatButton = ({order, hasUnreadMessages, onClick}) => {
    return (
        <div className="rTableRow">
            <div className="rTableCell argumentHeader">
                {hasUnreadMessages ? "Masz nieprzeczytane wiadomości!" : "Przejdź do czatu:"}
            </div>
            <div className="rTableCell">
                <Button icon="pi pi-comments" className={hasUnreadMessages ? 'badge' : ''} label="Otwórz czat"
                    onClick={onClick}
                        // disabled={!OrderUtil.wasCurrentOPLEverAssignedToOrder(order)}
                />
            </div>
        </div>);
};

/**
 * Status chatu OPL z PT
 * @see https://git.chupacabra.tp.pl/photomontage/deadpool-frontend/-/issues/19
 */
export const ChatStatusLabel = ({order, hasUnreadMessages}) => {
    return (
        <div className="rTableRow">
            <div className="rTableCell argumentHeader">
                Status czatu
            </div>
            <div className="rTableCell">
                {!order.chatWasOpened && <span className={"chat-label not-opened-chat-label"}>Czat nie był otwierany</span>}
                {!hasUnreadMessages && <span className={"chat-label no-unread-messages-chat-label"}>Brak nowych wiadomości</span>}
                {hasUnreadMessages && <span className={"chat-label unread-messages-chat-label"}>Nieprzeczytane wiadomości</span>}
            </div>
        </div>);
};


export const BooleanRow = ({label, value, disabled}) =>
    <div className="rTableRow">
        <div className="rTableCell argumentHeader">{label}</div>
        <div className="rTableCell">
            <input disabled={disabled} style={{"marginLeft": "0px"}} type="checkbox" checked={value}/>
        </div>
    </div>;

export const TextAreaRow = ({label, value}) =>
    <div className="rTableRow">
        <div className="rTableCell argumentHeader">{label}</div>
        <div className="rTableCell">
            <textarea className={"commentTextArea"} value={value} readOnly={true}/>
        </div>
    </div>;

export const DateTimeRow = ({label, value}) =>
    <div className="rTableRow">
        <div className="rTableCell argumentHeader"><span>{label}</span></div>
        <div className="rTableCell">
            {value && <DateTimeField dateTimeString={value}/>}
        </div>
    </div>;


export const ButtonRow = ({label, value, onClick}) =>
    <div className="rTableRow">
        <div className="rTableCell argumentHeader">{label}</div>
        <div className="rTableCell">
            <button onClick={onClick}>{value}</button>
        </div>
    </div>;

export const ActivationHistoryStringRow = ({label, value}) =>
    <div className="rTableHeaderGroupRow">
        <div className="rTableCell argumentHeader">{label}</div>
        <div className="rTableCell">{value}</div>
    </div>;

export const ActivationHistoryDateTimeRow = ({label, value}) =>
    <div className="rTableHeaderGroupRow">
        <div className="rTableCell argumentHeader"><span>{label}</span></div>
        <div className="rTableCell">
            {value && <DateTimeField dateTimeString={value}/>}
        </div>
    </div>;
