import RestService from "./RestService";

const GenesysService = {

    makeDialCall(data, onSuccess, onError) {
        RestService.post(`/genesys`, data, onSuccess, onError);
    },

};

export default GenesysService;

