import React from 'react';
import {NO_DATA} from '../config/constants';
import {OrderUtil, sortByDate} from './Utils';
import _ from "lodash";

const NO_DATA_VIEW = () => {
    return (
        <div>
            <span className={"noData"}>
                {NO_DATA}
            </span>
        </div>);
}

const INSTALLERS_VIEW = ({ installers }) => {
    return (
        <div>{installers.map(item =>
            <div>
                <span className={item.className}>
                    {OrderUtil.prepareInstallerTextWithSeparator(item.installer, item.isLast)}
                </span>
            </div>
        )}
        </div>
    );
}

export const InstallerRow = (rowData) => {
    let installersAddedToOrderEvents = _.get(rowData, "orderEventAggregatedData.installers",[]);
    let installers = rowData.installers ? rowData.installers : [];
    addToInstallerDateTimeOfAddedToOrder(installers, installersAddedToOrderEvents);
    sortByDate(installers, "addedToOrderDateTime");
    let noInstallerDataAndOrderCreatedDuringActivation = OrderUtil.isEmpty(installers) && OrderUtil.isCreatedDuringActivation(rowData);

    if (noInstallerDataAndOrderCreatedDuringActivation) {
        return (<NO_DATA_VIEW />)
    }
    else {
        let installersArray = createInstallerArray(installers);
        return (
            <INSTALLERS_VIEW installers={installersArray} />
        );
    }

}
function createInstallerArray(installers) {
    if (typeof installers !== 'undefined' && installers.length > 0) {
        let lastIndex = installers.length - 1;
        return installers.map((item, index) => {
            return {
                installer: item,
                isLast: lastIndex === index
            };
        });
    }
    return [];
}

function addToInstallerDateTimeOfAddedToOrder(installers, installersAddedToOrderEvents) {
    let dateTimeByInstallerId = installersAddedToOrderEvents.reduce(function (map, obj) {
        map[obj.id] = obj.addedToOrderDateTime;
        return map;
    }, {});
    installers.map(item => {
        return item.addedToOrderDateTime = dateTimeByInstallerId[item.id];
    })
}

