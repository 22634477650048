import axios from "axios";
import RestService from "./RestService";



const FinishedWorkService = {


    getById(id, onSuccess, onError) {

        return axios.get(`/finishedWork/${id}`)
            .then(function (response) {
                if (response.status===200){
                    onSuccess(response.data);
                } else {
                    const error = `Incorrect response status ${response.status}`;
                    onError(error);
                }

            })
            .catch(function (error) {
                onError(error);
            });


    },

    getByOrderId(id, onSuccess, onError) {

        RestService.get(`/finishedWork/order/${id}`,onSuccess,onError);

    },

};

export default FinishedWorkService;

