import moment from "moment";
import { DEFAULT_MODIFICATION_DATE_TIME_FROM_FOR_ALL_AND_HISTORY_IN_DAYS, DEFAULT_MODIFICATION_DATE_TIME_FROM_IN_DAYS, QueueType } from "../config/constants";
import FilterSaver from "./FilterSaver";

class OrderFilterLoadService {

    getFilter(queueType) {
        let filter = new FilterSaver().getFilters();
        let intervalInDays = this.getDateIntervalByQueueType(queueType);
        filter = this.modifyIfNeeded(filter, intervalInDays);
        new FilterSaver().saveFilters(filter);
        return filter;
    }

    modifyIfNeeded(filter, intervalInDays) {
        if (!filter) {
            filter = {
                modificationDateTimeFrom: this.getDateTimeMinusDaysInISOFormat(intervalInDays),
                _creationDateTime: this.getDateTimeMinusDaysInISOFormat(0)
            };
        } else {
            if (!filter.modificationDateTimeFrom || this.isFilterExpired(filter)) {
                filter.modificationDateTimeFrom = this.getDateTimeMinusDaysInISOFormat(intervalInDays);
                filter._creationDateTime = this.getDateTimeMinusDaysInISOFormat(0);
            }
        }
        return filter;
    }

    isFilterExpired(filter){
        let _creationDateTime =  filter._creationDateTime;
        if(_creationDateTime){
            let expiryDate = new Date();
            expiryDate.setDate(expiryDate.getDate() - 5);
            let creationDateTime = new Date(_creationDateTime);
            return creationDateTime <  expiryDate;
        }

    }

    resetFilter(callback, queueType) {
        let intervalInDays = this.getDateIntervalByQueueType(queueType);
        let filter = {
            modificationDateTimeFrom: this.getDateTimeMinusDaysInISOFormat(intervalInDays),
            _creationDateTime: this.getDateTimeMinusDaysInISOFormat(0)
        };
        new FilterSaver().saveFilters(filter);
        callback(filter);
    }

    getDateTimeMinusDaysInISOFormat(intervalInDays) {
        return (moment().subtract(intervalInDays, 'd').toDate()).toISOString();
    }

    getDateIntervalByQueueType(queueType) {

        let dateInterval = DEFAULT_MODIFICATION_DATE_TIME_FROM_IN_DAYS
        if (queueType) {
            switch (queueType) {
                case QueueType.HISTORY:
                    dateInterval = DEFAULT_MODIFICATION_DATE_TIME_FROM_FOR_ALL_AND_HISTORY_IN_DAYS;
                    break;
                default:
                    break;
            }
        }
        return dateInterval;
    }
}

export default OrderFilterLoadService;
