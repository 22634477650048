import RestService from "./RestService";

const PATH = "/group";

const GroupService = {


    getAll(onSuccess, onError) {
        return RestService.get(PATH, onSuccess, onError);
    },


};

export default GroupService;

