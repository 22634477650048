import React from 'react';
import AuthService from '../../services/AuthService';
import {withRouter} from "react-router";
import '../../static/css/Login.css';
import {Dialog} from "primereact/dialog";
import ErrorService from "../../services/ErrorService";
import {Message} from "primereact/message";
import {Messages} from "primereact/messages";
import {InputText} from "primereact/inputtext";
import ValidationService from "../../shared/ValidationService";
import {defaultDetail,defaultSummary} from "../../config/constants";


class ChangeAnotherAccountPasswordDialogWithoutRouter extends React.Component {
    state = {
        newPassword1: "",
        newPassword2: "",
        error:{}
    };
    showError = (summary, detail) => {
        this.messages.show({severity: 'error', summary: summary, detail: detail});
    };
    changePassword = () => {
        const isValid = this.validate();
        if (isValid) {
            AuthService.changeAnotherAccountPassword(this.props.accountId, this.state.newPassword1,
                () => {
                    this.resetStateOnSuccess();
                    this.props.onHide();
                    this.props.onSuccess();
                }, (error) => {
                    this.showError(defaultSummary,
                        defaultDetail);
                    ErrorService.logServerError(error);
                });
        }
    };
    resetStateOnSuccess() {
        this.setState({
            newPassword1: "",
            newPassword2: "",
            error:""
        });
    }
    handleOnChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
            loggingError: false
        });
    };
    validate() {

        const validationService = new ValidationService();
        validationService.validPassword("newPassword1",this.state.newPassword1);
        validationService.validPassword("newPassword2",this.state.newPassword2);
        validationService.twoFieldsEqual("newPassword1", this.state.newPassword1, this.state.newPassword2);

        const errorObj = validationService.validate();
        this.setState(errorObj);

        return errorObj.isValid;
    }
    //https://stackoverflow.com/questions/39442419/reactjs-is-there-a-way-to-trigger-a-method-by-pressing-the-enter-key-inside
    enterPressed(event) {
        var code = event.keyCode || event.which;
        if (code === 13) { //13 is the enter keycode
            if (this.validate()) {
                this.changePassword();
            }
        }
    }
    render() {
        return (
            <Dialog header={`Zmiana hasła użytkownika ${this.props.userName}`}
                visible={this.props.visible} style={{width: '30vw'}} modal={true}
                onHide={()=>{
                    this.resetStateOnSuccess();this.props.onHide();
                }}>
                <Messages ref={(el) => this.messages = el}/>
                <div className="p-grid nogutter p-col-12 ">
                    <div className="p-grid p-col-12 ">
                        <label className="o-label p-col-5" htmlFor="newPassword1">Podaj nowe hasło:</label>
                        <InputText placeholder="" id="newPassword1" type="password" required={true}
                            value={this.state.newPassword1} className="o-value p-col-6 validate"
                            style={{"margin": "5px 5px 0px 5px", "padding": "5px"}}
                            onChange={this.handleOnChange} onKeyPress={this.enterPressed.bind(this)}/>
                    </div>
                    <div className="p-grid p-col-12">
                        {this.state.error.newPassword1 &&
                        <div className="validation-msg"><Message severity="error" text={this.state.error.newPassword1}/></div>}
                    </div>

                    <div className="p-grid p-col-12 ">
                        <label className="o-label p-col-5" htmlFor="newPassword2">Powtórz nowe hasło:</label>
                        <InputText placeholder="" id="newPassword2" type="password" required={true}
                            value={this.state.newPassword2} className="o-value p-col-6 validate"
                            style={{"margin": "5px 5px 0px 5px", "padding": "5px"}}
                            onChange={this.handleOnChange} onKeyPress={this.enterPressed.bind(this)}/>
                    </div>
                    <div className="p-grid p-col-12">
                        {this.state.error.newPassword2 &&
                        <div className="validation-msg"><Message severity="error" text={this.state.error.newPassword2}/></div>}
                    </div>

                    <div className="p-grid p-col-12">
                        <button type="submit" className="button btn waves-effect waves-light"
                            onClick={() => this.changePassword()}
                            name="action">Zmień
                        </button>
                    </div>
                </div>
            </Dialog>
        );
    }
}

//https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/withRouter.md
const ChangeAnotherAccountPasswordDialog = withRouter(ChangeAnotherAccountPasswordDialogWithoutRouter);

export default ChangeAnotherAccountPasswordDialog;
