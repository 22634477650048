import RestService from "./RestService";

const PATH = '/version';

const VersionService = {

    getVersion(onSuccess, onError) {
        return RestService.get(PATH, onSuccess, onError);
    },
}

export default VersionService;
