import 'primeicons/primeicons.css';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {InputTextarea} from "primereact/inputtextarea";
import {Messages} from "primereact/messages";
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
import React from 'react';
import {withRouter} from 'react-router-dom';
import {forwardFromOkiActivationToDs, forwardToDispatcher, rejectAndForwardToInstaller} from "../../config/constants";
import CurrentAccountService from '../../services/CurrentAccountService';
import ErrorService from "../../services/ErrorService";
import OkiActivationService from "../../services/OkiActivationService";
import AssignPanel from "../../shared/AssignPanel";
import OrderRejectionReasonDiv from "../../shared/OrderRejectionReasonDiv";
import '../../static/css/App.css';

const isOrangeLoveDropDownOptions = [{name: "Tak", key: true}, {name: "Nie", key: false}];


class OkiActivationOperation extends React.Component {


    //https://www.robinwieruch.de/react-warning-cant-call-setstate-on-an-unmounted-component/
    _isMounted = false;

    state = {
        selectedOperation: "",
        comment: null,
        orderRejectionReason: null,
        isOrangeLove: null,

    }

    componentDidMount() {
        this._isMounted = true;

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    showError = (summary, detail) => {
        this.messages.show({severity: 'error', summary: summary, detail: detail});
    }


    invokeOperation = (orderId) => {
        this.setState({disabled: true}, function () {
            let data = {};
            if (this.state.comment) {
                data = {comment: this.state.comment};
            }

            if (this.state.selectedOperation) {
                if (this.state.selectedOperation.key === "dysp") {
                    this.forwardToDispatcher(orderId, data);
                } else if (this.state.selectedOperation.key === "reject") {
                    if (this.state.orderRejectionReason) {
                        data["orderRejectionReasonId"] = this.state.orderRejectionReason["key"];
                    }
                    this.rejectAndForwardToInstaller(orderId, data);
                } else if (this.state.selectedOperation.key === forwardFromOkiActivationToDs.key) {
                    this.forwardFromOkiActivationToDs(orderId, data);
                }
            }
        });

    }

    forwardToDispatcher(orderId, data) {
        OkiActivationService.forwardToDispatcher(orderId, data, () => {
            this.props.history.push("/okiActivation/order");
            this.setState({disabled: false});
        }, (error) => {
            ErrorService.getErrorMsgToShow(error, (summary, detail) => {
                this.showError(summary, detail);
                this.setState({disabled: false});
            });

        });
    }


    rejectAndForwardToInstaller(orderId, data) {
        OkiActivationService.rejectAndForwardToInstaller(orderId, data, () => {
            this.setState({disabled: false});
            this.props.history.push("/okiActivation/order");
        }, (error) => {
            ErrorService.getErrorMsgToShow(error, (summary, detail) => {
                this.showError(summary, detail);
                this.setState({disabled: false});
            });
        });
    }

    forwardFromOkiActivationToDs(orderId, data) {

        if (!this.state.isOrangeLove) {
            this.showError("Pole Orange Love jest wymagane!", "");
        } else {
            data.isOrangeLove = this.state.isOrangeLove.key;
            OkiActivationService.forwardFromOkiActivationToDs(orderId, data, () => {
                this.setState({disabled: false});
                this.props.history.push("/okiActivation/order");
            }, (error) => {
                ErrorService.getErrorMsgToShow(error, (summary, detail) => {
                    this.showError(summary, detail);
                    this.setState({disabled: false});
                });
            });
        }


    }


    getOperations() {
        const operations = [];

        operations.push(forwardToDispatcher);
        operations.push(rejectAndForwardToInstaller);
        operations.push(forwardFromOkiActivationToDs);

        return operations;
    }

    isOPLUserAssingedToOrder() {
        return this.props.order.assignedOplUser;
    }

    isCurrentOPLUserAssignedToOrder() {
        if (this.props.order.assignedOplUser) {
            let login = this.props.order.assignedOplUser.login;
            return CurrentAccountService.getLogin() === login;
        }

    }


    render() {

        //https://reacttraining.com/react-router/web/example/url-params
        const {match} = this.props;

        return (

            <div className="p-grid nogutter p-col-4 p-component p-align-start p-col-align-start">

                <AssignPanel refreshOrder={this.props.refreshOrder} order={this.props.order}
                             shouldAssign={this.props.shouldAssign}/>

                {this.getOperations(this.props.order).length > 0 && this.isCurrentOPLUserAssignedToOrder() ?
                    <>

                        <Messages ref={(el) => this.messages = el}/>
                        <div className="p-grid nogutter p-col-12">
                            <label className="p-col-3 o-label" htmlFor="in">Przekaż zlecenie:</label>
                            <span className="o-value">
                                <Dropdown showClear={true}
                                          optionLabel="name"
                                          value={this.state.selectedOperation}
                                          options={this.getOperations(this.props.order)}
                                          onChange={(event) => {
                                              this.setState({selectedOperation: event.value});
                                          }}/>
                            </span>
                        </div>

                        {this.state.selectedOperation && this.state.selectedOperation.key === forwardFromOkiActivationToDs.key
                        &&
                        <div className="p-grid nogutter p-col-12">
                            <label className="p-col-3 o-label" htmlFor="in">Orange love:</label>
                            <span className="o-value">
                                <Dropdown showClear={true}
                                    // style={this.state.isOrangeLove!==null ? {} :style}
                                          className={this.state.isOrangeLove !== null ? "" : "pm-warning"}
                                          optionLabel="name"
                                          value={this.state.isOrangeLove}
                                          options={isOrangeLoveDropDownOptions}
                                          onChange={(event) => {
                                              this.setState({isOrangeLove: event.value});
                                          }}/>
                            </span>

                        </div>
                        }

                        <OrderRejectionReasonDiv selectedOperation={this.state.selectedOperation}
                                                 onChange={(event) => {
                                                     this.setState({orderRejectionReason: event.value});
                                                 }
                                                 }/>


                        <div className="p-grid nogutter p-col-12">
                            <label className="p-col-3 o-label">Komentarz:</label>
                            <span className="o-value">
                                <InputTextarea rows={4} cols={30} autoResize={true}
                                               onChange={(e) => this.setState({comment: e.target.value})}/>
                            </span>
                        </div>

                        <div className="p-grid nogutter p-col-12 p-justify-start">
                            <span className="p-col-3 o-label"/>
                            <Button disabled={this.state.disabled || !this.state.selectedOperation}
                                    style={{"marginLeft": "10px", "marginTop": "5px"}}
                                    className="o-value" onClick={() => {
                                this.invokeOperation(match.params.id);
                            }} label="Przekaż"/>
                        </div>
                    </>
                    : null}
            </div>
        );

    }
}

export default withRouter(OkiActivationOperation);
