import axios from "axios";

//https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript


const OrderService = {


    addOPLNote(id,note, onSuccess, onError) {

        return axios.patch(`/order/${id}/opl/note`,{comment:note})
            .then(function (response) {
                if (response.status===204){
                    onSuccess(response.data);
                } else {
                    const error = `Incorrect response status ${response.status}`;
                    onError(error);
                }

            })
            .catch(function (error) {
                onError(error);
            });


    },

    getById(id, onSuccess, onError) {

        return axios.get(`/order/${id}`)
            .then(function (response) {
                if (response.status===200){
                    onSuccess(response.data);
                } else {
                    const error = `Incorrect response status ${response.status}`;
                    onError(error);
                }

            })
            .catch(function (error) {
                onError(error);
            });


    },

    assingOplUser(id, onSuccess, onError) {

        return axios.put(`/order/${id}/opl/assign`)
            .then(function (response) {
                if (response.status===204){
                    onSuccess(response.data);
                } else {
                    const error = `Incorrect response status ${response.status}`;
                    onError(error);
                }

            })
            .catch(function (error) {
                onError(error);
            });


    },
    assingOplUserSync(id) {
        return axios.put(`/order/${id}/opl/assign`);
    },
    unassignOplUser(id, onSuccess, onError) {

        return axios.put(`/order/${id}/opl/unassign`)
            .then(function (response) {
                if (response.status===204){
                    onSuccess(response.data);
                } else {
                    const error = `Incorrect response status ${response.status}`;
                    onError(error);
                }

            })
            .catch(function (error) {
                onError(error);
            });


    },

};

export default OrderService;

