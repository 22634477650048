import axios from "axios/index";

const PATH = "/role";

const RoleService = {



    getAll( onSuccess, onError) {

        return axios.get(PATH)
            .then(function (response) {
                console.log(` response${response}`);
                if (response.status===200){
                    onSuccess(response.data);
                } else {
                    const error = `Incorrect response status ${response.status}`;
                    if (onError){
                        onError(error);
                    }
                }

            })
            .catch(function (error) {
                if (onError){
                    onError(error);
                }
            });

    },






};

export default RoleService;

