import '../../static/css/App.css';
import React, {Component} from 'react';
import HeartbeatService from "../../services/HeartbeatService";

class Heartbeat extends Component {

    componentDidMount() {
        this.heartbeat();
    }

    heartbeat = () => {
        HeartbeatService.heartbeat().then();
    }

    render() {
        return <div/>;
    }

}

export default Heartbeat;
