import RestService from "./RestService";

const OplAccountService = {


    getPhoneNumber(onSuccess, onError) {
        RestService.get(`/opl/account/phoneNumber`, onSuccess, onError);
    },

};

export default OplAccountService;

