import React from "react";
import {DateTimeField, OrderUtil} from "./Utils";
import {Link} from "react-router-dom";
import {NO_AREA, NO_DATA} from "../config/constants";
import _ from "lodash";

export const CompanyTemplateFromInstallers = (rowData) => {
    return <div>
        {joinCompaniesToOneString(rowData.installers)}
    </div>;
};

export const CompanyTemplateFromArea = (rowData) => {
    return <div>
        {getCompanyFromOrderArea(rowData)}
    </div>;
};


// https://www.npmjs.com/package/react-moment
export const DateTimeTemplate = (rowData, column) => {
    return (
        <span>{rowData[column.field] && <DateTimeField dateTimeString={rowData[column.field]}/>} </span>
    );
};

export const FirstLastNameLoginBody = (rowData, column) => {
    const account = rowData[column.field];
    return (
        <> {account ? `${account['firstName']} ${account['lastName']} (${account['login']})` : ""} </>
    );
};

export const InstallerOrAccountBody = (rowData, column) => {
    const installer = rowData[column.field];
    if (installer) {
        return OrderUtil.prepareInstallerText(installer);
    } else {
        const account = rowData[column.accountField];
        return OrderUtil.prepareAccountText(account);
    }
};

export const LocationBody = (rowData, column) => {
    const location = rowData[column.field];
    return (
        <span className={location ? "" : "noData"}>{location ? location : NO_DATA}</span>
    );
}

export const AreaBody = (rowData, column) => {
    const area = _.get(rowData, column.field);
    return (
        <span className={area === NO_AREA ? "noData" : ""}>{area === NO_AREA ? NO_DATA : area}</span>
    );
}

export const TechnologyDlaBody = (rowData, column) => {
    const technologyDla = _.get(rowData, column.field);
    const activationCounter = _.get(rowData, 'activationCounter');
    return (
        activationCounter > 0 &&
        <span className={technologyDla  ? "" : "noData"}>{technologyDla ? technologyDla : NO_DATA}</span>
    );
}

export const OrderIdTemplate = (rowData) => {
    return <div>
        <Link to={`order/${rowData.id}`}>{rowData.id}</Link>
    </div>;
};

export const AccountLoginTemplate = (rowData) => {
    return <div>
        <Link to={`account/${rowData.id}`}>{rowData.login}</Link>
    </div>;
};

export const TextAreaRowTemplate = (rowData, column) => {
    return (
        <textarea className={"commenTextArea"} value={rowData[column.field]} readOnly={true}/>
    );
};

export const DeviceIdTemplate = (rowData) => {
    return <div>
        <a>{rowData.device.id}</a>
    </div>;
};
export const AIdTemplate = (rowData, column) => {
    return <div>
        <a>{rowData[column.field]}</a>
    </div>;
};

export const createParams = (filters, sortBy) => {
    const params = {};
    for (const key in filters) {
        if (filters[key] === false || filters[key] && filters[key] !== '') {
            let element = filters[key];

            if (Array.isArray(element) && element.length > 0) {
                element = convertToIdArray(element, params, key);
            } else if (filters[key].hasOwnProperty('id')) {
                params[key] = filters[key]['id'];
            } else {
                params[key] = filters[key];
            }
        }
    }
    if (sortBy) {
        params["sort"] = sortBy;
    }
    return params;
};


export const sumDelays = (delays) => {

    if (delays) {
        const array = delays.map(d => d.delayInMinutes);
        if (array && array.length > 0) {
            return array.reduce((total, num) => {
                return total + num;
            });
        }

    }
};

/*
    Converting from  [{id:10, name:"ssss"}]  to  [10]
*/
function convertToIdArray(element, params, key) {
    const firstElement = element[0];
    if (firstElement.hasOwnProperty('id')) {
        element = element.map(sel => sel['id']);
        params[key] = element;
    }
    return element;
}

function joinCompaniesToOneString(installers) {

    return installers
        .filter((installer) => installer)
        .map((installer) => installer.company)
        .filter((company) => company)
        .map((company) => company.name)
        .filter((name) => name && name !== "")
        .join(",");

}

export function getCompanyFromOrderArea(order) {

    if (order.area) {
        return order.area.company ? order.area.company['verboseName'] : null;

    }

}


