import React, {Component} from 'react';
import {Dropdown} from "primereact/dropdown";


class WifiAnalyzeAddedDropdown extends Component {


    state = {
        values: [
            {"name": "Tak", "id":true},
            {"name": "Nie", "id":false},
        ],
        value: null
    }

    componentDidMount() {
    }


    render() {

        return (
            <Dropdown id={this.props.id}
                showClear={typeof this.props.showClear !== 'undefined' ? this.props.showClear : true}
                className={this.props.className} optionLabel="name"
                style={this.props.style}
                dataKey={"id"}
                value={this.props.value}
                options={this.state.values}
                onChange={(event) => {
                    return this.props.onChange(event.value);
                }}/>

        );
    }
}

export default WifiAnalyzeAddedDropdown;
