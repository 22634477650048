import RestService from "./RestService";

const PATH = "/admin/testOps";

const AdminTestOpsService = {
    createNewOrder(onSuccess, onError) {
        RestService.get(PATH + "/order", onSuccess, onError);
    },
    sendStatus(orderNumber, status, onSuccess, onError) {
        RestService.getWithParams(PATH + "/order/status", {
            'orderNumber': orderNumber,
            'status': status
        }, onSuccess, onError);
    },
    sendLate(orderNumber, onSuccess, onError) {
        this.sendStatus(orderNumber, "PT_LATE", onSuccess, onSuccess);
    },
    sendEnroute(orderNumber, onSuccess, onError) {
        this.sendStatus(orderNumber, "PT_ENROUTE", onSuccess, onSuccess);
    },
    sendStart(orderNumber, onSuccess, onError) {
        this.sendStatus(orderNumber, "PT_START_INSTALLATION", onSuccess, onSuccess);
    },
    sendNoResponse(orderNumber, onSuccess, onError) {
        this.sendStatus(orderNumber, "PT_NO_RESPONSE", onSuccess, onSuccess);
    },
    sendWaitingForClient(orderNumber, onSuccess, onError) {
        this.sendStatus(orderNumber, "PT_WAITING_FOR_CLI", onSuccess, onSuccess);
    },
    sendResignation(orderNumber, onSuccess, onError) {
        this.sendStatus(orderNumber, "PT_RESIGNATION", onSuccess, onSuccess);
    },
    sendFinish(orderNumber, onSuccess, onError) {
        RestService.getWithParams(PATH + "/order/finishedWork", {
            "orderNumber": orderNumber,
            "success": true
        }, onSuccess, onError);
    },
};

export default AdminTestOpsService;