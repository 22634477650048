import { MultiSelect } from "primereact/multiselect";
import React from 'react';
import OrderRejectionReasonService from "../../services/dict/OrderRejectionReasonService";


class OrderRejectionReasonMultiSelect extends React.Component {


    state = {
        values: [],
        val: null

    }

    componentDidMount() {
        OrderRejectionReasonService.getAll((values) => {
            this.setState({values: values});
        }, (error) => {
            console.log(`OrderRejectionReasonMultiSelect.getByCompanyId()->error${JSON.stringify(error)}`);
        });

    }


    render() {

        return (

            <MultiSelect showClear={true} className={this.props.className} optionLabel="verboseName"
                value={this.props.value?this.props.value:this.state.val} options={this.state.values} placeholder={this.props.placeholder} filter={true}
                dataKey={"id"}
                onChange={(event) => {
                    this.setState({"val": event.value});
                    if (this.props.onChange) {
                        this.props.onChange(event);
                    }
                }}/>

        );
    }
}

export default OrderRejectionReasonMultiSelect;
