import axios from "axios";
import RestService from "./RestService";

//https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript

const PATH = "/okiActivation/order";

const OkiActivationService = {


    getById(id, onSuccess, onError) {
        return RestService.get(`${PATH}/${id}`, onSuccess, onError);
    },

    getByIdWithTimestamp(id, modificationDateTime, onSuccess, onError) {
        const params = { 'timestamp': modificationDateTime };
        return RestService.getWithParams(`${PATH}/${id}`, params, onSuccess, onError);
    },

    getEventsByIdWithTimestamp(id, modificationDateTime, onSuccess, onError) {
        let params = { 'timestamp': modificationDateTime };
        return RestService.getWithParams(PATH + "/" + id + "/events", params, onSuccess, onError);
    },

    pullFromQueueAndAssign(onSuccess, onError) {
        RestService.getWithParams(`${PATH}/assign`, {}, onSuccess, onError);
    },

    forwardToDispatcher(orderId, data, onSuccess, onError) {

        return axios.put(`/okiActivation/order/${orderId}/dispatcher`, data)
            .then(function (response) {
                if (response.status === 204) {
                    onSuccess(response.data);
                } else {
                    const error = `Incorrect response status ${response.status}`;
                    console.log(`Error Body: ${JSON.stringify(response.data)}`);
                    onError(error);
                }
            })
            .catch(function (error) {
                if (error && error.response) {
                    console.log(`Error body + ${JSON.stringify(error.response.data)}`);
                }

                onError(error);
            });


    },


    forwardFromOkiActivationToDs(orderId, data, onSuccess, onError) {
        RestService.update(`/okiActivation/order/${orderId}/forwardToDs`, data, onSuccess, onError);
    },

    rejectAndForwardToInstaller(orderId, data, onSuccess, onError) {

        return axios.put(`/okiActivation/order/${orderId}/reject`, data)
            .then(function (response) {
                if (response.status === 204) {
                    onSuccess(response.data);
                } else {
                    const error = `Incorrect response status ${response.status}`;
                    console.log(`Error Body: ${JSON.stringify(response.data)}`);
                    onError(error);
                }
            })
            .catch(function (error) {
                if (error && error.response) {
                    console.log(`Error body + ${JSON.stringify(error.response.data)}`);
                }

                onError(error);
            });


    },


    getMainQueueOrders(params, pageNumber, pageSize, onSuccess, onError) {

        if (!params) {
            params = {};
        }
        params["page"] = pageNumber;
        params["size"] = pageSize;

        return axios.get("/okiActivation/order", {
            params: params
        })
            .then(function (response) {
                if (response.status === 200) {
                    onSuccess(response.data);
                } else {
                    const error = `Incorrect response status ${response.status}`;
                    onError(error);
                }

            })
            .catch(function (error) {
                onError(error);
            });


    },

    getHistoryOrders(params, pageNumber, pageSize, onSuccess, onError) {

        if (!params) {
            params = {};
        }
        params["page"] = pageNumber;
        params["size"] = pageSize;

        return axios.get("/okiActivation/order/history", {
            params: params
        })
            .then(function (response) {
                if (response.status === 200) {
                    onSuccess(response.data);
                } else {
                    const error = `Incorrect response status ${response.status}`;
                    onError(error);
                }

            })
            .catch(function (error) {
                onError(error);
            });


    }


};

export default OkiActivationService;

