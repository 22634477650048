import '../../static/css/App.css';
import React from 'react';
import {withRouter} from "react-router";
import Account from "./Account";


class AddAccount extends React.Component {

    render() {

        return (
            <Account/>
        );

    }
}

export default withRouter(AddAccount);


