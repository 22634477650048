import React from 'react';
import CacheService from "../../services/CacheService";
import {withRouter} from "react-router";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Messages} from "primereact/messages";
import {defaultSummary, defaultDetail} from "../../config/constants";

class CacheSettings extends React.Component {


    state = {
        successDialogVisible: false
    }

    render() {


        return (
            <div>

                <Messages ref={(el) => this.messages = el}/>

                <h2 style={{"marginTop": "0px"}}>Pamięć podręczna</h2>
                <div className="p-component">
                    <p style={{"fontSize": "12px"}}>
                        Aplikacja mobilna (po zalogowaniu się użytkownika i przed uzupełnieniem fotomontażu) pobiera
                        konfigurację z serwera (słowniki, konfiguracja procesów biznesowych).
                        <br></br>
                        Ze względu na wydajność systemu, dane te są przechowywane w pamięci podręcznej.
                        <br></br>
                        Po modyfikacji słowników lub konfiguracji procesów biznesowych należy
                        zresetować pamięć podręczną, aby aplikacja mobilna mogła pobrać aktualną konfigurację.
                        <br></br>
                        Uwaga !!! Operacja resetu pamięci podręcznej jest kosztowna i nie należy jej nadużywać.
                    </p>
                </div>
                <br></br>
                <button className="p-button p-component p-button-text-only" onClick={this.resetCache()}>
                    <span className={"p-button-text p-c"}> Reset</span>
                </button>

                <Dialog header="Operacja wykonana prawidłowo" visible={this.state.successDialogVisible}
                    onHide={() => {
                        this.setState({successDialogVisible: false});
                    }}>
                    <div className=" p-col-12 p-col p-grid p-nogutter p-justify-center p-align-center">
                        <Button label="OK" onClick={() => {
                            this.setState({successDialogVisible: false});
                        }}/>
                    </div>
                </Dialog>

            </div>
        );

    }

    showError = (summary, detail) => {
        this.messages.show({severity: 'error', summary: summary, detail: detail});
    }


    resetCache() {
        return () => {
            CacheService.reset(() => {
                this.setState({successDialogVisible: true});
            }, () => {
                this.showError(defaultSummary, defaultDetail);
            });
        };
    }
}

export default withRouter(CacheSettings);
