import {PanelMenu} from 'primereact/panelmenu';
import React from 'react';
import {withRouter} from "react-router";
import {
    ADMIN_ROLE_KEY, ASSIGNMENT_MANAGEMENT_ROLE_KEY,
    BOT_DS_READONLY_ROLE_KEY,
    BOT_OKI_READONLY_ROLE_KEY,
    DEALER_SUPPORT_ROLE_KEY,
    FTTH_MONITORING_READONLY_ROLE_KEY,
    FTTH_MONITORING_ROLE_KEY,
    OKI_ROLE_KEY,
    ORDER_PREVIEW_ROLE_KEY,
    WCKU_ROLE_KEY
} from "../../config/constants";
import AuthService from "../../services/AuthService";
import CurrentAccountService from "../../services/CurrentAccountService";
import FilterSaver from '../../shared/FilterSaver';
import '../../static/css/App.css';


class LeftMenuWithRouter extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            items: []
        };

        const roles = CurrentAccountService.getRoles();

        // console.log("Roles = " +JSON.stringify(roles,null,2));

        if (CurrentAccountService.hasRoleInArray(DEALER_SUPPORT_ROLE_KEY, roles)) {
            this.state.items.push({
                label: 'Fotomontaże - Dealer Support',
                items: [
                    {
                        label: 'Nowe fotomontaże', icon: 'pi pi-fw ', command: this.redirect("/dealerSupport/order")
                    },
                    {
                        label: 'Historia fotomontaży',
                        icon: 'pi pi-fw ',
                        command: this.redirect("/dealerSupport/order/history")
                    },
                ],
            });
        }

        if (CurrentAccountService.hasRoleInArray(DEALER_SUPPORT_ROLE_KEY, roles)) {
            this.state.items.push({
                label: 'Fotomontaże - NJU',
                items: [
                    {
                        label: 'Nowe fotomontaże', icon: 'pi pi-fw ', command: this.redirect("/nju/order")
                    },
                    {
                        label: 'Historia fotomontaży',
                        icon: 'pi pi-fw ',
                        command: this.redirect("/nju/order/history")
                    },
                ],
            });
        }

        if (CurrentAccountService.hasRoleInArray(BOT_OKI_READONLY_ROLE_KEY, roles)) {
            this.state.items.push({
                label: 'Robot OKI - podgląd',
                items: [
                    {
                        label: 'Nowe fotomontaże', icon: 'pi pi-fw ', command: this.redirect("/botOkiPreview/order")
                    },
                    {
                        label: 'Historia fotomontaży',
                        icon: 'pi pi-fw ',
                        command: this.redirect("/botOkiPreview/order/history")
                    },

                ],
            })
        }

        if (CurrentAccountService.hasRoleInArray(BOT_DS_READONLY_ROLE_KEY, roles)) {
            this.state.items.push({
                label: 'Robot DS - podgląd',
                items: [
                    {
                        label: 'Nowe fotomontaże', icon: 'pi pi-fw ', command: this.redirect("/botDsPreview/order")
                    },
                    {
                        label: 'Historia fotomontaży',
                        icon: 'pi pi-fw ',
                        command: this.redirect("/botDsPreview/order/history")
                    },

                ],
            })
        }

        if (CurrentAccountService.hasRoleInArray(WCKU_ROLE_KEY, roles)) {
            this.state.items.push({
                label: 'Fotomontaże - WCKU',
                items: [
                    {
                        label: 'Nowe fotomontaże', icon: 'pi pi-fw ', command: this.redirect("/wcku/order")
                    },
                    {
                        label: 'Historia fotomontaży', icon: 'pi pi-fw ', command: this.redirect("/wcku/order/history")
                    },
                ],
            });
        }

        if (CurrentAccountService.hasRoleInArray(DEALER_SUPPORT_ROLE_KEY, roles)) {
            this.state.items.push({
                label: 'Fotomontaże - aktywacje xDSL',
                items: [
                    {
                        label: 'Nowe fotomontaże', icon: 'pi pi-fw ', command: this.redirect("/okiActivation/order")
                    },
                    {
                        label: 'Historia fotomontaży',
                        icon: 'pi pi-fw ',
                        command: this.redirect("/okiActivation/order/history")
                    },
                ],
            });
        }

        if (CurrentAccountService.hasRoleInArray(OKI_ROLE_KEY, roles)) {
            this.state.items.push({
                label: 'Fotomontaże - OKI - wymiany',
                items: [
                    {
                        label: 'Nowe fotomontaże', icon: 'pi pi-fw ', command: this.redirect("/okiReplacement/order")
                    },
                    {
                        label: 'Historia fotomontaży',
                        icon: 'pi pi-fw ',
                        command: this.redirect("/okiReplacement/order/history")
                    },
                ],
            });
        }


        const monitoring = {
            label: 'Monitoring zleceń',
            items: [
                {
                    label: 'Dzisiaj', icon: 'pi pi-fw ', command: this.redirect("/monitoring/order/today")
                },
                {label: 'Do podjęcia', icon: 'pi pi-fw ', command: this.redirect("/monitoring/order/toTake")},
                {label: 'Do podjęcia - opóźnione', icon: 'pi pi-fw ', command: this.redirect("/monitoring/order/late")},
                // {label: 'Opóźnione', icon: 'pi pi-fw ', command: this.redirect("/monitoring/order/delayed")},
                {label: 'Historia', icon: 'pi pi-fw ', command: this.redirect("/monitoring/order/history")},
            ]
        };

        if (CurrentAccountService.hasRoleInArray(FTTH_MONITORING_ROLE_KEY, roles)
            && CurrentAccountService.hasRoleInArray(ASSIGNMENT_MANAGEMENT_ROLE_KEY, roles)) {
            monitoring['items'].push({
                label: 'Konfiguracja',
                icon: 'pi pi-fw ',
                command: this.redirect("/monitoring/settings")
            });
        }

        if (CurrentAccountService.hasRoleInArray(FTTH_MONITORING_READONLY_ROLE_KEY, roles)) {
            monitoring['items'].push({
                label: 'Wszystkie',
                icon: 'pi pi-fw ',
                command: this.redirect("/monitoring/order/all")
            });
        }

        if (CurrentAccountService.hasRoleInArray(FTTH_MONITORING_ROLE_KEY, roles) || CurrentAccountService.hasRoleInArray(FTTH_MONITORING_READONLY_ROLE_KEY, roles)) {
            this.state.items.push(monitoring);
        }

        // ADMIN
        if (CurrentAccountService.hasRoleInArray(ADMIN_ROLE_KEY, roles)) {
            this.state.items.push(
                {
                    label: 'Użytkownicy',
                    items: [{
                        label: 'Lista', icon: 'pi pi-fw ', command: this.redirect("/accounts")
                    }, {
                        label: 'Dodaj', icon: 'pi pi-fw ', command: this.redirect("/account/add")
                    }]
                },
                {
                    label: 'Aktualności',
                    items: [{
                        label: 'Aktualności', icon: 'pi pi-fw pi-bell', command: this.redirect("/news")
                    }],

                },
                {
                    label: 'Zarządzanie słownikami danych',
                    items: [
                        {
                            label: 'Powody odrzucenia zlecenia',
                            icon: 'pi pi-fw ',
                            command: this.redirect("/dictionary/orderRejectionReason")
                        },
                        {
                            label: 'Strefy utrzymaniowe', icon: 'pi pi-fw ', command: this.redirect("/dictionary/area")
                        },
                        {
                            label: 'Statusy umowy',
                            icon: 'pi pi-fw ',
                            command: this.redirect("/dictionary/contractStatus")
                        },
                        {
                            label: 'Statusy urządzeń',
                            icon: 'pi pi-fw ',
                            command: this.redirect("/dictionary/deviceStatus")
                        },
                        {
                            label: 'Statusy zlecenia',
                            icon: 'pi pi-fw ',
                            command: this.redirect("/dictionary/orderStatus")
                        },
                        {
                            label: 'Typy zleceń',
                            icon: 'pi pi-fw ',
                            command: this.redirect("/dictionary/orderType")
                        },
                        {
                            label: 'Typy technologii',
                            icon: 'pi pi-fw ',
                            command: this.redirect("/dictionary/technology")
                        },
                        {
                            label: 'Typy technologii DLA',
                            icon: 'pi pi-fw ',
                            command: this.redirect("/dictionary/technologyDla")
                        },
                        {
                            label: 'Modele urządzeń',
                            icon: 'pi pi-fw ',
                            command: this.redirect("/dictionary/deviceModel")
                        },
                        {
                            label: 'Typy usług', icon: 'pi pi-fw ', command: this.redirect("/dictionary/service")
                        },
                        {
                            label: 'Opcje usługowe',
                            icon: 'pi pi-fw ',
                            command: this.redirect("/dictionary/serviceOption")
                        },
                        {
                            label: 'Globalne ustawienia',
                            icon: 'pi pi-fw ',
                            command: this.redirect("/dictionary/globalConfig")
                        }
                    ]
                },
            );
        }

        if (CurrentAccountService.hasRoleInArray(ORDER_PREVIEW_ROLE_KEY, roles)) {
            this.state.items.push({
                label: 'Podgląd zleceń',
                items: [
                    {
                        label: 'Zlecenia', icon: 'pi pi-fw ', command: this.redirect("/orderPreview/order")
                    }

                ],
            });
        }

        if (CurrentAccountService.hasRoleInArray(ADMIN_ROLE_KEY, roles)) {
            this.state.items.push(
                {
                    label: 'Ustawienia',
                    items: [
                        {label: 'Pamięć podręczna', icon: 'pi pi-fw ', command: this.redirect("/settings/cache")}
                    ]
                },
                {
                    label: 'Konto',
                    items: [
                        {label: 'Wyloguj', icon: 'pi pi-fw pi-power-off', command: () => this.logout()}]
                });
        }
    }


    redirect(path) {
        return () => {
            new FilterSaver().clearFiltersForView(path);
            this.props.history.push(path);
        };
    }

    logout = () => {
        AuthService.signout(() => this.props.history.push("/login"));
    }


    render() {
        return (
            <div>
                <PanelMenu model={this.state.items}/>
            </div>

        );
    }
}

//https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/withRouter.md
const LeftMenu = withRouter(LeftMenuWithRouter);
export default LeftMenu;
