import React from 'react';
import RoleList from "./RoleList";

export const groupTemplate = function (group) {

    var rows = [];
    const roles = group.roles;
    if (roles) {
        for (var i = 0; i < roles.length; i += 1) {
            rows.push(<RoleList role={roles[i]} key={i} />);
        }
    }

    return (
        <div className="p-clearfix">
            <div style={{ float: 'left' }}>{group.verboseName}</div>
            <br />
            {rows}
            {/*<ListBox className="o-value"  options={group.roles}*/}
            {/*optionLabel="verboseName"/>*/}
        </div>
    );
}