class FilterSaver {

    saveFilters(filters) {
        localStorage.setItem(`filters_${window.location.pathname}`, JSON.stringify(filters));
    }

    clearFiltersForView(pathName) {
        console.log(`Path_NAME = ${pathName}`);
        localStorage.removeItem(`filters_${pathName}`);
    }

    getFilters() {
        return JSON.parse(localStorage.getItem(`filters_${window.location.pathname}`));
    }

}

export default FilterSaver;
