import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import React from "react";
import '../static/css/App.css';
import AreaAutoComplete from './autocomplete/AreaAutoComplete';
import OplAccountAutoComple from './autocomplete/OplAccountAutoComplete';
import FinishedWorkDropDown from "./dropdown/FinishedWorkDropDown";
import OplStatusDropdown from './dropdown/OplStatusDropDown';
import PtInClientLocationDropdown from "./dropdown/PtInClientLocationDropdown";
import WifiAnalyzeAddedDropdown from "./dropdown/WifiAnalyzeAddedDropdown";
import FilterSaver from './FilterSaver';
import OrderRejectionReasonMultiSelect from "./multiselect/OrderRejectionReasonMultiSelect";
import OrderStatusMultiSelect from "./multiselect/OrderStatusMultiSelect";
import ServiceMultiSelect from "./multiselect/ServiceMultiSelect";
import TechnologyMultiSelect from "./multiselect/TechnologyMultiSelect";
import RangeCalendar from "./RangeCalendar";
import InstallerAutoComplete from "./autocomplete/InstallerAutoComplete";
import AreaMultiSelect from "./multiselect/AreaMultiselect";


const defaultVisible = true;



export function defaultOnFilterChange(thisPointer) {
    return (newFilter) => {
        console.log(`newFilter${JSON.stringify(newFilter)}`);
        const filters = Object.assign({}, thisPointer.state.filters);
        for (const filter in newFilter) {
            filters[[filter]] = newFilter[filter];
        }
        console.log(`filters${JSON.stringify(filters)}`);
        thisPointer.setState({ filters: filters });
        new FilterSaver().saveFilters(filters);
    };
}
export const defaultOnFilterChangeWithCallback =
    (thisPointer, newFilter, callback) => {
        console.log(`newFilter${JSON.stringify(newFilter)}`);
        const filters = Object.assign({}, thisPointer.state.filters);
        for (const filter in newFilter) {
            filters[[filter]] = newFilter[filter];
        }
        console.log(`filters${JSON.stringify(filters)}`);
        new FilterSaver().saveFilters(filters);
        thisPointer.setState({ filters: filters }, () => {
            callback();
        });

    };

const ModificationDateTimeRow = ({ pointer }) => {
    return (
        <>
            {pointer.state.visible.modificationDateTime && <div className="filter-panel-div">
                <label className="filter-panel-label">Zmodyfikowano</label>
                <RangeCalendar required={true} className="filter-panel-input" placeholder={"Zmodyfikowano"}
                    dateValues={pointer.getDateTableFromFilters(pointer.props.filters, 'modificationDateTimeFrom', 'modificationDateTimeTo')}
                    onUpdate={(from, to, fromToArray) => {
                        pointer.filterChangeJsonParam(
                            {
                                "modificationDateTimeTo": to,
                                "modificationDateTimeFrom": from
                            });

                    }}
                />

            </div>
            }</>);
}

class OrderFilterPanel extends React.Component {


    state = {
        sortDropdownData: [],
        installers: [],
        modificationDateTimeTable: null,
        visible: {
            id: defaultVisible,
            neoId: defaultVisible,
            number: defaultVisible,
            technologyId: defaultVisible,
            companyId: defaultVisible,
            creationDateTime: defaultVisible,
            sortField: defaultVisible,
            serviceId: defaultVisible,
            areaId: defaultVisible,
            byInstaller: true,
            status: true,
            finishedWorkSuccess: false,
            finishedWorkDateTime: false,
            timeSlot: true,
            location: true,
            handledByUserId: true,
            modificationDateTime: true,
            orderRejectionReason: true,
            ptInClientLocation: false,
            wifiAnalyzeAdded: false,
            oplUserAssigned: defaultVisible,
            oplStatus: false
        }
    }


    constructor(props) {
        super(props);
        const sortTable = [
            { name: 'Pole id, rosnąco', key: 'id,asc' },
            { name: 'Pole id, malejąco', key: 'id,desc' },
            { name: 'Pole data utworzenia, rosnąco', key: 'creationDateTime,asc' },
            { name: 'Pole data utworzenia, malejąco', key: 'creationDateTime,desc' },
            { name: 'Pole data modyfikacji, rosnąco', key: 'modificationDateTime,asc' },
            { name: 'Pole data modyfikacji, malejąco', key: 'modificationDateTime,desc' },
        ];
        const sortDropdownData = this.props.sortDropdownData;
        console.log(`sortDropdownData${sortDropdownData}`);
        if (!sortDropdownData) {
            this.state.sortDropdownData = sortTable;
        } else {
            this.state.sortDropdownData = sortDropdownData;
        }




        // props.visible="sortField,serviceId";
        const visible = this.props.visible;
        if (visible) {
            console.log(`visible=${visible}`);
            const array = visible.split(",");
            console.log(`visible=${JSON.stringify(array)}`);
            for (const i in array) {
                console.log(`id = ${array[i]}`);
                this.state.visible[array[i]] = true;
            }
        }
        const visibleObject = this.props.visibleObject;
        if (visibleObject) {
            const result = {
                ...this.state.visible,
                ...visibleObject,
            };
            console.log(`RES${JSON.stringify(result)}`);
            this.state.visible = result;
        }


    }

    componentDidMount() {

    }



    saveFilter(value, property) {

        const onFilterChange = this.props.onFilterChange;
        if (onFilterChange) {
            onFilterChange({ [property]: value });
        }

    }

    saveFiltersFromMultiselect(event, property) {
        const values = event.value;
        if (values) {
            const onFilterChange = this.props.onFilterChange;
            if (onFilterChange) {
                onFilterChange({ [property]: values });
            }

        }
    }

    saveFiltersFromCalendar(event, property) {
        const val = event ? event.value : null;
        this.filterChange(val, property);
        this.setState({ [property]: val });

    }

    filterChange(val, property) {
        const onFilterChange = this.props.onFilterChange;
        if (onFilterChange) {
            onFilterChange({ [property]: val });
        }
    }

    filterChangeJsonParam(param) {
        const onFilterChange = this.props.onFilterChange;
        if (onFilterChange) {
            onFilterChange(param);
        }
    }


    getDateTableFromFilters(filters, keyFrom, keyTo) {
        var from = filters[keyFrom];

        if (from) {
            from = new Date(from);
        }

        var to = filters[keyTo];
        if (to) {
            to = new Date(to);
        }
        if (!from && !to) {// Important
            return [];
        }

        return [from, to];
    }

    filterBtnDisabled() {
        let modifTable = this.getDateTableFromFilters(this.props.filters, 'modificationDateTimeFrom', 'modificationDateTimeTo');
        if (modifTable) {
            if (modifTable[0]) {
                return false;
            }

        }
        return true;
    }
    ifNoValueReturnEmptyString(val) {
        return val ? val : '';
    }

    ifNoValueReturnEmptyTable(val) {
        return val ? val : [];
    }

    render() {

        var filters = this.props.filters ? this.props.filters : {};
        var filter = this.props.filter;

        return (
            <div className="filter-panel">

                {filter ?
                    <div className="filter-panel-field-div">


                        {this.state.visible.id && <div className="filter-panel-div">
                            <label htmlFor="searchInputField" className="filter-panel-label">Id FM</label>
                            <InputText className="filter-panel-input" id="searchInputField"
                                value={this.ifNoValueReturnEmptyString(filters.id)}
                                keyfilter="pint"
                                onChange={(event) => {
                                    const val = event.target ? event.target.value : null;
                                    const onFilterChange = this.props.onFilterChange;
                                    if (onFilterChange) {
                                        onFilterChange({ "id": val });
                                    }

                                }} />
                        </div>
                        }
                        {this.state.visible.status && <div className="filter-panel-div">
                            <label className="filter-panel-label">Status</label>
                            <OrderStatusMultiSelect value={this.ifNoValueReturnEmptyTable(filters.statusId)} statusFilter={this.props.statusFilter} className="filter-panel-input"
                                onChange={(event) => {
                                    this.saveFiltersFromMultiselect(event, "statusId");
                                }} />
                        </div>
                        }

                        {this.state.visible.neoId && <div className="filter-panel-div">
                            <label htmlFor="searchInputField1" className="filter-panel-label">Id usługi</label>
                            <InputText className="filter-panel-input" id="searchInputField1" value={this.ifNoValueReturnEmptyString(filters.neoId)}
                                onChange={(event) => {
                                    const val = event.target ? event.target.value : null;
                                    const onFilterChange = this.props.onFilterChange;
                                    if (onFilterChange) {
                                        onFilterChange({ "neoId": val });
                                    }

                                }} />
                        </div>
                        }
                        {this.state.visible.number && <div className="filter-panel-div">
                            <label htmlFor="searchInputField2" className="filter-panel-label">Numer zlecenia</label>
                            <InputText className="filter-panel-input" id="searchInputField2" value={this.ifNoValueReturnEmptyString(filters.number)}
                                onChange={(event) => {
                                    const val = event.target ? event.target.value : null;
                                    const onFilterChange = this.props.onFilterChange;
                                    if (onFilterChange) {
                                        onFilterChange({ "number": val });
                                    }

                                }} />
                        </div>
                        }


                        {/*{this.state.visible.areaId && <div className="filter-panel-div">
                            <label className="filter-panel-label">Strefa</label>
                            <AreaAutoComplete statusFilter={this.props.statusFilter} className="filter-panel-input"
                                value={filters.areaId}
                                onChange={(event) => {
                                    this.saveFiltersFromMultiselect(event, "areaId");
                                }} />

                        </div>
                        }*/}

                        {this.state.visible.areaId && <div className="filter-panel-div">
                            <label className="filter-panel-label">Strefa</label>
                            <AreaMultiSelect statusFilter={this.props.statusFilter} className="filter-panel-input"
                                              value={this.ifNoValueReturnEmptyTable(filters.areaId)}
                                              onChange={(event) => {
                                                  this.saveFiltersFromMultiselect(event, "areaId");
                                              }} />

                        </div>
                        }

                        {this.state.visible.technologyId &&
                            <div className="filter-panel-div">
                                <label className="filter-panel-label">Technologia</label>
                                <TechnologyMultiSelect statusFilter={this.props.statusFilter} className="filter-panel-input"
                                    value={this.ifNoValueReturnEmptyTable(filters.technologyId)}
                                    onChange={(event) => {
                                        this.saveFiltersFromMultiselect(event, "technologyId");
                                    }} />

                            </div>
                        }

                        {this.state.visible.orderRejectionReason &&
                            <div className="filter-panel-div">
                                <label className="filter-panel-label">Powód odrzucenia zgłoszenia</label>
                                <OrderRejectionReasonMultiSelect
                                    statusFilter={this.props.statusFilter} className="filter-panel-input"
                                    value={this.ifNoValueReturnEmptyTable(filters.rejectionReasonId)}
                                    onChange={(event) => {
                                        this.saveFiltersFromMultiselect(event, "rejectionReasonId");
                                    }} />

                            </div>
                        }

                        {this.state.visible.byInstaller &&
                        <div className="filter-panel-div">
                            <label className="filter-panel-label">Serwisant</label>
                            <InstallerAutoComplete
                                value={filters.installerId}
                                statusFilter={filters.statusFilter} className="filter-panel-input"
                                onChange={(event) => {
                                    this.saveFiltersFromMultiselect(event, "installerId");
                                }} />

                        </div>
                        }


                        {this.state.visible.handledByUserId &&
                            <div className="filter-panel-div">
                                <label className="filter-panel-label">Obsłużył w OPL</label>
                                <OplAccountAutoComple
                                    value={filters.handledByUserId}
                                    statusFilter={this.props.statusFilter} className="filter-panel-input"
                                    onChange={(event) => {
                                        this.saveFiltersFromMultiselect(event, "handledByUserId");
                                    }} />

                            </div>
                        }


                        {this.state.visible.serviceId && <div className="filter-panel-div">
                            <label className="filter-panel-label">Usługa</label>

                            <ServiceMultiSelect statusFilter={this.props.statusFilter} className="filter-panel-input"
                                value={this.ifNoValueReturnEmptyTable(filters.serviceId)}
                                onChange={(event) => {
                                    this.saveFiltersFromMultiselect(event, "serviceId");
                                }} />
                        </div>
                        }

                        {/* {this.state.visible.finishedWorkSuccess && <div className="filter-panel-div">
                            <label className="filter-panel-label ">Status zakończenia interwencji</label>
                            <FinishedWorkDropDown statusFilter={this.props.statusFilter} className="filter-panel-input"
                                value={filters.finishedWorkSuccess}
                                onChange={(val) => {
                                    this.saveFilter(val, "finishedWorkSuccess");
                                }} />

                        </div>
                        } */}

                        {/* {this.state.visible.ptInClientLocation && <div className="filter-panel-div">
                            <label className="filter-panel-label ">Czy PT jest w lokalu Klienta?</label>
                            <PtInClientLocationDropdown statusFilter={this.props.statusFilter} className="filter-panel-input"
                                value={filters.ptInClientLocation}
                                onChange={(val) => {
                                    this.saveFilter(val, "ptInClientLocation");
                                }} />

                        </div> }*/}
                        

                        {this.state.visible.wifiAnalyzeAdded && <div className="filter-panel-div">
                            <label className="filter-panel-label ">Analiza Wi-Fi</label>
                            <WifiAnalyzeAddedDropdown statusFilter={this.props.statusFilter} className="filter-panel-input"
                                value={filters.wifiAnalyzeAdded}
                                onChange={(val) => {
                                    this.saveFilter(val, "wifiAnalyzeAdded");
                                }} />

                        </div>
                        }

                        {/* {this.state.visible.finishedWorkDateTime && <div className="filter-panel-div">
                            <label className="filter-panel-label">Data zakończenie interwencji</label>
                            <RangeCalendar className="filter-panel-input" placeholder={"Data zakończenie interwencji"}
                                dateValues={this.getDateTableFromFilters(this.props.filters, 'finishedWorkDateTimeFrom', 'finishedWorkDateTimeTo')}
                                onUpdate={(from, to, fromToArray) => {
                                    this.filterChangeJsonParam(
                                        {
                                            "finishedWorkDateTimeTo": to,
                                            "finishedWorkDateTimeFrom": from
                                        });

                                }}
                            />

                        </div>
                        } */}

                        <ModificationDateTimeRow pointer={this} />

                        {this.state.visible.timeSlot && <div className="filter-panel-div">
                            <label className="filter-panel-label">Data umówienia</label>
                            <RangeCalendar className="filter-panel-input" placeholder={"Data umówienia"}
                                dateValues={this.getDateTableFromFilters(this.props.filters, 'timeSlotFrom', 'timeSlotTo')}
                                onUpdate={(from, to, fromToArray) => {
                                    console.log(`timeSlot from = ${from} to = ${to}`);
                                    this.filterChangeJsonParam(
                                        {
                                            "timeSlotTo": to,
                                            "timeSlotFrom": from
                                        });

                                }}
                            />

                        </div>
                        }


                        {this.state.visible.location && <div className="filter-panel-div">
                            <label htmlFor="searchInputFieldLocal" className="filter-panel-label">Adres</label>
                            <InputText className="filter-panel-input" id="searchInputFieldLocal" value={this.ifNoValueReturnEmptyString(filters.location)}
                                onChange={(event) => {
                                    const val = event.target ? event.target.value : null;
                                    const onFilterChange = this.props.onFilterChange;
                                    if (onFilterChange) {
                                        onFilterChange({ "location": val });
                                    }


                                }} />
                        </div>
                        }


                        {this.state.visible.creationDateTime && <div className="filter-panel-div">
                            <label className="filter-panel-label">Utworzono</label>
                            <RangeCalendar className="filter-panel-input" placeholder={"Utworzono"}
                                dateValues={this.getDateTableFromFilters(this.props.filters, 'creationDateTimeFrom', 'creationDateTimeTo')}
                                onUpdate={(from, to, fromToArray) => {
                                    console.log(`creationDateTime from = ${from} to = ${to}`)
                                    this.filterChangeJsonParam(
                                        {
                                            "creationDateTimeTo": to,
                                            "creationDateTimeFrom": from
                                        });

                                }}
                            />

                        </div>
                        }


                        {this.state.visible.oplUserAssigned && <div className="filter-panel-div">
                            <label className="filter-panel-label">Przypisany użytkownik</label>
                            <TriStateCheckbox value={filters.oplUserAssigned}
                                onChange={(event) => {
                                    const val = event.target ? event.target.value : null;
                                    const onFilterChange = this.props.onFilterChange;
                                    if (onFilterChange) {
                                        onFilterChange({ oplUserAssigned: val });
                                    }

                                }}
                            />

                        </div>
                        }
                        {this.state.visible.oplStatus && <div className="filter-panel-div">
                            <label className="filter-panel-label">Status DS</label>
                            <OplStatusDropdown statusFilter={this.props.statusFilter} className="filter-panel-input"
                                value={filters.oplStatus}
                                onChange={(val) => {
                                    this.saveFilter(val, "oplStatus");
                                }} />

                        </div>
                        }


                    </div>
                    : <ModificationDateTimeRow pointer={this} />}

                <Button disabled={this.filterBtnDisabled()} onClick={() => {
                    const onFilter = this.props.onFilter;
                    if (onFilter) {
                        onFilter();
                    }
                }} label="Filtruj"></Button>
                <Button onClick={() => {
                    const onCleanButton = this.props.onCleanButton;
                    if (onCleanButton) {
                        onCleanButton(filters);
                    }

                }} label="Wyczyść" style={{ "marginLeft": "5px" }} >
                </Button>
            </div>

        );
    }
}

export default OrderFilterPanel;
