import { Growl } from "primereact/growl";
import React from 'react';
import { DEFAULT_PAGE_SIZE, MODIFICATION_DATE_TIME_ASC, QueueType, REFRESH_TABLE_INTERVAL } from "../../config/constants";
import MonitoringService from "../../services/MonitoringService";
import QueueOrderNotificationService from "../../services/QueueOrderNotificationService";
import OrderDataTable from "../../shared/orderDataTable/OrderDataTable";
import OrderFilterLoadService from "../../shared/OrderFilterLoadService";
import OrderFilterPanel, { defaultOnFilterChange } from "../../shared/OrderFilterPanel";
import { createParams } from "../../shared/OrderTableCommon";
import { filtersNotEmptySkipModificationDateTimeFrom, onError, showQueueNewOrdersNotifications, updateTableWithNewData } from "../../shared/Utils";
import TakeLastFromQueueBtn from "../../shared/TakeLastFromQueueBtn";
import '../../static/css/App.css';
import MonitoringOperationUtil from "./MonitoringOperationUtil";

class MonitoringToTakeOrders extends React.Component {

    state = {
        orders: [],
        loading: false,
        first: 1,
        rows: DEFAULT_PAGE_SIZE, // Ilość wierszy na stronie
        totalRecords: 0,// Ilośc wszystkich elementów w bazie
        filters: {},
        area: {},
        sortBy: MODIFICATION_DATE_TIME_ASC,
        filter: false,
        previousModificationTime: new Date(),
        pageNumber: 1,
        startIndex: 0

    }

    constructor(props) {
        super(props);
        this.onPage = this.onPage.bind(this);
    }


    refreshTable() {
        this.getOrders(this.state.filters, this.state.sortBy, this.state.pageNumber, this.state.startIndex, (data) => {
            showQueueNewOrdersNotifications(this, QueueOrderNotificationService.getNewOrdersFromMonitoringToTakeQueue);
        });

    }


    componentDidMount() {
        this.setState({ filters: new OrderFilterLoadService().getFilter(QueueType.MAIN) }, () => {
            this.interval = setInterval(() => this.refreshTable(), REFRESH_TABLE_INTERVAL);
            this.refreshTable();
            this.setState({
                filter: filtersNotEmptySkipModificationDateTimeFrom(this.state.filters)
            });

        });

    }


    componentWillUnmount() {
        clearInterval(this.interval);
    }

    onPage(event) {
        this.setState({
            loading: true
        });
        const startIndex = event.first;
        const pageNumber = startIndex / event.rows + 1;

        this.setState({ pageNumber: pageNumber, startIndex: startIndex, rows: event.rows }, () => {
            this.getOrders(this.state.filters, this.state.sortBy, this.state.pageNumber, this.state.startIndex);
        });

    }


    getOrders(filters, sortBy, pageNumber, startIndex, onSuccess) {

        this.getOrdersWithUpdateTableCallback(filters, sortBy, pageNumber, startIndex, onSuccess,
            (page, startIndex) => {
                updateTableWithNewData(this, page, startIndex);
            });
    }
    getOrdersWithUpdateTableCallback(filters, sortBy, pageNumber, startIndex,
                                     onSuccess, updateTableWithData) {

        const params = createParams(filters, sortBy);
        MonitoringService.getToTake(params, pageNumber, this.state.rows,
            (page) => {
                if (updateTableWithData) {
                    updateTableWithData(page, startIndex);
                }
                if (onSuccess) {
                    onSuccess(page.content);
                }
            },
            (error) => {
                onError(this, error);
            });
    }

    render() {
        const statusesToChoose = [
            "PT_RESIGNATION",
            "PT_NO_RESPONSE",
            "PT_CLIE_POSTPON",
            "PT_WAITING_FOR_CLI",
            "PT_QUESTIONS_FOR_THE_CONTRACT",
        ];

        const filterColumns = {
            ptInClientLocation: true,
            oplStatus: true
        };

        return (
            <div>
                {  <OrderFilterPanel
                    visible="status"
                    statusFilter={(obj) => {
                        return statusesToChoose.includes(obj['name']);
                    }}
                    onCleanButton={(filters) => {
                        new OrderFilterLoadService().resetFilter((filters) => {
                            this.setState({ filters: filters }, () => {
                                this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                            });

                        },QueueType.MAIN);
                    }}
                    filters={this.state.filters} filter={this.state.filter}
                    visibleObject={filterColumns}
                    onFilterChange={defaultOnFilterChange(this)}
                    onFilter={() => {
                        this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                    }}
                    onSortFieldChange={(val, property) => {
                        this.setState({ [property]: val });
                    }}
                />}

                <Growl ref={(el) => this.growl = el} position="topright"></Growl>

                <TakeLastFromQueueBtn getOrderId=
                                          {(onSuccess, onError) => {
                                              return MonitoringService.pullFromQueueAndAssign(onSuccess, onError)
                                          }} />

                <OrderDataTable dataTableId="highprioritytable" visible={"assignAndOpenOrder,location,timeSlot,installationStartDateTime,resignationDateTime,clientPostPoneDateTime,noResponseDateTime," +
                    "techPostPoneDateTime,enrouteDateTime,handoverDateTime,delay,ptInClientLocation,oplStatus,questionForContractDateTime"}
                                totalRecords={this.state.totalRecords} toogleFilter={() => this.setState({ filter: !this.state.filter })}
                                headerTitle={"Do podjęcia"} orders={this.state.orders} rows={this.state.rows}
                                first={this.state.first} loading={this.state.loading} onPage={this.onPage}
                                itemLink={(rowData) => `./${rowData.id}?status=${rowData.status['name']}&oplStatus=true`}
                                assign={MonitoringService.assingOplUser} showOperationComment={false}
                                unassign={MonitoringService.unassingOplUser} showPriorityColumn={true}
                                assignAndOpenOrderBtnDisabled={(rowData) => {
                                    return !MonitoringOperationUtil.showMonitoringOperation(rowData);
                                }}
                                refreshTable={() => {
                                    this.refreshTable();
                                }}
                                onSort={(sortBy) => {
                                    this.setState({ sortBy: sortBy }, () => {
                                        this.refreshTable();
                                    });
                                }} />

            </div>

        );
    }


}

export default MonitoringToTakeOrders;