import RestService from "./RestService";

const PATH = "/installer";

const InstallerService = {

    getInstallersByQueryLikeFilter(query,limit, onSuccess, onError) {
        RestService.get(PATH+"/queryFilter?query="+query+"&limit="+limit  , onSuccess, onError);
    },
};

export default InstallerService;

