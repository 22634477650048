import React from 'react';



export const groupBody = (rowData, column) => {
    return (
        <ul style={{ textAlign: 'left', "paddingLeft": "15px" }}>
            {
                rowData[column.field] ? rowData[column.field].map((g) => {
                    return <li>{g.verboseName}</li>;
                }) : ""

            }</ul>

    );
}