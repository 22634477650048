import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Message } from "primereact/message";
import React from "react";
import { withRouter } from 'react-router-dom';
import GroupService from "../../services/GroupService";
import NewsService from "../../services/NewsService";
import { FilteredPickList } from "../../shared/FilteredPickList";
import RangeCalendar from "../../shared/RangeCalendar";
import '../../static/css/App.css';
import { groupTemplate } from "../account/groupTemplate";
import { sourceFilter } from "../account/SourceFIlter";


class AddEditNews extends React.Component {


    _isMounted = false;

    state = {
        successDialogVisible: false,
        id: null,
        titleError: null,
        bodyError: null,
        expirationDateTimeError: null,
        item: {},
        target: []

    }


    componentDidMount() {
        this._isMounted = true;
        GroupService.getAll(group => {
            group.sort((a, b) => {
                if (a.verboseName.toLowerCase() < b.verboseName.toLowerCase()) {
                    return -1;
                }
                if (a.verboseName.toLowerCase() > b.verboseName.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
            this.setState({ source: group }, () => {
                this.getNews(() => {
                    let target = this.state.item && this.state.item.groups ? this.state.item.groups : [];
                    this.setState({ source: sourceFilter(this.state.source, this.state.item.groups), target: target });
                })
            });
        });

    }

    getNews(onSuccess) {
        if (this.props.match.params.id) {
            NewsService.getById(this.props.match.params.id,
                (data) => {
                    if (this._isMounted) {
                        data["expirationDateTime"] = new Date(data["expirationDateTime"]);
                        this.setState({ item: data }, () => {
                            if (onSuccess) {
                                onSuccess();
                            }
                        });
                    }

                },
                (error) => {
                    console.log("Error: " + error)
                })
        }
    }


    hideSuccessDialog = () => {
        this.setState({ successDialogVisible: false });
        this.props.history.push("/news/");
    }

    showSuccessDialog = () => {
        this.setState({ successDialogVisible: true });
    }

    saveValueFromCalendar(event, property) {
        let val = event != null ? event.value : null;
        let stateVal = this.state.item;
        stateVal[property] = val;
        this.setState({ item: stateVal });
    }

    saveValueFromInput(event, property) {
        let val = event.target != null ? event.target.value : null;
        let stateVal = this.state.item;
        stateVal[property] = val;
        this.setState({ item: stateVal });
    }

    getDateTable = () => {
        let item = this.state.item;
        let from = item.startDateTime ? new Date(item.startDateTime) : item.startDateTime;
        let to = item.endDateTime ? new Date(item.endDateTime) : item.endDateTime;
        return (!from && !to) ? [] : [from, to]; // Important
    }

    render() {
        return (<div className="p-grid nogutter  p-component p-col-8">
            <div className="p-grid nogutter p-col-12">
                <label className="p-col-2 o-label">Tytuł:</label>
                <span className="o-value">
                    <InputTextarea rows={1} cols={70} value={this.state.item.title}
                        autoResize={true}
                        readOnly={false} onChange={(event) => this.saveValueFromInput(event, "title")} />
                </span>
                {this.state.titleError &&
                    <div className="validation-msg o-value"><Message severity="error" text={this.state.titleError} /></div>}
            </div>

            <div className="p-grid nogutter p-col-12">
                <label className="p-col-2 o-label">Tekst wiadomości:</label>
                <span className="o-value">
                    <InputTextarea rows={4} cols={70} value={this.state.item.body} autoResize={true} maxLength="3096"
                        readOnly={false} onChange={(event) => this.saveValueFromInput(event, "body")} />
                </span>
                {this.state.bodyError &&
                    <div className="validation-msg o-value"><Message severity="error" text={this.state.bodyError} /></div>}
            </div>

            <div className="p-grid nogutter p-col-12">
                <label className="p-col-2 o-label">Czas wyświetlania:</label>
                <span className="o-value filter-panel-input">
                    <RangeCalendar className="filter-panel-input" placeholder={"Czas wyświetlania"}
                        dateValues={this.getDateTable()}
                        onUpdate={(from, to, fromToArray) => {
                            let item = this.state.item;
                            item['startDateTime'] = from;
                            item['endDateTime'] = to;
                            this.setState({ item: item });

                        }}
                    />
                </span>
                {this.state.expirationDateTimeError &&
                    <div className="validation-msg o-value" style={{ "marginLeft": "10px" }}>
                        <Message severity="error" text={this.state.expirationDateTimeError} />
                    </div>
                }

            </div>


            <div className="p-grid nogutter p-col-12" >
                <label className="p-col-2 o-label">Grupy:</label>
                <span className="o-value">
                    <FilteredPickList source={this.state.source} target={this.state.target}
                        itemTemplate={groupTemplate}
                        sourceHeader="Dostępne" targetHeader="Wybrane" responsive={true}
                        sourceStyle={{ height: '300px' }} targetStyle={{ height: '300px' }}
                        onChange={(event) => this.setState({
                            source: event.source,
                            target: event.target
                        })} searchBy={"verboseName"}
                        showSourceControls={false} showTargetControls={false} />
                </span>
            </div>



            <Button
                style={{ "marginLeft": "10px", "marginTop": "5px" }}
                className="o-value" onClick={this.props.editMode ? this.edit() : this.add()} label={"Zapisz"}></Button>

            <Dialog header="Operacja wykonana prawidłowo" visible={this.state.successDialogVisible}
                onHide={this.hideSuccessDialog}>
                <div className=" p-col-12 p-col p-grid p-nogutter p-justify-center p-align-center">
                    <Button label="OK" onClick={this.hideSuccessDialog} />
                </div>
            </Dialog>

        </div>);
    }

    validate() {
        let isValid = true;

        if (!this.state.item.title || this.state.item.title.length < 1) {
            this.setState({ titleError: "Tytuł nie może być pusty" });
            isValid = false;
        }


        if (!this.state.item.body || this.state.item.body.length < 1) {
            this.setState({ bodyError: "Wiadomość nie może być pusta." });
            isValid = false;
        }


        let startDateTimeIsNotSet = !this.state.item.startDateTime || this.state.item.startDateTime.length < 1;
        let endDateTimeIsNotSet = !this.state.item.endDateTime || this.state.item.endDateTime.length < 1;
        if (startDateTimeIsNotSet || endDateTimeIsNotSet) {
            this.setState({ expirationDateTimeError: "Czas wyświetlania nie może być pusty." });
            isValid = false;
        }


        return isValid;
    }

    add() {
        return (event) => {
            let isValid = this.validate();
            let item = this.state.item;
            item["groups"] = this.state.target;
            if (isValid) {
                NewsService.add(item,
                    (data) => {
                        if (this._isMounted) {
                            this.setState({ id: data.id })
                            this.showSuccessDialog();
                        }
                    },
                    (error) => {
                        console.log("Error: " + error)
                    })
            }

        };
    }

    edit() {
        return (event) => {

            let isValid = this.validate();

            if (isValid) {
                let item = this.state.item;
                item["groups"] = this.state.target;
                let id = this.state.item["id"];
                NewsService.edit(item,
                    (data) => {
                        if (this._isMounted) {
                            this.setState({ id: id })
                            this.showSuccessDialog();

                        }
                    },
                    (error) => {
                        console.log("Error: " + error)
                    })
            }

        };
    }
}

export default withRouter(AddEditNews);