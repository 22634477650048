import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import React, { Component } from 'react';



const pl = {
    firstDayOfWeek: 0,
    dayNames: ["niedziela", "poniedziałek", "wtorek", "środa", "czwartek", "piątek", "sobota"],
    dayNamesShort: ["ndz", "pon", "wto", "śro", "czw", "pt", "sob"],
    dayNamesMin: ["N", "P", "W", "Ś", "C", "P", "S", ],
    monthNames: ["styczeń", "luty", "marzec", "kwiecień", "maj", "czerwiec", "lipiec", "sierpień", "wrzesień", "pażdziernik", "listopad", "grudzień"],
    monthNamesShort: ["sty", "lut", "mar", "kwi", "maj", "cze", "lip", "sie", "wrz", "paź", "lis", "gru"],
    today: 'Teraz',
    clear: 'Wyczyść',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Sm'
};

function startOfDay(dateTime) {
    const from = dateTime ? dateTime : new Date();
    from.setHours(0);
    from.setMinutes(0);
    return from;
}

function last7Days() {
    const from = new Date();
    from.setHours(0);
    from.setMinutes(0);
    from.setDate(from.getDate() - 7);
    return from;
}

function startOfMonth() {
    let from = new Date();
    from = new Date(from.getFullYear(), from.getMonth(), 1);
    from.setHours(0);
    from.setMinutes(0);
    return from;
}

const HourPicker = ({ onPlusHour, onMinusHour, getHour }) =>
    <div className="p-hour-picker">
        <button className="p-link" onClick={onPlusHour}>
            <span className="pi pi-chevron-up"></span>
        </button>

        {getHour()}

        <button className="p-link" onClick={onMinusHour}>
            <span className="pi pi-chevron-down"></span>
        </button>
    </div>;

const MinutePicker = ({ onPlusMinute, onMinusMinute, getMinute }) =>
    <div className="p-minute-picker">
        <button className="p-link" onClick={onPlusMinute}>
            <span className="pi pi-chevron-up"></span>
        </button>
        {getMinute()}

        <button className="p-link" onClick={onMinusMinute}>
            <span className="pi pi-chevron-down"></span>
        </button>
    </div>;


const Separator = () =>
    <div className="p-separator"><span className="p-separator-spacer"><span
        className="pi pi-chevron-up"></span></span><span>:</span><span
        className="p-separator-spacer"><span className="pi pi-chevron-down"></span></span>
    </div>;

class RangeCalendar extends Component {

    state = {
        dateValues: [],
        viewDate: []
    }

    componentDidMount() {


    }

    render() {

        const CustomTimePicker =
            <div className="p-timepicker">
                <HourPicker onPlusHour={() => this.plusHourToFirstDate()}
                    onMinusHour={() => this.minusHourToFirstDate()}
                    getHour={() => this.getFirstDateHour()} />
                <Separator />
                <MinutePicker onPlusMinute={() => this.plusMinuteToFirstDate()}
                    onMinusMinute={() => this.minusMinuteToFirstDate()}
                    getMinute={() => this.getFirstDateMinute()} />

                <div></div>

                <HourPicker onPlusHour={() => this.plusHourToSecondDate()}
                    onMinusHour={() => this.minusHourToSecondDate()}
                    getHour={() => this.getSecondDateHour()} />
                <Separator />
                <MinutePicker onPlusMinute={() => this.plusMinuteToSecondDate()}
                    onMinusMinute={() => this.minusMinuteToSecondDate()}
                    getMinute={() => this.getSecondDateMinute()} />
            </div>;
        return (

            <Calendar required={this.props.required}
                className={`${this.props.className} hideTimePicker `}
                selectionMode="range"
                locale={pl} showTime={true} dateFormat="yy-mm-dd"
                hourFormat="24"
                value={this.props.dateValues}
                readOnlyInput={false} placeholder={this.props.placeholder}
                showButtonBar={true} showIcon={false}
                onChange={(event) => {
                    this.updateDates(event.value);
                }}
                footerTemplate={() =>
                    <div>

                        {CustomTimePicker}

                        <Button label="Początek dnia"
                            className="p-button-secondary"
                            style={{ marginRight: "3px", marginBottom: "3px" }}
                            onClick={() => {
                                if (this.props.dateValues) {
                                    this.updateDates([startOfDay(this.props.dateValues[0]), this.props.dateValues[1]]);
                                } else {
                                    this.updateDates([startOfDay(null), null]);
                                }

                            }} />
                        <Button label="Początek miesiąca"
                            className="p-button-secondary"
                            onClick={() => {
                                var from = startOfMonth();
                                this.updateDates([from, new Date()]);
                            }} />
                        <Button label="7 dni"
                            className="p-button-secondary"
                            onClick={() => {
                                var from = last7Days();
                                this.updateDates([from, new Date()]);
                            }} />

                    </div>}>

            </Calendar>

        );
    }

    getFirstDateMinute() {
        const date = this.getDateToShowByIndex(0);
        return <span>{date ? date.getMinutes() : "00"}</span>;
    }

    getFirstDateHour() {
        const date = this.getDateToShowByIndex(0);
        return <span>{date ? date.getHours() : "00"}</span>;
    }

    getSecondDateMinute() {
        const date = this.getDateToShowByIndex(1);
        return <span>{date ? date.getMinutes() : "00"}</span>;
    }

    getSecondDateHour() {
        const date = this.getDateToShowByIndex(1);
        return <span>{date ? date.getHours() : "00"}</span>;
    }

    getDateToShowByIndex(index) {
        const fromNotNull = this.props.dateValues && this.props.dateValues[index];
        const ret = fromNotNull ? fromNotNull : null;
        if (ret instanceof Date){
            return ret;
        }
        return null;
    }


    // Date Operations
    //////////////////////////////////
    // First date
    plusMinuteToFirstDate() {
        const date = this.getDateByIndex(0);
        if (date) {
            date.setMinutes(date.getMinutes() + 1);
            this.updateDates([date, this.props.dateValues[1]]);
        }
    }

    plusHourToFirstDate() {
        const date = this.getDateByIndex(0);
        if (date) {
            date.setHours(date.getHours() + 1);
            this.updateDates([date, this.props.dateValues[1]]);
        }
    }

    minusMinuteToFirstDate() {
        const date = this.getDateByIndex(0);
        if (date) {
            date.setMinutes(date.getMinutes() - 1);
            this.updateDates([date, this.props.dateValues[1]]);
        }
    }

    minusHourToFirstDate() {
        const date = this.getDateByIndex(0);
        if (date) {
            date.setHours(date.getHours() - 1);
            this.updateDates([date, this.props.dateValues[1]]);
        }
    }

    // Second date
    plusMinuteToSecondDate() {
        const date = this.getDateByIndex(1);
        if (date) {
            date.setMinutes(date.getMinutes() + 1);
            this.updateDates([this.props.dateValues[0], date]);
        }
    }

    plusHourToSecondDate() {
        const date = this.getDateByIndex(1);
        if (date) {
            date.setHours(date.getHours() + 1);
            this.updateDates([this.props.dateValues[0], date]);
        }
    }

    minusMinuteToSecondDate() {
        const date = this.getDateByIndex(1);
        if (date) {
            date.setMinutes(date.getMinutes() - 1);
            this.updateDates([this.props.dateValues[0], date]);
        }
    }

    minusHourToSecondDate() {
        const date = this.getDateByIndex(1);
        if (date) {
            date.setHours(date.getHours() - 1);
            this.updateDates([this.props.dateValues[0], date]);
        }
    }


    getDateByIndex(index) {
        if (this.props.dateValues) {
            if (this.props.dateValues[index]) {
                return this.props.dateValues[index];
            }
        }
        return null;
    }

    updateDates(val) {
        this.setState({ dateValues: val }, () => {
            if (this.props.onUpdate) {
                let from = null;
                let to = null;
                const dates = val;
                if (dates) {
                    if (dates.length > 1) {
                        if ( dates[0] instanceof Date){ // write 'd' instead of date and boom
                            from = dates[0];
                        }
                        if (dates[1] instanceof Date){
                            to = dates[1];
                        }
                    } else if (dates.length > 0) {
                        if ( dates[0] instanceof Date){
                            from = dates[0];
                        }
                    }
                }
                let timeSlotTable = [from, to];
                if (!from && !to) {// Important
                    timeSlotTable = [];
                }
                this.props.onUpdate(from, to, timeSlotTable);
            }

        });

    }
}

export default RangeCalendar;
