import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import React from 'react';
import { withRouter } from "react-router";
import { defaultDetail, defaultSummary } from "../../config/constants";
import '../../static/css/App.css';


class Dictionary extends React.Component {

    state = {
        values: [],
        rows: 20, // Ilość wierszy na stronie
        rowIdsToSendUpdateToServer: new Set(),
        style: {
            region: {
                display: "none"
            },
            test: {
                display: "none"
            }
        }

    }

    constructor(props) {
        super(props);

        this.verboseNameEditor = this.verboseNameEditor.bind(this);
        this.inputTextEditor = this.inputTextEditor.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.regionEditor = this.regionEditor.bind(this);
        this.regionBody = this.regionBody.bind(this);
        this.testBody = this.testBody.bind(this);
        this.testEditor = this.testEditor.bind(this);
        if (this.props.areaMode) {
            delete this.state.style["region"]["display"];
            delete this.state.style["test"]["display"];
        }
    }

    componentDidMount() {
        this.getValues();
    }


    getValues() {
        this.props.fetchValues((data) => {
                this.setState({
                    values: data,
                });
            },
            (error) => {
                this.setState({
                    loading: false
                });
                console.log(`Error: ${error}`);
            });
    }

    verboseNameEditor(props) {
        return this.inputTextEditor(props, 'verboseName');
    }

    inputTextEditor(props, field) {
        return <InputText type="text" value={props.rowData[field]}
                          onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
    }

    onEditorValueChange(props, value) {

        const updatedValues = [...props.value];
        updatedValues[props.rowIndex][props.field] = value;
        const rowIdsToSendUpdateToServer = new Set(this.state.rowIdsToSendUpdateToServer);
        const id = updatedValues[props.rowIndex]["id"];
        rowIdsToSendUpdateToServer.add(id);
        this.setState({ values: updatedValues, rowIdsToSendUpdateToServer: rowIdsToSendUpdateToServer });
    }

    updateValue = (data) => {
        this.props.update(data, () => {
            const rowIdsToSendUpdateToServer = new Set(this.state.rowIdsToSendUpdateToServer);
            rowIdsToSendUpdateToServer.delete(data["id"]);
            this.setState({ rowIdsToSendUpdateToServer: rowIdsToSendUpdateToServer });
        });
    }
    removeItem = (data) => {
        this.props.remove(data, () => {
            this.getValues();
        }, () => {
            this.showError(defaultSummary, defaultDetail);
        });
    }

    showError = (summary, detail) => {
        this.messages.show({ severity: 'error', summary: summary, detail: detail });
    }

    actionTemplate(rowData) {
        const { update, remove } = this.props;

        return <div>
            {update &&
                <Button type="button" disabled={!this.state.rowIdsToSendUpdateToServer.has(rowData["id"])} icon="pi pi-save"
                        className="p-button-success" onClick={() => {
                    this.updateValue(rowData);
                }}> </Button>
            }
            {remove &&
                <Button type="button" style={{ "marginLeft": "10px" }} icon="pi pi-minus"
                        className="p-button-success" onClick={() => {
                    this.removeItem(rowData);
                }}> </Button>
            }
        </div>;
    }

    regionEditor(props) {
        return <div>

            <Dropdown showClear={false} optionLabel="verboseName" value={props.rowData["type"]}
                      options={this.state.regions}
                      onChange={(event) => {
                          this.onEditorValueChange(props, event.value);
                      }}
            />
        </div>;
    }

    regionBody(rowData) {
        return <span>{rowData["type"] ? rowData["type"]["verboseName"] : null}</span>;
    }
    testBody(rowData) {
        return <span>{rowData["test"] ? "Tak" : "Nie"}</span>;
    }

    testEditor(props) {
        return (
            <input style={{ "marginLeft": "0px" }} type="checkbox" checked={props.rowData["test"]}
                   onChange={(e) => {

                       this.onEditorValueChange(props, e.target.checked);
                   }}/>);
    }

    render() {
        const { title, addUrl, areaMode, update, remove } = this.props;

        const header = <div className="p-clearfix" style={{'lineHeight': '1.87em'}}> {title}
            {addUrl &&
                <Button icon="pi pi-plus" style={{'float': 'right', 'marginLeft': '10px'}}
                        onClick={() => this.props.history.push(addUrl)}/>
            }
            <Button
                icon="pi pi-refresh" style={{'float': 'right'}}
                onClick={() => this.getValues()}/>
        </div>;

        return (
            <div className="content-section implementation">
                <div className="p-grid nogutter p-col-12">
                    <Messages ref={(el) => this.messages = el} />
                </div>
                <DataTable value={this.state.values} editable={!!update} header={header}>
                    <Column field="id" header="Identyfikator" filter={true}
                            style={{ textAlign: 'center' }} sortable={true} />
                    {areaMode ?
                        <Column field="areaCode" header="Kod" style={{ textAlign: 'center' }} filter={true} sortable={true} />
                        : <Column field="name" header="Nazwa" style={{ textAlign: 'center' }} filter={true} sortable={true} />
                    }
                    <Column field="verboseName" header="Wartość wyświetlana" editor={update && this.verboseNameEditor}
                            sortable={true} style={{ textAlign: 'center' }} filter={true} />
                    {!areaMode &&
                        <Column field="type" body={this.regionBody} header="Region" editor={this.regionEditor}
                                style={this.state.style.region} filter={true} sortable={true}/>
                    }
                    <Column field="test" body={this.testBody} header="Testowa" sortable={true} editor={this.testEditor}
                            style={this.state.style.test} />
                    {(update || remove) &&
                        <Column body={this.actionTemplate} style={{textAlign: 'center', width: '8em'}} />
                    }
                </DataTable>
                <br></br>
                <div style={{ "display": "flex" }} >
                    <span className="p-component" >
                        Uwaga!!! Słowniki i konfiguracja procesów biznesowych jest przechowywana w pamięci podręcznej.
                        W celu aktualizacji tych danych na aplikacji mobilnej należy przejść do zakładki Ustawienia-&gt;Pamięć podręczna.
                    </span>
                </div>
            </div>
        );
    }

}

export default withRouter(Dictionary);