import RestService from "./RestService";

const PATH = "/account";

const AccountService = {

    add(data, onSuccess, onError) {
        RestService.create(PATH, data, onSuccess, onError);
    },

    edit(id, data, onSuccess, onError) {
        RestService.update(`${PATH}/${id}`, data, onSuccess, onError);
    },

    blockUnblock(id, enabled, onSuccess, onError) {
        const data = {
            enabled: enabled
        };
        RestService.update(`${PATH}/${id}/enabled`, data, onSuccess, onError);
    },

    blockAccounts(accountIds, onSuccess, onError) {
        RestService.update(PATH + "/disabled", accountIds, onSuccess, onError);
    },

    unBlockAccounts(accountIds, onSuccess, onError) {
        RestService.update(PATH + "/enabled", accountIds, onSuccess, onError);
    },



    delete(id, onSuccess, onError) {

        RestService.delete(`${PATH}/${id}`, onSuccess, onError);
    },

    getById(id, onSuccess, onError) {

        RestService.get(`${PATH}/${id}`, onSuccess, onError);
    },

    getAll(params, pageNumber, pageSize, onSuccess, onError) {

        RestService.getWithPagination(PATH, params, pageNumber, pageSize, onSuccess, onError);

    },
    getOplAccountsByQueryLikeFilter(query,size,onSuccess, onError) {

        RestService.get(`${PATH}/opl/queryFilter?query=${query}&size=${size}`, onSuccess, onError);
    },
};

export default AccountService;

