import React from 'react';
import '../../static/css/App.css';
import Heartbeat from "./Heartbeat";


import 'primeflex/primeflex.css';
import { Card } from 'primereact/card';
import NewsService from '../../services/NewsService';
import ErrorService from '../../services/ErrorService';



class Home extends React.Component {


    state = {
        key: "",
        value: '',
        news: []
    }

    componentDidMount() {
        NewsService.getNewsNotifications(
            (data) => {
                NewsService.updateLastMsgReadDateTime(data.queryDateTime,()=>{},()=>{})
                this.setState({ news: data.content });
            },
            (error) => ErrorService.logServerError(error));

             
    }

    render() {

        return (
            <div className="">
                <h1 className="marginBottom marginTop0" >Aktualności</h1>
                <div className="row" id="rowek">

                    {Array.isArray(this.state.news) && this.state.news.length >0  ? this.state.news.map((news) => {

                        return <Card title={news.title} className="marginBottom">
                                   {news.body}
                                </Card>;
                    }):"Brak wiadomości"}

                    {/* <div className="col s4 App" ><JokePanel /> </div> */}
                </div>
                <Heartbeat />



            </div>
        );
    }
}

export default Home;
