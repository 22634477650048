import _ from "lodash";

const InterventionStatusMapper = {

    mapToVerboseName(success) {
        if(!_.isNil(success)){
            return success?"Skuteczna":"Nieskuteczna";
        }else{
            return "Brak informacji";
        }
    },

};

export default InterventionStatusMapper;
