import { Growl } from "primereact/growl";
import React from 'react';
import {
    DEFAULT_PAGE_SIZE,
    MODIFICATION_DATE_TIME_ASC,
    QueueType,
    REFRESH_TABLE_INTERVAL
} from "../../config/constants";
import AuthService from "../../services/AuthService";
import MonitoringService from "../../services/MonitoringService";
import QueueOrderNotificationService from "../../services/QueueOrderNotificationService";
import OrderDataTable from "../../shared/orderDataTable/OrderDataTable";
import OrderFilterLoadService from "../../shared/OrderFilterLoadService";
import OrderFilterPanel, { defaultOnFilterChange, defaultOnFilterChangeWithCallback } from "../../shared/OrderFilterPanel";
import { createParams } from "../../shared/OrderTableCommon";
import { filtersNotEmptySkipModificationDateTimeFrom, showQueueNewOrdersNotifications } from "../../shared/Utils";
import '../../static/css/App.css';



class MonitoringLateOrders extends React.Component {

    state = {
        orders: [],
        loading: false,
        first: 1,
        rows: DEFAULT_PAGE_SIZE, // Ilość wierszy na stronie
        totalRecords: 0,// Ilośc wszystkich elementów w bazie
        filters: {},
        area: {},
        sortBy: MODIFICATION_DATE_TIME_ASC,
        filter: false,
        previousModificationTime: new Date(),
        pageNumber: 1,
        startIndex: 0

    }

    constructor(props) {
        super(props);
        this.onPage = this.onPage.bind(this);
    }

    refreshTable() {
        this.getOrders(this.state.filters, this.state.sortBy, this.state.pageNumber, this.state.startIndex, (data) => {
            showQueueNewOrdersNotifications(this,QueueOrderNotificationService.getNewOrdersFromMonitoringLateQueue);
        });

    }

    componentDidMount() {
        this.setState({ filters: new OrderFilterLoadService().getFilter(QueueType.MAIN) },()=>{
            this.resetIntervalAndRefreshTable();

            this.setState({
                filter:filtersNotEmptySkipModificationDateTimeFrom(this.state.filters)
            });

        });

    }


    componentWillUnmount() {
        clearInterval(this.interval);
    }


    resetInterval() {
        clearInterval(this.interval);
        this.interval = setInterval(() => this.refreshTable(), REFRESH_TABLE_INTERVAL);
    }

    resetIntervalAndRefreshTable() {
        this.resetInterval();
        this.refreshTable();
    }

    onPage(event) {
        this.setState({
            loading: true
        });
        const startIndex = event.first;
        const pageNumber = startIndex / event.rows + 1;

        this.setState({pageNumber:pageNumber, startIndex:startIndex, rows: event.rows},()=>{
            this.getOrders(this.state.filters, this.state.sortBy, this.state.pageNumber, this.state.startIndex);
        });
    }


    getOrders(filters, sortBy, pageNumber, startIndex, onSuccess) {

        const params = createParams(filters, sortBy);
        MonitoringService.getLate(params, pageNumber, this.state.rows, (page) => {

            this.setState({
                orders: page.content,
                totalRecords: page.totalElements,
                loading: false,
                first: startIndex,
            });
            if (onSuccess) {
                onSuccess(page.content);
            }
        },
        (error) => {
            this.setState({
                loading: false
            });
            console.error(`Error:${error}`);
            AuthService.clearLocalStorageAndForwardToLoginWhen401(this, error);
        });
    }


    render() {
        const statusesToChoose = [
            "PT_LATE",
        ];

        const filterColumns = {
            ptInClientLocation: true,
            oplStatus: true
        };

        return (
            <div>
                {  <OrderFilterPanel
                    // visible="sortField,serviceId"
                    statusFilter={(obj) => {
                        return statusesToChoose.includes(obj['name']);
                    }}
                    visibleObject={filterColumns}
                    onFilterChange={defaultOnFilterChange(this)}
                    filters={this.state.filters} filter={this.state.filter}
                    onCleanButton={(filters) => {
                        new OrderFilterLoadService().resetFilter((filters) => {
                            this.setState({filters:filters},()=>{
                                this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                            });
                            
                        },QueueType.MAIN);
                    }}
                    onFilter={() => {
                        this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                    }}
                    onSortFieldChange={(val, property) => {
                        this.setState({ [property]: val });
                    }}
                />}

                <Growl ref={(el) => this.growl = el} position="topright"></Growl>

                <OrderDataTable
                    visible={"oplStatus,assignAndOpenOrder,location,timeSlot,installationStartDateTime,resignationDateTime,clientPostPoneDateTime,noResponseDateTime,techPostPoneDateTime,enrouteDateTime,handoverDateTime,delay"}
                    totalRecords={this.state.totalRecords}
                    toogleFilter={() => this.setState({ filter: !this.state.filter })}
                    headerTitle={"Do podjęcia - opóźnione"} orders={this.state.orders} rows={this.state.rows}
                    first={this.state.first} loading={this.state.loading} onPage={this.onPage} skipLastURLSegmentOfItem={true}
                    refreshTable={() => {
                        this.resetIntervalAndRefreshTable();
                    }}
                    assign = {MonitoringService.assingOplUser}
                    unassign = {MonitoringService.unassingOplUser}
                    itemLink={(rowData) => `./${rowData.id}?oplStatus=true`}
                    onSort={(sortBy) => {
                        this.setState({ sortBy: sortBy }, () => {
                            this.resetIntervalAndRefreshTable();
                        });
                    }} />

            </div>

        );
    }


}

export default MonitoringLateOrders;

