import { Growl } from "primereact/growl";
import React from 'react';
import { DEFAULT_PAGE_SIZE, DEFAULT_SORT_BY_FOR_OPL_ORDER_QUEUE, HIDDEN_FIELDS_MAIN_QUEUE, QueueType, REFRESH_TABLE_INTERVAL } from "../../config/constants";
import QueueOrderNotificationService from "../../services/QueueOrderNotificationService";
import WckuService from "../../services/WckuService";
import OrderDataTable from "../../shared/orderDataTable/OrderDataTable";
import OrderFilterLoadService from "../../shared/OrderFilterLoadService";
import OrderFilterPanel, { defaultOnFilterChange } from "../../shared/OrderFilterPanel";
import { createParams } from "../../shared/OrderTableCommon";
import { onError, updateTableWithNewData, filtersNotEmptySkipModificationDateTimeFrom, showQueueNewOrdersNotifications } from "../../shared/Utils";
import TakeLastFromQueueBtn from "../../shared/TakeLastFromQueueBtn";
import '../../static/css/App.css';

class WckuOrders extends React.Component {

    state = {
        orders: [],
        loading: false,
        first: 1,
        rows: DEFAULT_PAGE_SIZE, // Ilość wierszy na stronie
        totalRecords: 0,// Ilośc wszystkich elementów w bazie
        filters: {},
        area: {},
        sortBy: DEFAULT_SORT_BY_FOR_OPL_ORDER_QUEUE,
        filter: false,
        previousModificationTime: new Date(),
        pageNumber: 1,
        startIndex: 0

    }

    constructor(props) {
        super(props);
        this.onPage = this.onPage.bind(this);
    }

    refreshTable() {
        this.getOrders(this.state.filters, this.state.sortBy, this.state.pageNumber, this.state.startIndex, (data) => {
            showQueueNewOrdersNotifications(this,QueueOrderNotificationService.getNewOrdersFromWckuMainQueue);
        });

    }

    componentDidMount() {
        this.setState({ filters: new OrderFilterLoadService().getFilter(QueueType.MAIN) },()=>{
            this.resetIntervalAndRefreshTable();

            this.setState({
                filter:filtersNotEmptySkipModificationDateTimeFrom(this.state.filters)
            });

        });

    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    resetInterval() {
        clearInterval(this.interval);
        this.interval = setInterval(() => this.refreshTable(), REFRESH_TABLE_INTERVAL);
    }

    resetIntervalAndRefreshTable() {
        this.resetInterval();
        this.refreshTable();
    }


    onPage(event) {
        this.setState({
            loading: true
        });
        const startIndex = event.first;
        const pageNumber = startIndex / event.rows + 1;

        this.setState({ pageNumber: pageNumber, startIndex: startIndex, rows: event.rows }, () => {
            this.getOrders(this.state.filters, this.state.sortBy, this.state.pageNumber, this.state.startIndex);
        });

    }

    getOrders(filters, sortBy, pageNumber, startIndex, onSuccess) {

        this.getOrdersWithUpdateTableCallback(filters, sortBy, pageNumber, startIndex, onSuccess,
            (page, startIndex) => {
                updateTableWithNewData(this, page, startIndex);
            });
    }
    getOrdersWithUpdateTableCallback(filters, sortBy, pageNumber, startIndex,
        onSuccess, updateTableWithData) {

        const params = createParams(filters, sortBy);
        WckuService.getMainQueueOrders(params, pageNumber, this.state.rows,
            (page) => {
                if (updateTableWithData) {
                    updateTableWithData(page, startIndex);
                }
                if (onSuccess) {
                    onSuccess(page.content);
                }
            },
            (error) => {
                onError(this, error);
            });
    }

    render() {

        return (
            <div>
                {  <OrderFilterPanel
                    // visible="sortField,serviceId"
                    onFilterChange={defaultOnFilterChange(this)}
                    filters={this.state.filters} filter={this.state.filter}
                    onCleanButton={(filters) => {
                        new OrderFilterLoadService().resetFilter((filters) => {
                            this.setState({filters:filters},()=>{
                                this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                            });
                            
                        },QueueType.MAIN);
                    }}
                    onFilter={() => {
                        this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                    }}
                    onSortFieldChange={(val, property) => {
                        this.setState({ [property]: val });
                    }}
                />}
                <Growl ref={(el) => this.growl = el} position="topright"></Growl>
                <TakeLastFromQueueBtn getOrderId=
                    {(onSuccess, onError) => { 
                        return WckuService.pullFromQueueAndAssign(onSuccess, onError) }} />
                        
                <OrderDataTable showAll={false} visible={"location,assignAndOpenOrder"} hidden={HIDDEN_FIELDS_MAIN_QUEUE}
                    totalRecords={this.state.totalRecords} toogleFilter={() => this.setState({ filter: !this.state.filter })}
                    headerTitle={"Lista fotomontaży"} orders={this.state.orders} rows={this.state.rows}
                    first={this.state.first} loading={this.state.loading} onPage={this.onPage}
                    refreshTable={() => {
                        this.resetIntervalAndRefreshTable();
                    }}
                    onSort={(sortBy) => {
                        this.setState({ sortBy: sortBy }, () => {
                            this.resetIntervalAndRefreshTable();
                        });
                    }} />

            </div>

        );
    }


}

export default WckuOrders;

