import { Growl } from 'primereact/growl';
import React from 'react';
import WckuService from "../../services/WckuService";
import {lastCreationDate, setHistoryMetadata, showMsgAboutOrderAssignmentChange} from '../../shared/Utils';
import '../../static/css/App.css';
import Order from "../other/Order";
import WCKUOperation from "./WCKUOperation";
import AvailableAssignmentService from "../../shared/AvailableAssignmentService";

class WckuOrder extends React.Component {

    _isMounted = false;

    state = {
        order: {
            area: [],
            installers: [],
            technology: {},
            technologyDla: {},
            service: {},
            status: {},
            type: {},
            rejectionReason: {},
            orderDevices: [],
        },
        events: []

    }

    componentDidMount() {
        this._isMounted = true;
        this.getOrder();
    }

    getOrder() {

        let modificationDateTime;
        if (this.state.order) {
            modificationDateTime = this.state.order.modificationDateTime;
        }
        WckuService.getByIdWithTimestamp(this.props.match.params.id, modificationDateTime,
            (data) => {
                showMsgAboutOrderAssignmentChange(data, this.growl, this.state.order);
                if (this._isMounted && data) {
                    setHistoryMetadata(data);
                    this.setState({ order: data });
                }

            },
            (error) => {
                console.log(`Error: ${error}`);
            });


    }

    getEvents(id) {
        WckuService.getEventsByIdWithTimestamp(id, lastCreationDate(this.state.events),
            (data) => {
                if (data) this.setState({events: data})
            },
            (error) => {
                console.log("Error: " + error)
            })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {

        //https://reacttraining.com/react-router/web/example/url-params
        const { match } = this.props;
        const availableAssignmentService = new AvailableAssignmentService();

        return (

            <div style={{ "paddingTop": "7px" }}>
                <Growl ref={(el) => this.growl = el} position="topright"/>
                {
                    !availableAssignmentService.isNoNeedToAssign(this.props.location.search, this.state.order, false)
                    &&
                    <WCKUOperation order={this.state.order} refreshOrder={() => this.getOrder()}/>
                }
                <Order refreshOrderIntervalMode={true} showOPLNotes={true} dataSource={this.state.order} match={match}
                       refreshOrder={() => this.getOrder()} refreshEvents={() => this.getEvents(this.state.order.id)}
                       events={this.state.events} />
            </div>
        );

    }
}

export default WckuOrder;
