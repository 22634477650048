import React from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import {
    DeviceIdTemplate,
} from '../../shared/OrderTableCommon';
import { copyToClipboard } from '../../shared/CardCommon';
import _ from "lodash";


function guidGenerator() {
    let S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}

function getValueFromNestedJsonObject(parentObject, jsonPath, deviceId) {
    if (Array.isArray(parentObject.orderDevices)) {
        for (let i = 0; i < parentObject.orderDevices.length; i++) {
            let device = _.get(parentObject.orderDevices[i], jsonPath);
            if (device && device.id === deviceId) {
                return device.equal;
            }
        }
    }
    return "";
}

class DeviceTable extends React.Component {

    state = {
      
    }

    componentDidMount() {
       
    }

    render() {
        const { devices, rows, metadata, showHistoryActivationHistory } = this.props

        const isAnyOrderDeviceHasProperty = (devices, property) => {
            if (devices) {
                let i;
                for (i = 0; i < devices.length; i++) {
                    if (devices[i].hasOwnProperty(property)) {
                        return true;
                    }
                }
            }
            return false;
        }
        const isAnyDeviceHasProperty = (devices, property) => {
            if (devices) {
                let i;
                for (i = 0; i < devices.length; i++) {
                    let device = devices[i]["device"];
                    if (device && device.hasOwnProperty(property)) {
                        return true;
                    }
                }
            }
            return false;
        }

        const isAnyOrderDeviceHasNotEmptyObject = (devices, property) => {
            if (devices) {
                let i;
                for (i = 0; i < devices.length; i++) {
                    if (devices[i].hasOwnProperty(property)) {
                        let obj =devices[i][property];
                        return !(Object.keys(obj).length === 0 && obj.constructor === Object);
                    }
                }
            }
           
            return false;
        }

        const DeviceModelTypeTemplate = (rowData, column) => {
            return <div>
                {rowData.device.type.verboseName}
            </div>;
        }

        const DeviceStatusTemplate = (rowData, column) => {
            return <div>
                {rowData.status.verboseName}
            </div>;
        }

        const DeviceModelTemplate = (rowData, column) => {
            return <div>
                {rowData.device.model.verboseName}
            </div>;
        }

        const DeviceMsisdnTemplate = (rowData, column) => {
            return <div>
                <span className="bold">{rowData.device.msisdn}</span>
            </div>;
        }

        const DevicePortNumberTemplate = (rowData, column) => {
            return <div>
                {rowData.device.portNumber}
            </div>;
        }

        const DeviceAddressMacTemplate = (rowData, column) => {
            return <div>
                <span className="bold">{rowData.device.addressMac}</span>
            </div>;
        }

        const DeviceGponNumberTemplate = (rowData, column) => {
            return <div>
                <span className="bold">{rowData.device.gponNumber}</span>
            </div>;
        }

        const rowClassName = (rowData) => {
            return metadata ? getValueFromNestedJsonObject(metadata, "device", rowData.device.id) ?
                {'equal' : showHistoryActivationHistory} : {'notEqual' : showHistoryActivationHistory} : "";
        }

        const isAnyDeviceHasOutDoorInstalledProperty = isAnyOrderDeviceHasProperty(devices, 'outDoorInstalled');
        const isAnyDeviceHasLandlineConnectedProperty = isAnyOrderDeviceHasProperty(devices, 'landlineConnected');
        const isAnyDeviceHasMsisdnProperty = isAnyDeviceHasProperty(devices, 'msisdn');
        const isAnyDeviceHasPortNumberProperty = isAnyDeviceHasProperty(devices, 'portNumber');
        const isAnyDeviceHasAddressProperty = isAnyDeviceHasProperty(devices, 'addressMac');
        const isAnyDeviceHasGponNumberProperty = isAnyDeviceHasProperty(devices, 'gponNumber');
        const isAnyDeviceHasOntppStatusProperty = isAnyOrderDeviceHasNotEmptyObject(devices, 'ontppMappingStatus');

        return (
            <DataTable ref={(el) => this.dt = el}
                            value={devices} rows={rows}
                            first={this.state.first} autoLayout={true}
                            rowClassName={rowClassName}
                        >
                            <Column field="device" header="Id" body={DeviceIdTemplate} style={{ textAlign: "center" }} />
                            <Column field="device.status.verboseName" body={DeviceStatusTemplate} header="Status" style={{ textAlign: "center" }}/>
                            <Column field="device.model.type.verboseName" body={DeviceModelTypeTemplate} header="Typ urządzenia"
                                    style={{ textAlign: "center" }}
                                    filterMatchMode="custom"
                            />
                            <Column field="device.model.verboseName" body={DeviceModelTemplate} header="Model urządzenia"

                                style={{ textAlign: "center" }}
                                filterMatchMode="custom" />
                            <Column field="device.sn" header="Numer seryjny" style={{ textAlign: "center" }}
                                body={(rowData, column) => {
                                    let randomId = "device_sn_id" + guidGenerator();
                                    return (
                                        <div
                                            style={{
                                            "display": "flex", "alignItems": "center",
                                            "justifyContent": "center"
                                        }}>

                                            <span className="bold" id={randomId}>{rowData['device']['sn']}</span>
                                            <button style={{"marginLeft": "0.15em"}} className="p-button pi pi-copy"
                                                    onClick={() => copyToClipboard(randomId)}/>
                                        </div>
                                    );
                                }} />
                            <Column field="device.msisdn" header="Msisdn" body={DeviceMsisdnTemplate}
                                style={{ display: isAnyDeviceHasMsisdnProperty ? null : "none" }} />
                            <Column field="device.portNumber" header="Numer portu" body={DevicePortNumberTemplate}
                                style={{ display: isAnyDeviceHasPortNumberProperty ? null : "none" }} />
                            <Column field="device.addressMac" header="Adres MAC" body={DeviceAddressMacTemplate}
                                style={{ display: isAnyDeviceHasAddressProperty ? null : "none" }} />
                            <Column field="device.gponNumber" header="Numer GPON" body={DeviceGponNumberTemplate}
                                style={{ display: isAnyDeviceHasGponNumberProperty ? null : "none" }} />

                            <Column field="landlineConnected" header="Czy TV naziemna podłączona do dekodera"
                                style={{ display: isAnyDeviceHasLandlineConnectedProperty ? null : "none" }}
                                body={(rowData, column) => {
                                    return (
                                        <Checkbox readOnly checked={rowData[column.field]}/>
                                    );
                                }} />
                            <Column field="outDoorInstalled" header="Czy urządzenie zostało zainstalowane poza lokalem klienta?"
                                style={{ display: isAnyDeviceHasOutDoorInstalledProperty ? null : "none" }}
                                body={(rowData, column) => {
                                    return (
                                        <Checkbox readOnly checked={rowData[column.field]}/>
                                    );
                                }} />

                            <Column field="ontppMappingStatus.verboseName" header="Status ONTP&P"
                                style={{ display: isAnyDeviceHasOntppStatusProperty ? null : "none" }} />

                        </DataTable>
        );
    }
}

export default DeviceTable;