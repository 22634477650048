import { Growl } from 'primereact/growl';
import queryString from "query-string";
import React from 'react';
import OkiActivationService from "../../services/OkiActivationService";
import {lastCreationDate, setHistoryMetadata, showMsgAboutOrderAssignmentChange} from '../../shared/Utils';
import '../../static/css/App.css';
import Order from "../other/Order";
import OkiActivationOperation from "./OkiActivationOperation";
import AvailableAssignmentService from "../../shared/AvailableAssignmentService";
import _ from "lodash";


class OkiActivationOrder extends React.Component {


    //https://www.robinwieruch.de/react-warning-cant-call-setstate-on-an-unmounted-component/
    _isMounted = false;

    state = {
        order: {
            area: [],
            installers: [],
            technology: {},
            technologyDla: {},
            service: {},
            status: {},
            type: {},
            rejectionReason: {},
            orderDevices: [],
            shouldAssign: false
        },
        events: []

    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({shouldAssign: this.props.location && this.props.location.pathname && _.endsWith(this.props.location.pathname, "assign")});
        this.getOrder();
    }

    getOrder() {

        let modificationDateTime;
        if (this.state.order) {
            modificationDateTime = this.state.order.modificationDateTime;
        }
        OkiActivationService.getByIdWithTimestamp(this.props.match.params.id, modificationDateTime,
            (data) => {
                showMsgAboutOrderAssignmentChange(data, this.growl, this.state.order);
                if (this._isMounted && data) {
                    setHistoryMetadata(data);
                    this.setState({ order: data });
                }

            },
            (error) => {
                console.log(`Error: ${error}`);
            });

    }

    getEvents(id) {
        OkiActivationService.getEventsByIdWithTimestamp(id, lastCreationDate(this.state.events),
            (data) => {
                if (data) this.setState({events: data})
            },
            (error) => {
                console.log("Error: " + error)
            })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {

        //https://reacttraining.com/react-router/web/example/url-params
        const { match } = this.props;
        const availableAssignmentService = new AvailableAssignmentService();

        return (
            <div style={{ "paddingTop": "7px" }}>
                <Growl ref={(el) => this.growl = el} position="topright"/>
                {
                    !availableAssignmentService.isNoNeedToAssign(this.props.location.search, this.state.order, false)
                    &&
                    <OkiActivationOperation order={this.state.order} refreshOrder={() => this.getOrder()}
                                            shouldAssign={this.state.shouldAssign}/>
                }
                <Order refreshOrderIntervalMode={true} showOPLNotes={true} dataSource={this.state.order} match={match}
                       refreshOrder={() => this.getOrder()} refreshEvents={() => this.getEvents(this.state.order.id)}
                       events={this.state.events} />

            </div>
        );

    }


}

export default OkiActivationOrder;
