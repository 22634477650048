import { AutoComplete } from 'primereact/autocomplete';
import React from 'react';
import InstallerService from '../../services/InstallerService';
import {createFirstNameAndLastNameIdentifierSt} from '../Utils';

class InstallerAutoComplete extends React.Component {


    state = {
        options: [],
        selected: []

    }

    selectedItemTemplate(installer) {
        return <span>{createFirstNameAndLastNameIdentifierSt(installer)}</span>
    }

    itemTemplate(installer) {
        return <span>{createFirstNameAndLastNameIdentifierSt(installer)}</span>
    }

    suggestValue(event) {

        let query = event.query.toLowerCase().trim();
        InstallerService.getInstallersByQueryLikeFilter(query, 100, (values) => {
            this.setState({ suggestions: values });
        }, (error) => {
            console.log("InstallerAutoComplete.getByCompanyId()->error" + JSON.stringify(error))
        });


    }

    render() {

        return (

            <AutoComplete className="filter-panel-input" itemTemplate={this.itemTemplate} multiple={true} dropdown={false}
                value={this.props.value} placeholder={this.props.placeholder}
                onChange={(e) => {
                    if (this.props.onChange) this.props.onChange(e);
                }}
                suggestions={this.state.suggestions} completeMethod={this.suggestValue.bind(this)}
                selectedItemTemplate={this.selectedItemTemplate} />

        );
    }
}

export default InstallerAutoComplete;