import React from "react";
import {withRouter} from 'react-router-dom';
import MonitoringSettingsService from "../../services/MonitoringSettingsService";
import ErrorService from "../../services/ErrorService";
import {defaultDetail, defaultSummary} from "../../config/constants";
import {Messages} from "primereact/messages";
import {InputTextarea} from "primereact/inputtextarea";
import {Message} from "primereact/message";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
class AddRagResponse extends React.Component {
    _isMounted = false;

    state = {
        successDialogVisible: false,
        id: null,
        messageError: null,
        message: null

    }

    showError = (summary, detail) => {
        this.messages.show({severity: 'error', summary: summary, detail: detail});
    }
    componentDidMount() {
        this._isMounted = true;
    }
    hideSuccessDialog = () => {
        this.setState({successDialogVisible: false});
        this.props.history.push("/monitoring/settings" );
    }
    showSuccessDialog = () => {
        this.setState({successDialogVisible: true});
    }

    saveValueFromInput(event) {
        const val = event.target ? event.target.value : null;
        this.setState({message: val});
    }

    validate() {
        let isValid = true;
        if (!this.state.message || this.state.message.length < 1) {
            this.setState({nameError: "Treść wiadomości nie może być pusta."});
            isValid = false;
        }
        return isValid;
    }

    add() {
        return () => {
            this.setState({nameError:null,verboseNameError:null,regionError:null,areaCodeError:null});
            const isValid = this.validate();
            if (isValid) {
                MonitoringSettingsService.newMessage(this.state.message, (data) => {
                    if (this._isMounted) {
                        this.showSuccessDialog();
                    }
                },(error) => {
                    ErrorService.logServerError(error);
                    this.showError(defaultSummary, defaultDetail);
                });
            }
        };
    }

    render() {
        return <div className="p-grid nogutter  p-component p-col-8">
            <div className="p-grid nogutter p-col-12">
                <Messages ref={(el) => this.messages = el}/>
            </div>
            <div className="p-grid nogutter p-col-12">
                <h3>Dodawanie nowej odpowiedzi słownikowej:</h3>
            </div>
            <div className="p-grid nogutter p-col-12">
                <label className="p-col-2 o-label">Wiadomość:</label>
                <span className="o-value">
                    <InputTextarea rows={1} cols={70} value={this.state.message}
                                   autoResize={true}
                                   readOnly={false} onChange={(event) => this.saveValueFromInput(event)}/>
                </span>
                {this.state.messageError &&
                    <div className="validation-msg o-value"><Message severity="error" text={this.state.messageError}/>
                    </div>}
            </div>
            <Button style={{"marginLeft": "10px", "marginTop": "5px"}} className="o-value" onClick={this.add()} label={"Dodaj"}/>

            <Dialog header="Operacja wykonana prawidłowo" visible={this.state.successDialogVisible}
                    onHide={this.hideSuccessDialog}>
                <div className=" p-col-12 p-col p-grid p-nogutter p-justify-center p-align-center">
                    <Button label="OK" onClick={this.hideSuccessDialog}/>
                </div>
            </Dialog>
        </div>
    }
}

export default withRouter(AddRagResponse);