import React from 'react';
import {ActivationHistoryDateTimeRow, ActivationHistoryStringRow} from '../../shared/CardCommon';

class ActivationHistoryData extends React.Component {

    state = {}

    componentDidMount() {

    }

    render() {
        const {activationData} = this.props

        return (
                <div>
                    <ActivationHistoryStringRow label={"Typ zlecenia:"} value={activationData.orderType.verboseName}/>
                    <ActivationHistoryStringRow label={"Technologia:"} value={activationData.technology.verboseName}/>
                    <ActivationHistoryStringRow label={"Technologia DLA:"} value={activationData.technologyDla ? activationData.technologyDla.verboseName : ""}/>
                    <ActivationHistoryStringRow label={"Usługa:"} value={activationData.service.verboseName}/>
                    <ActivationHistoryStringRow label={"Opcja usługowa:"}
                                                value={activationData.serviceOption.verboseName}/>
                    {/*<ActivationHistoryStringRow label={"Uzupełnione przez:"}*/}
                    {/*                            value={createFirstNameAndLastNameLoginSt(activationData.completedBy)}/>*/}
                    <ActivationHistoryDateTimeRow label={"Utworzono:"} value={activationData.creationDateTime}/>
                    <ActivationHistoryDateTimeRow label={"Przekazano do OPL:"}
                                                  value={activationData.completionDateTime}/>
                    <ActivationHistoryStringRow label={"Status umowy:"}
                                                value={activationData.contractStatus.verboseName}/>
                </div>
        );

    }
}

export default ActivationHistoryData;