import RestService from "../RestService";

const PATH = "/global/appUniversalConfiguration";

const GlobalPropertiesService = {

    update(data, onSuccess, onError) {
        RestService.update(PATH, data, onSuccess, onError);
    },

    getAll(onSuccess, onError) {
        return RestService.get(PATH, onSuccess, onError);
    },

    add(data, onSuccess, onError) {
        RestService.post(PATH, data, onSuccess, onError);
    },

};

export default GlobalPropertiesService;

