// export const SERVER_API_URL = '/deadpool/prodsupport/rest/'; //stg2
export const SERVER_API_URL = '/deadpool/rest/'; //prod, stg1, local
// export const SERVER_API_URL = 'http://localhost:8080/deadpool/rest';
// export const SERVER_API_URL = 'https://fotomontaztest.orange.pl/photomontage/rest';
// Server return and accept this DateTimeTemplate format
export const DATE_TIME_SERVER_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX";
// We show dataTime ussing this format
export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";

export const IS_AUTHENTICATED_KEY = "isAuthenticated";

export const DEALER_SUPPORT_ROLE_KEY = "DEALER_SUPPORT";
export const ADMIN_ROLE_KEY = "ADMIN";
export const OKI_ROLE_KEY = "OKI";
export const WCKU_ROLE_KEY = "WCKU";
export const ORDER_PREVIEW_ROLE_KEY = "ORDER_PREVIEW";
export const BOT_OKI_READONLY_ROLE_KEY = "BOT_OKI_READONLY";
export const BOT_DS_READONLY_ROLE_KEY = "BOT_DS_READONLY";
export const FTTH_MONITORING_ROLE_KEY = "FTTH_MONITORING";
export const FTTH_MONITORING_READONLY_ROLE_KEY = "FTTH_MONITORING_READONLY";
export const ASSIGNMENT_MANAGEMENT_ROLE_KEY = "ASSIGNMENT_MANAGEMENT";

export const OPL_ALREADY_ASSIGNED_TO_ORDER_ERROR_CODE = "OPL_ALREADY_ASSIGNED_TO_ORDER";
export const ATRIUM_CONNECT_EXCEPTION = "ATRIUM_CONNECT_EXCEPTION";
export const ATRIUM_SOCKET_TIMEOUT_EXCEPTION = "ATRIUM_SOCKET_TIMEOUT_EXCEPTION";
export const BE_CONNECT_TIMEOUT = "BE_CONNECT_TIMEOUT";
export const BE_READ_TIMEOUT = "BE_READ_TIMEOUT";
export const ATRIUM_INTERNAL_ERROR = "ATRIUM_INTERNAL_ERROR";
export const DP_BE_INTERNAL_ERROR = "DP_BE_INTERNAL_ERROR";
export const ATRIUM_EVENT_INCORRECT_STATUS = "ATRIUM_EVENT_INCORRECT_STATUS";
export const ATRIUM_NOT_FOUND_EXCEPTION = "ATRIUM_NOT_FOUND_EXCEPTION";
export const ATRIUM_INCORRECT_HTTP_STATUS = "ATRIUM_INCORRECT_HTTP_STATUS";

// Wybrane tabele ze zleceniami odświeżają się co REFRESH_TABLE_INTERVAL
// W milisekundach
export const REFRESH_TABLE_INTERVAL = 20000;
export const REFRESH_EVENTS_INTERVAL = 20000;
export const REFRESH_NEWS_INTERVAL = 1000 * 60 * 5;
// Dialog informujący o nowym zleceniu jest automatycznie zamykany po NEW_ORDER_DIALOG_LIFE
// W milisekundach
export const NEW_ORDER_DIALOG_LIFE = 15000;

export const MIN_DATE_IN_MONTH = 13;

export const CONSTRAINT_VIOLATION_ERROR_CODE = "CONSTRAINT_VIOLATION";

export const DEFAULT_PAGE_SIZE = 10;

export const ErrorCode = {
};


export const PtInClientLocation = {
    IN: {name: "W lokalu", value: true,},
    OUT: {name: "Poza lokalem", value: false},
    NO_INFORMATION: {name: "Brak informacji", value: null},
};

export const OrderStatus = {
    "ARCHIVED": "ARCHIVED",
    "DISPATCHER": "DISPATCHER",
    "PT_LATE": "PT_LATE",
    "PT_RESIGNATION": "PT_RESIGNATION",
    "PT_CLIE_POSTPON": "PT_CLIE_POSTPON",
    "PT_NO_RESPONSE": "PT_NO_RESPONSE",
    "PT_HANDOVER": "PT_HANDOVER",
    "PT_ENROUTE": "PT_ENROUTE",
    "PT_TECH_POSTPONED": "PT_TECH_POSTPONED",
    "PT_START_INSTALLATION": "PT_START_INSTALLATION",
    "PT_WAITING_FOR_CLI": "PT_WAITING_FOR_CLI",
    "PT_QUESTIONS_FOR_THE_CONTRACT": "PT_QUESTIONS_FOR_THE_CONTRACT",
    "SENT_TO_ATRIUM": "SENT_TO_ATRIUM",
    "ATRIUM_RECEIVED": "ATRIUM_RECEIVED",
    "ATRIUM_REJECTED": "ATRIUM_REJECTED",
    "ATRIUM_MANUAL_ONLINE": "ATRIUM_MANUAL_ONLINE",
    "DEALER_SUPPORT_NEW": "DEALER_SUPPORT_NEW",
    "OKI_REPLACEMENT_NEW": "OKI_REPLACEMENT_NEW",
    "SELL_CPE_NEW": "SELL_CPE_NEW",
    "REPLACEMENT_IN_SALONS_NEW": "REPLACEMENT_IN_SALONS_NEW",
    "OKI_ACTVATION_NEW": "OKI_ACTVATION_NEW",
    "WCKU_NEW": "WCKU_NEW",
    "DEALER_SUPPORT_REJECTED": "DEALER_SUPPORT_REJECTED",
    "OKI_REPLACEMENT_REJECTED": "OKI_REPLACEMENT_REJECTED",
    "OKI_ACTIVATION_REJECTED": "OKI_ACTIVATION_REJECTED",
    "REPLACEMENT_IN_SALONS_REJECTED": "REPLACEMENT_IN_SALONS_REJECTED",
    "OKI_ACTVATION_REJECTED": "OKI_ACTVATION_REJECTED",
    "WCKU_REJECTED": "WCKU_REJECTED",
    "CREATED": "CREATED",
    "REOPENED": "REOPENED",
    "SUSPENDED": "SUSPENDED",
    "RESUMED": "RESUMED",
    "DISCARDED": "DISCARDED",
    "BOT_OKI_BACK_TO_DS": "BOT_OKI_BACK_TO_DS",
    "BOT_DS_BACK_TO_OPL": "BOT_DS_BACK_TO_OPL",
    "OKI_ACTIVATION_TO_DS": "OKI_ACTIVATION_TO_DS",
    "BOT_DS_UNASSIGNED": "BOT_DS_UNASSIGNED",
    "BOT_OKI_UNASSIGNED": "BOT_OKI_UNASSIGNED",
    "OKI_NJU_NEW": "OKI_NJU_NEW",
    "OKI_NJU_REJECTED": "OKI_NJU_REJECTED",
};

export const OrderEventType = {
    "STATUS_CHANGE": "STATUS_CHANGE",
    "OPL_ASSIGNED_TO_ORDER": "OPL_ASSIGNED_TO_ORDER",
    "OPL_UNASSIGNED_TO_ORDER": "OPL_UNASSIGNED_TO_ORDER",
    "COMPLETED_BY_INSTALLER": "COMPLETED_BY_INSTALLER",
    "WORK_FINISHED_BY_INSTALLER": "WORK_FINISHED_BY_INSTALLER",
    "AUTOMATIC_ARCHIVING_EVENT": "AUTOMATIC_ARCHIVING_EVENT",
    "SUSPENDED_AFTER_CLIENT_RESIGNATION_EVENT": "SUSPENDED_AFTER_CLIENT_RESIGNATION_EVENT",
};

export const OplStatus = {
    "IN_PROGRESS": "IN_PROGRESS",
};

export const EventState = {
    "INIT_AUTO": "INIT_AUTO",
    "INIT_MANUAL": "INIT_MANUAL",
    "TO_MANUAL": "TO_MANUAL",
    "ACKNOWLEDGED": "ACKNOWLEDGED",
    "COMMITTED": "COMMITTED",
    "REJECTED": "REJECTED",
};

export const QueueType = {
    "MAIN": "MAIN",
    "HISTORY": "HISTORY",
};

export const OPL_ACTION_FOR_DELTA_CALCULATIONS = [OrderStatus.DISPATCHER];
export const ORDER_EVENT_TYPES_FOR_DELTA_CALCULATIONS = [OrderEventType.STATUS_CHANGE];

export const defaultSummary = "Wystąpił błąd!";
export const defaultDetail = "Operacja zakończyła się niepowodzeniem.";

export const forwardToDispatcher = {"name": 'Zatwierdź', "key": "dysp"};
export const rejectAndForwardToInstaller = {"name": 'Odrzuć', "key": "reject"};
export const forwardFromOkiActivationToDs = {"name": 'Przekieruj na kolejkę Dealer Support', "key": "forwardFromOkiActivationToDs"};
export const forwardFromDsToOkiActivation = {"name": 'Przekieruj na kolejkę Oki - aktywacje', "key": "forwardFromDsToOkiActivation"};

export const resumedToInstaller = {"name": 'Do serwisanta', "key": "resumed"};
export const discard = {"name": 'Do odrzucenia', "key": "discarded"};

export const DEFAULT_MODIFICATION_DATE_TIME_FROM_IN_DAYS = 5;
export const DEFAULT_MODIFICATION_DATE_TIME_FROM_FOR_ALL_AND_HISTORY_IN_DAYS = 10;


export const changeToInProgress = {"name": 'W trakcie', "key": "inProgress"};


export const DEFAULT_SORT_BY_FOR_ORDER = "modificationDateTime,desc";

export const MODIFICATION_DATE_TIME_ASC = "modificationDateTime,asc";

export const DEFAULT_SORT_BY_FOR_OPL_ORDER_QUEUE = "completionDateTime,asc";

export const DEFAULT_SORT_BY_FOR_OPL_ORDER_HISTORY_QUEUE = "completionDateTime,desc";


export const DEFAULT_ORDER_LIST_STATE = {
    orders: [],
    loading: false,
    first: 1,
    rows: DEFAULT_PAGE_SIZE, // Ilość wierszy na stronie
    totalRecords: 0,// Ilośc wszystkich elementów w bazie
    filters: {},
    area: {},
    sortBy: DEFAULT_SORT_BY_FOR_ORDER,
    filter: false,
    dialogVisible: false,
    makeCallDialogVisible: false
};
export const DEFAULT_ORDER_STATE = {
    order: {
        area: [],
        installers: [],
        technology: {},
        technologyDla: {},
        service: {},
        status: {},
        type: {},
        rejectionReason: {},
        orderDevices: []
    }

};

export const DEFAULT_ROWS_PER_PAGE_SIZES = [5, 10, 20, 25, 50, 100, 1000];
export const ACCOUNT_ROWS_PER_PAGE_SIZES = [8, 10, 20, 25, 50, 100];

export const ERROR_MESSAGE_TITLE = 'Wystąpił błąd!';
export const OPEN_CHAT_ERROR_MESSAGE_DETAIL = 'Nie można otworzyć czatu!';
export const ASSIGN_ORDER_ERROR_MESSAGE_DETAIL = 'Nie można pobrać zlecenia!';
export const UN_ASSIGN_ORDER_ERROR_MESSAGE_DETAIL = 'Nie można odpisać użytkownika od zlecenia!';

export const NO_DATA = "Brak danych";
export const NO_AREA = "Brak strefy";


export const HIDDEN_FIELDS_MAIN_QUEUE = ["modificationDateTime","rejectionReason","timeBetweenCompleteOrderByInstallerAndForwardedToDispatcherBody",
"timeBetweenOplAssigmentAndForwardedToDispatcherBody","handledByUser"]

export const HIDDEN_FIELDS_HISTORY_QUEUE = ["modificationDateTime"]

export const NOTIFICATION_LIMIT = 20;

export const TIME_TO_CLOSE_PT_CALL_DIALOG_AFTER_SUCCESS_IN_MILLIS = 5000;

export const CHAT_NOTIFICATION_ALLOWED_ROLES = [DEALER_SUPPORT_ROLE_KEY, OKI_ROLE_KEY, WCKU_ROLE_KEY];
