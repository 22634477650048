import '../../static/css/App.css';
import React from 'react';
import {Button} from "primereact/button";
import {Panel} from "primereact/panel";
import {InputText} from "primereact/inputtext";
import AdminTestOpsService from "../../services/AdminTestOpsService";

class AdminTestOpsPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orderNumber: "",

        };
    }

    createOrder = () => {
        AdminTestOpsService.createNewOrder((data) => {
            this.setState({orderNumber: data});
        }, (e) => {
            console.error("error CREATE");
            console.error(e);
        });
    };

    late = () => {
        AdminTestOpsService.sendLate(this.state.orderNumber, () => {
            console.debug("succes LATE");
        }, (e) => {
            console.error("error LATE");
            console.error(e);
        });
    };
    enroute = () => {
        AdminTestOpsService.sendEnroute(this.state.orderNumber, () => {
            console.debug("succes ENROUTE");
        }, (e) => {
            console.error("error ENROUTE");
            console.error(e);
        });
    };
    startInstallation = () => {
        AdminTestOpsService.sendStart(this.state.orderNumber, () => {
            console.debug("succes START");
        }, (e) => {
            console.error("error START");
            console.error(e);
        });
    };
    noResponse = () => {
        AdminTestOpsService.sendNoResponse(this.state.orderNumber, () => {
            console.debug("succes NORESPONSE");
        }, (e) => {
            console.error("error NORESPONSE");
            console.error(e);
        });
    };
    waitingForClient = () => {
        AdminTestOpsService.sendWaitingForClient(this.state.orderNumber, () => {
            console.debug("succes WAITING FOR CLIENT");
        }, (e) => {
            console.error("error WAITING FOR CLIENT");
            console.error(e);
        });
    };
    resignation = () => {
        AdminTestOpsService.sendResignation(this.state.orderNumber, () => {
            console.debug("succes RESIGNATION");
        }, (e) => {
            console.error("error RESIGNATION");
            console.error(e);
        });
    };
    finish = () => {
        AdminTestOpsService.sendFinish(this.state.orderNumber, () => {
            console.debug("succes FINISH");
        }, (e) => {
            console.error("error FINISH");
            console.error(e);
        });
    };

    render() {
        return (
            <div>
                <div className="p-inputgroup flex-1">
                    <Button className="p-button-warning" label="Zlecenie monitoring" onClick={() => {
                        this.createOrder()
                    }}/>
                    <InputText placeholder="Numer zlecenia" value={this.state.orderNumber} onInput={(e) => this.setState({orderNumber: e.value})}/>
                </div>
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Numer zlecenia" value={this.state.orderNumber} onInput={(e) => this.setState({orderNumber: e.value})}/>
                    <Button className="p-button-warning" label="Zlecenie monitoring - spóźnię" onClick={() => {
                        this.late()
                    }}/>
                </div>
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Numer zlecenia" value={this.state.orderNumber} onInput={(e) => this.setState({orderNumber: e.value})}/>
                    <Button className="p-button-warning" label="Zlecenie monitoring - w drodze" onClick={() => {
                        this.enroute()
                    }}/>
                </div>
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Numer zlecenia" value={this.state.orderNumber} onInput={(e) => this.setState({orderNumber: e.value})}/>
                    <Button className="p-button-warning" label="Zlecenie monitoring - start" onClick={() => {
                        this.startInstallation()
                    }}/>
                </div>
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Numer zlecenia" value={this.state.orderNumber} onInput={(e) => this.setState({orderNumber: e.value})}/>
                    <Button className="p-button-warning" label="Zlecenie monitoring - brak kontaktu" onClick={() => {
                        this.noResponse()
                    }}/>
                </div>
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Numer zlecenia" value={this.state.orderNumber} onInput={(e) => this.setState({orderNumber: e.value})}/>
                    <Button className="p-button-warning" label="Zlecenie monitoring - oczekiwanie na klienta" onClick={() => {
                        this.waitingForClient()
                    }}/>
                </div>
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Numer zlecenia" value={this.state.orderNumber} onInput={(e) => this.setState({orderNumber: e.value})}/>
                    <Button className="p-button-warning" label="Zlecenie monitoring - rezygnacja" onClick={() => {
                        this.resignation()
                    }}/>
                </div>
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Numer zlecenia" value={this.state.orderNumber} onInput={(e) => this.setState({orderNumber: e.value})}/>
                    <Button className="p-button-warning" label="Zlecenie monitoring - zakończenie" onClick={() => {
                        this.finish()
                    }}/>
                </div>
                <Panel header="Header">
                    <p className="m-0">
                        LOGI<br/>
                        LOGI<br/>
                        LOGI<br/>
                        LOGI<br/>
                        LOGI<br/>
                        LOGI<br/>
                        LOGI<br/>
                        LOGI<br/>
                        LOGI<br/>
                        LOGI<br/>
                    </p>
                </Panel>
            </div>
        )
    }
}

export default AdminTestOpsPanel;