import axios from "axios";
import { IS_AUTHENTICATED_KEY } from "../config/constants";
import packageJson from '../../package.json';

//https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript


function handle(onSuccess, onError, axiosPromise) {
    axiosPromise.then(function () {
        onSuccess();
    })
        .catch(function () {
            onError();
        });
}

const AuthService = {

    isLoggedIn() {
        return localStorage.getItem(IS_AUTHENTICATED_KEY) === "true";
    },


    authenticate(login, password, onSuccess, onError) {

        axios.post("/auth/login", {
            username: login,
            password: password
        })
            .then(function (response) {
                localStorage.setItem(IS_AUTHENTICATED_KEY, "true");
                onSuccess(response.data);
            })
            .catch(function (error) {
                console.log(`Login error${error}`);
                onError(error);
            });


    },
    changeAnotherAccountPassword(accountId, newPassword, onSuccess, onError) {
        handle(onSuccess, onError,
            axios.put(`/account/${accountId}/password`, { newPassword: newPassword }));
    },
    changePassword(oldPassword, newPassword, onSuccess, onError) {
        handle(onSuccess, onError,
            axios.put("/account/password", {
                oldPassword: oldPassword,
                newPassword: newPassword
            }));
    },
    signout(cb) {

        axios.post("/auth/logout")
            .then(function (response) {
                console.log(`Logout OK${response}`);
                localStorage.clear();
                cb();
            })
            .catch(function (error) {
                localStorage.clear();
                console.log(`Login error${error}`);
            });

    },
    clearLocalStorageAndForwardToLoginWhen401(element, error) {
        if (error && error.response && error.response.status === 401) {

            localStorage.clear();
            if (element && element.props && element.props.history) {
                element.props.history.push("/login");
            }
        }
    },

    clearLocalStorageAndForwardToLogin(window) {
        localStorage.clear();

        if (window && window.location) {
            window.location = `${packageJson.homepage}/login`.replace(/\/\//g, "/");
        }
    },


};

export default AuthService;

