import React from "react";
import {withRouter} from 'react-router-dom';
import '../../static/css/App.css';
import AddEditNews from "./AddEditNews";

class AddNews extends React.Component {



    render() {

        return (<AddEditNews editMode={false}/>);

    }
}

export default withRouter(AddNews);