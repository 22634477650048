import RestService from '../RestService';

const PATH = '/orderType';

const OrderTypeService = {

    update(data, onSuccess, onError) {
        RestService.update(`${PATH}/${data["id"]}`, data, onSuccess, onError);
    },

    getAll(onSuccess, onError) {
        return RestService.get(PATH, onSuccess, onError);
    },

};

export default OrderTypeService;

