import { Growl } from "primereact/growl";
import React from 'react';
import {
    DEFAULT_ORDER_LIST_STATE,

    QueueType,

    REFRESH_TABLE_INTERVAL
} from "../../config/constants";
import AuthService from "../../services/AuthService";
import MonitoringService from "../../services/MonitoringService";
import QueueOrderNotificationService from "../../services/QueueOrderNotificationService";
import OrderDataTable from "../../shared/orderDataTable/OrderDataTable";
import OrderFilterLoadService from "../../shared/OrderFilterLoadService";
import OrderFilterPanel, { defaultOnFilterChange } from "../../shared/OrderFilterPanel";
import { createParams } from "../../shared/OrderTableCommon";
import { filtersNotEmptySkipModificationDateTimeFrom, showQueueNewOrdersNotifications } from "../../shared/Utils";
import '../../static/css/App.css';
import MonitoringOperationUtil from "./MonitoringOperationUtil";

class MonitoringAllOrders extends React.Component {

    state = DEFAULT_ORDER_LIST_STATE;


    constructor(props) {
        super(props);
        this.onPage = this.onPage.bind(this);
    }


    refreshTable() {
        this.getOrders(this.state.filters, this.state.sortBy, this.state.pageNumber, this.state.startIndex, (data) => {
            showQueueNewOrdersNotifications(this, QueueOrderNotificationService.getNewOrdersFromMonitoringAllQueue);
        });

    }

    componentDidMount() {
        this.setState({ filters: new OrderFilterLoadService().getFilter(QueueType.HISTORY) }, () => {
            this.resetIntervalAndRefreshTable();

            this.setState({
                filter: filtersNotEmptySkipModificationDateTimeFrom(this.state.filters)
            });

        });

    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    resetInterval() {
        clearInterval(this.interval);
        this.interval = setInterval(() => this.refreshTable(), REFRESH_TABLE_INTERVAL);
    }

    resetIntervalAndRefreshTable() {
        this.resetInterval();
        this.refreshTable();
    }


    onPage(event) {
        this.setState({
            loading: true
        });
        const startIndex = event.first;
        const pageNumber = startIndex / event.rows + 1;

        this.setState({ pageNumber: pageNumber, startIndex: startIndex, rows: event.rows }, () => {
            this.getOrders(this.state.filters, this.state.sortBy, this.state.pageNumber, this.state.startIndex);
        });

    }


    getOrders(filters, sortBy, pageNumber, startIndex, onSuccess) {

        const params = createParams(filters, sortBy);
        MonitoringService.getAll(params, pageNumber, this.state.rows, (page) => {

            this.setState({
                orders: page.content,
                totalRecords: page.totalElements,
                loading: false,
                first: startIndex,
            });
            if (onSuccess) {
                onSuccess(page.content);
            }
        },
            (error) => {
                this.setState({
                    loading: false
                });
                console.log(`Error:${error}`);
                AuthService.clearLocalStorageAndForwardToLoginWhen401(this, error);
            });
    }


    render() {
        const filterColumns = {
            finishedWorkSuccess: true,
            finishedWorkDateTime: true,
            ptInClientLocation: true,
            wifiAnalyzeAdded: true,
        };
        return (
            <div>
                {  <OrderFilterPanel
                    // visible="sortField,serviceId"
                    onFilterChange={defaultOnFilterChange(this)}
                    onCleanButton={(filters) => {
                        new OrderFilterLoadService().resetFilter((filters) => {
                            this.setState({ filters: filters }, () => {
                                this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                            });

                        },QueueType.HISTORY);
                    }}
                    filters={this.state.filters} filter={this.state.filter}
                    visibleObject={filterColumns}
                    onFilter={() => {
                        this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                    }}
                    onSortFieldChange={(val, property) => {
                        this.setState({ [property]: val });
                    }}
                />}


                <Growl ref={(el) => this.growl = el} position="topright" />

                <OrderDataTable visible={"location,timeSlot,installationStartDateTime,resignationDateTime,clientPostPoneDateTime,noResponseDateTime," +
                    "techPostPoneDateTime,enrouteDateTime,handoverDateTime,delay,ptInClientLocation,installerFinishedWorkDateTime,installerFinishedWorkSuccess,wifiAnalyze," +
                    "waitingForClientDateTime,questionForContractDateTime"}
                    totalRecords={this.state.totalRecords}
                    toogleFilter={() => this.setState({ filter: !this.state.filter })}
                    headerTitle={"Wszystkie zlecenia"} orders={this.state.orders} rows={this.state.rows}
                    first={this.state.first} loading={this.state.loading} onPage={this.onPage}
                    refreshTable={() => {
                        this.resetIntervalAndRefreshTable();
                    }}
                    assign = {MonitoringService.assingOplUser}
                    unassign = {MonitoringService.unassingOplUser}
                    assignAndOpenOrderBtnDisabled={(rowData) => {
                        return !MonitoringOperationUtil.isAnyOperationAvailable(rowData);
                    }}
                    openChatWithoutAssignment={true}
                    skipLastURLSegmentOfItem={true}
                    onSort={(sortBy) => {
                        this.setState({ sortBy: sortBy }, () => {
                            this.resetIntervalAndRefreshTable();
                        });
                    }} />
            </div>

        );
    }


}

export default MonitoringAllOrders;

