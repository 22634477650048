import {PickList} from "primereact/picklist";
import {PickListControls} from "primereact/components/picklist/PickListControls";
import React from "react";
import {PickListTransferControls} from "primereact/components/picklist/PickListTransferControls";
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import {FilteredPickListSubList} from "./FilteredPickListSubList";
import PropTypes from 'prop-types';

export class FilteredPickList extends PickList {
    static defaultProps = {
        id: null,
        source: null,
        target: null,
        sourceHeader: null,
        targetHeader: null,
        style: null,
        className: null,
        sourceStyle: null,
        targetStyle: null,
        responsive: false,
        showSourceControls: true,
        showTargetControls: true,
        metaKeySelection: true,
        tabIndex: '0',
        itemTemplate: null,
        onChange: null,
        onMoveToSource: null,
        onMoveAllToSource: null,
        onMoveToTarget: null,
        onMoveAllToTarget: null,
        onSourceSelect: null,
        onTargetSelect: null,
        searchBy: null
    };

    static propTypes = {
        id: PropTypes.string,
        source: PropTypes.array,
        target: PropTypes.array,
        sourceHeader: PropTypes.string,
        targetHeader: PropTypes.string,
        style: PropTypes.object,
        className: PropTypes.string,
        sourcestyle: PropTypes.object,
        targetstyle: PropTypes.object,
        responsive: PropTypes.bool,
        showSourceControls: PropTypes.bool,
        showTargetControls: PropTypes.bool,
        metaKeySelection: PropTypes.bool,
        tabIndex: PropTypes.string,
        itemTemplate: PropTypes.func,
        onChange: PropTypes.func,
        onMoveToSource: PropTypes.func,
        onMoveAllToSource: PropTypes.func,
        onMoveToTarget: PropTypes.func,
        onMoveAllToTarget: PropTypes.func,
        onSourceSelect: PropTypes.func,
        onTargetSelect: PropTypes.func,
        searchBy: PropTypes.string
    };

    render() {
        const className = classNames('p-picklist p-component', this.props.className, {
            'p-picklist-responsive': this.props.responsive
        });
        return (
            <div id={this.props.id} className={className} style={this.props.style}>
                {this.props.showSourceControls &&
                <PickListControls list={this.props.source} selection={this.state.selectedItemsSource}
                    onReorder={this.onSourceReorder} className="p-picklist-source-controls"/>}
                <FilteredPickListSubList ref={(el) => this.sourceListElement = ReactDOM.findDOMNode(el)}
                    list={this.props.source} selection={this.state.selectedItemsSource}
                    onSelectionChange={(e) => this.onSelectionChange(e, 'selectedItemsSource', this.props.onSourceSelect)}
                    itemTemplate={this.props.itemTemplate}
                    header={this.props.sourceHeader} style={this.props.sourceStyle}
                    className="p-picklist-source-wrapper" listClassName="p-picklist-source"
                    metaKeySelection={this.props.metaKeySelection} tabIndex={this.props.tabIndex} searchBy={this.props.searchBy}/>
                <PickListTransferControls onTransfer={this.onTransfer} source={this.props.source}
                    target={this.props.target}
                    sourceSelection={this.state.selectedItemsSource}
                    targetSelection={this.state.selectedItemsTarget}/>
                <FilteredPickListSubList ref={(el) => this.targetListElement = ReactDOM.findDOMNode(el)}
                    list={this.props.target} selection={this.state.selectedItemsTarget}
                    onSelectionChange={(e) => this.onSelectionChange(e, 'selectedItemsTarget', this.props.onTargetSelect)}
                    itemTemplate={this.props.itemTemplate}
                    header={this.props.targetHeader} style={this.props.targetStyle}
                    className="p-picklist-target-wrapper" listClassName="p-picklist-targe"
                    metaKeySelection={this.props.metaKeySelection} tabIndex={this.props.tabIndex} searchBy={this.props.searchBy}/>
                {this.props.showTargetControls &&
                <PickListControls list={this.props.target} selection={this.state.selectedItemsTarget}
                    onReorder={this.onTargetReorder} className="p-picklist-target-controls"/>}
            </div>
        );
    }
}
