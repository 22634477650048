import React, {Component} from 'react';
import {Dropdown} from "primereact/dropdown";


class FinishedWorkDropDown extends Component {


    state = {
        values: [{"name": "skuteczna", "id": true}, {"name": "nieskuteczna", "id": false}],
        value: null
    }

    componentDidMount() {
    }


    render() {

        return (
            <Dropdown id={this.props.id}
                showClear={typeof this.props.showClear !== 'undefined' ? this.props.showClear : true}
                className={this.props.className} optionLabel="name"
                style={this.props.style}
                value={this.props.value}
                options={this.state.values}
                dataKey={"id"}
                onChange={(event) => {
                    return this.props.onChange(event.value);
                }}/>

        );
    }
}

export default FinishedWorkDropDown;
