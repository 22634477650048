import { AutoComplete } from 'primereact/autocomplete';
import React from 'react';
import AccountService from '../../services/AccountService';
import { createFirstNameAndLastNameLoginSt } from '../Utils';


class OplAccountAutoComple extends React.Component {


    state = {
        options: [],
        selected: []

    }

    selectedItemTemplate(installer) {
        return <span>{createFirstNameAndLastNameLoginSt(installer)}</span>;
    }

    itemTemplate(installer) {
        return <span>{createFirstNameAndLastNameLoginSt(installer)}</span>;
    }

    suggestValue(event) {

        const query = event.query.toLowerCase().trim();
        AccountService.getOplAccountsByQueryLikeFilter(query, 100, (values) => {
            this.setState({ suggestions: values });
        }, (error) => {
            console.log(` AccountService.getOplAccountsByQueryLikeFilter->error${JSON.stringify(error)}`);
        });


    }

    render() {

        return (

            <AutoComplete className="filter-panel-input" itemTemplate={this.itemTemplate} multiple={true} dropdown={false}
                value={this.props.value} placeholder={this.props.placeholder}
                onChange={(e) => {
                    if (this.props.onChange) {
                        this.props.onChange(e);
                    }
                }}
                suggestions={this.state.suggestions} completeMethod={this.suggestValue.bind(this)}
                selectedItemTemplate={this.selectedItemTemplate} />

        );
    }
}

export default OplAccountAutoComple;
