import React from 'react';

export const sourceFilter = function (source, selectedGroups) {

    const uG = selectedGroups;
    if (uG && source) {
        for (let i = 0; i < uG.length; i += 1) {
            const uge = uG[i];
            const index = source.findIndex(g => g.id === uge.id);
            if (index >= 0) {
                source.splice(index, 1);
            }
        }
    }
    return source;
}