import {AutoComplete} from 'primereact/autocomplete';
import React from 'react';
import AreaService from "../../services/dict/AreaService";

class AreaAutoComplete extends React.Component {
    state = {
        options: [],
        selected: []

    }

    selectedItemTemplate(value) {
        return <span>{value.verboseName}</span>;
    }

    itemTemplate(value) {
        return <span>{value.verboseName}</span>;
    }

    suggestValue(event) {
        const query = event.query.toLowerCase();
        AreaService.getLikeVerboseNameWithLimit(query, 100, (values) => {
            this.setState({suggestions: values});
        }, (error) => {
            console.log(`AreaService.getLikeVerboseNameWithLimit()->error${JSON.stringify(error)}`);
        });
    }

    render() {
        return (
            <AutoComplete className="filter-panel-input" itemTemplate={this.itemTemplate} multiple={true}
                          dropdown={false}
                          value={this.props.value} placeholder={this.props.placeholder}
                          onChange={(e) => {
                              this.setState({value: e.value});
                              if (this.props.onChange) {
                                  this.props.onChange(e);
                              }
                          }}
                          suggestions={this.state.suggestions} completeMethod={this.suggestValue.bind(this)}
                          selectedItemTemplate={this.selectedItemTemplate}/>
        );
    }
}

export default AreaAutoComplete;
