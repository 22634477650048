import { MultiSelect } from "primereact/multiselect";
import React from 'react';
import GroupService from '../../services/GroupService';


class GroupMultiSelect extends React.Component {


    state = {
        values: [],
        val: null

    }

    componentDidMount() {
        GroupService.getAll((values) => {
            this.setState({values: values});
        }, (error) => {
            console.log(`GroupService.getAll()->error${JSON.stringify(error)}`);
        });

    }


    render() {

        return (

            <MultiSelect showClear={true} className={this.props.className} optionLabel="verboseName"
                value={this.props.value} options={this.state.values}
                placeholder={this.props.placeholder} filter={true}
                dataKey={"id"}
                onChange={(event) => {
                    if (this.props.onChange) {
                        this.props.onChange(event);
                    }
                }}/>

        );
    }
}

export default GroupMultiSelect;
