import is from 'is_js';
import {Button} from "primereact/button";
import {Card} from 'primereact/card';
import {Dialog} from "primereact/dialog";
import {Growl} from "primereact/growl";
import {OverlayPanel} from 'primereact/overlaypanel';
import React, {Component} from 'react';
import {withRouter} from "react-router";
import {
    ERROR_MESSAGE_TITLE, OPEN_CHAT_ERROR_MESSAGE_DETAIL,
    CHAT_NOTIFICATION_ALLOWED_ROLES, NEW_ORDER_DIALOG_LIFE, REFRESH_TABLE_INTERVAL, REFRESH_NEWS_INTERVAL
} from '../../config/constants';
import AuthService from "../../services/AuthService";
import ChatService from "../../services/ChatService";
import CurrentAccountService from "../../services/CurrentAccountService";
import ErrorService from '../../services/ErrorService';
import NewsService from '../../services/NewsService';
import {chat, notify, soundNotification} from '../../shared/Utils';
import '../../static/css/App.css';
import ChangePasswordDialog from "../auth/ChangePasswordDialog";

class Header extends Component {


    state = {
        successDialogVisible: false,
        isMessagePanelVisible: false
    }

    logout = () => {
        AuthService.signout(() => this.props.history.push("/login"));
    }

    handleOnChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
            loggingError: false
        });
    }

    hideSuccessDialog = () => {
        this.setState({successDialogVisible: false});
    }

    showSuccessDialog = () => {
        this.setState({successDialogVisible: true});
    }

    showChangePasswordDialog() {
        return () => this.setState({changePasswordDialogVisible: true});
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        clearInterval(this.newsInterval);
    }

    componentDidMount() {
        clearInterval(this.interval);
        this.interval = setInterval(() => {
            this.getPushNotificationsIfAllowed();
        }, REFRESH_TABLE_INTERVAL);


        NewsService.hasUnreadMessages(
            (data) => this.setState({hasUnreadMessages: data.hasUnreadMessages}),
            (error) => ErrorService.logServerError(error))
        clearInterval(this.newsInterval);
        this.newsInterval = setInterval(() => {
            if (!this.state.hasUnreadMessages) {
                NewsService.hasUnreadMessages(
                    (data) => this.setState({hasUnreadMessages: data.hasUnreadMessages}),
                    (error) => ErrorService.logServerError(error));
            }
        }, REFRESH_NEWS_INTERVAL);
    }

    getPushNotificationsIfAllowed() {
        if (CurrentAccountService.areAllowedRolesInCurrentAccountRoles(CHAT_NOTIFICATION_ALLOWED_ROLES)) {
            ChatService.getPushNotifications((data) => {
                    if (data) {
                        for (let i = 0; i < data.length; i += 1) {
                            const notif = data[i];
                            soundNotification();
                            notify("Masz nową wiadomość.", `Masz nieprzeczytaną wiadomość na chacie do zlecenia o numerze: ${notif.orderNumber}`, notif.orderId, this);
                            this.growl.show({
                                life: NEW_ORDER_DIALOG_LIFE,
                                severity: 'info',
                                summary: "Masz nową wiadomość.",
                                detail: `Masz nieprzeczytaną wiadomość na chacie do zlecenia o numerze: ${notif.orderNumber}`,
                                openChat: true,
                                orderId: notif.orderId
                            });
                        }
                    }
                },
                () => {
                });
        }
    }

    createChat(message) {
        if (message) {
            if (message.openChat && message.orderId) {
                chat(message.orderId, this);
            }
        }
    }

    showError() {
        this.growl.show({severity: 'error', summary: ERROR_MESSAGE_TITLE, detail: OPEN_CHAT_ERROR_MESSAGE_DETAIL});
    }

    toogleMessagePanel(e) {
        this.op.toggle(e);
        this.setState({isMessagePanelVisible: !this.state.isMessagePanelVisible}, () => {
            if (this.state.isMessagePanelVisible) {
                NewsService.getNewsNotifications(
                    (data) => {
                        this.setState({news: data.content, hasUnreadMessages: false});
                        NewsService.updateLastMsgReadDateTime(data.queryDateTime, () => {
                        }, () => {
                        })
                    },
                    (error) => ErrorService.logServerError(error));
            }
        });

    }

    render() {
        //https://stackoverflow.com/questions/47476186/when-user-is-not-logged-in-redirect-to-login-reactjs
        return (
            <div className="header-wrapper p-col-12 p-col p-grid p-nogutter p-justify-end p-align-center">
                <Growl ref={(el) => this.growl = el} position="topright" className={"open-chat"}
                       onClick={(message) => this.createChat(message)}/>
                <div className="header p-col-12 p-col p-grid p-nogutter p-justify-end p-align-center">

                    <div className=" p-col-6 p-col p-grid p-nogutter p-justify-start p-align-center">
                        <Button icon={this.props.menuVisible ? "pi pi-angle-double-left" : "pi pi-angle-double-right"}
                                onClick={this.props.onClickToggleMenu}/>
                        <Button icon="pi pi-home" label="Strona główna" onClick={() => this.props.history.push('/')}
                                style={{'float': 'left', "margin": "5px"}}/>
                    </div>
                    <ChangePasswordDialog visible={this.state.changePasswordDialogVisible}
                                          onHide={() => this.setState({changePasswordDialogVisible: false})}
                                          onSuccess={this.showSuccessDialog}/>
                    <Dialog header="Operacja zakończona sukcesem" visible={this.state.successDialogVisible}
                            onHide={this.hideSuccessDialog}>
                        <div>
                            <Button label="OK" onClick={this.hideSuccessDialog}/>
                        </div>
                    </Dialog>
                    <div className="p-col-6 p-col p-grid p-nogutter p-justify-end p-align-center rigthHeader">
                        <span style={{'float': 'right', "margin": "5px"}}>
                            Witaj {CurrentAccountService.getLogin()}!
                        </span>
                        <OverlayPanel ref={(el) => this.op = el}
                                      dismissable id="overlay_panel" onHide={() => {
                            this.setState({
                                isMessagePanelVisible: false
                            });
                        }}
                                      style={{width: '450px'}}>

                            {Array.isArray(this.state.news) && this.state.news.length ? this.state.news.map((news) => {
                                return <div onClick={(e) => {
                                    this.props.history.push('/');
                                    this.op.hide(e);
                                }}>
                                    <Card title={news.title} className="marginBottom">
                                        {news.body}
                                    </Card>
                                </div>;
                            }) : "Brak wiadomości"}

                            {/* https://primefaces.org/primereact/showcase/#/overlaypanel */}
                        </OverlayPanel>
                        <Button icon="pi pi-bell" className={this.state.hasUnreadMessages ? 'badge' : ''}
                                style={{"margin": "5px"}}
                                onClick={(e) => this.toogleMessagePanel(e)} aria-controls="popup_menu" aria-haspopup/>

                        <Button label="Zmień hasło" onClick={this.showChangePasswordDialog()}/>
                        <Button icon="pi pi-fw  pi pi-sign-out" style={{'float': 'right', "margin": "5px"}}
                                onClick={this.logout}/>
                    </div>
                </div>
                {is.not.firefox() &&
                <div className="browser-warning p-col-12 p-col p-grid p-nogutter p-justify-center p-align-center">
                    Wykryto przeglądarkę inną niż Firefox. Pewne funkcjonalności panelu mogą działać niepoprawnie.
                </div>}
            </div>
        );
    }
}

export default withRouter(Header);
