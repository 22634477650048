import '../../static/css/App.css';
import React from 'react';
import Dictionary from "./Dictionary";
import ServiceOptionService from "../../services/dict/ServiceOptionService";


class ServiceOption extends React.Component {

    render() {

        return (
            <Dictionary
                title="Opcje usługowe"
                fetchValues={(onSuccess, onError) => {
                    ServiceOptionService.getAll((data) => {
                        onSuccess(data);
                    },
                    (error) => {
                        onError(error);
                    });
                }}
                addUrl={"/dictionary/serviceOption/add"}
                update={(data, onSuccess) => {
                    ServiceOptionService.update(data, onSuccess);
                }}
                remove={(data, onSuccess,onError) => {
                    ServiceOptionService.remove(data, onSuccess,onError);
                }}
            />

        );
    }

}

export default ServiceOption;

