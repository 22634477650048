import { Button } from 'primereact/button';
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import React from "react";
import '../static/css/App.css';
import GroupMultiSelect from "./multiselect/GroupMultiselect";

class AccountFilterPanel extends React.Component {


    state = {
        sortDropdownData: [],
        enabled: true
    }


    constructor(props) {
        super(props);
        const sortTable = [
            { name: 'Id, rosnąco', key: 'id,asc' },
            { name: 'Id, malejąco', key: 'id,desc' },
            { name: 'Login, rosnąco', key: 'login,asc' },
            { name: 'Login, malejąco', key: 'login,desc' },
        ];
        const sortDropdownData = this.props.sortDropdownData;
        if (!sortDropdownData) {
            this.state.sortDropdownData = sortTable;
        } else {
            this.state.sortDropdownData = sortDropdownData;
        }

        // Default "Aktywny" value
        const onFilterChange = this.props.onFilterChange;
        if (onFilterChange) {
            onFilterChange({ "enabled": "true" });
        }

    }

    saveFiltersFromCalendar(event, property) {
        const val = event ? event.value : null;
        const onFilterChange = this.props.onFilterChange;
        if (onFilterChange) {
            onFilterChange(val, property);
        }
        this.setState({ [property]: val });
    }

    saveFiltersFromMultiselect(event, property) {

        const values = event.value;
        if (values) {
            const onFilterChange = this.props.onFilterChange;
            if (onFilterChange) {
                onFilterChange({ [property]: values });
            }

        }

    }


    render() {

        var filters = this.props.filters ? this.props.filters : {};
        return (
            <div className="filter-panel">
                <div className="filter-panel-field-div">


                    <div className="filter-panel-div">
                        <label className="filter-panel-label">Id</label>
                        <InputText className="filter-panel-input" value={filters.id} keyfilter="pint"
                            onChange={(event) => {
                                const val = event.target ? event.target.value : null;
                                const onFilterChange = this.props.onFilterChange;
                                if (onFilterChange) {
                                    onFilterChange({ "id": val });
                                }
                            }} />
                    </div>


                    <div className="filter-panel-div">
                        <label htmlFor="searchInputField" className="filter-panel-label">Login</label>
                        <InputText className="filter-panel-input" value={filters.login}
                            onChange={(event) => {
                                const val = event.target ? event.target.value : null;
                                const onFilterChange = this.props.onFilterChange;
                                if (onFilterChange) {
                                    onFilterChange({ "login": val });
                                }
                            }} />
                    </div>

                    <div className="filter-panel-div">
                        <label htmlFor="searchInputField" className="filter-panel-label">Imię</label>
                        <InputText className="filter-panel-input" value={filters.firstName}
                            onChange={(event) => {
                                const val = event.target ? event.target.value : null;
                                const onFilterChange = this.props.onFilterChange;
                                if (onFilterChange) {
                                    onFilterChange({ "firstName": val });
                                }
                            }} />
                    </div>

                    <div className="filter-panel-div">
                        <label htmlFor="searchInputField" className="filter-panel-label">Nazwisko</label>
                        <InputText className="filter-panel-input" id="searchInputField" value={filters.lastName}
                            onChange={(event) => {
                                const val = event.target ? event.target.value : null;
                                const onFilterChange = this.props.onFilterChange;
                                if (onFilterChange) {
                                    onFilterChange({ "lastName": val });
                                }
                            }} />
                    </div>

                    <div className="filter-panel-div">
                        <label htmlFor="searchInputField" className="filter-panel-label">Email</label>
                        <InputText className="filter-panel-input" id="searchInputField" value={filters.email}
                            onChange={(event) => {
                                const val = event.target ? event.target.value : null;
                                const onFilterChange = this.props.onFilterChange;
                                if (onFilterChange) {
                                    onFilterChange({ "email": val });
                                }
                            }} />
                    </div>


                    <div className="filter-panel-div">
                        <label className="filter-panel-label">Serwisant</label>
                        <div className="filter-panel-input">
                            <InputSwitch checked={filters.installer}
                                onChange={(event) => {
                                    const val = event.target ? event.target.value : null;
                                    const onFilterChange = this.props.onFilterChange;
                                    if (onFilterChange) {
                                        onFilterChange({ "installer": val });
                                    }
                                }}
                            />
                        </div>

                    </div>

                    <div className="filter-panel-div">
                        <label className="filter-panel-label">Aktywny</label>
                        <div className="filter-panel-input">
                            <InputSwitch checked={filters.enabled}
                                onChange={(event) => {
                                    const val = event.target ? event.target.value : null;
                                    const onFilterChange = this.props.onFilterChange;
                                    if (onFilterChange) {
                                        onFilterChange({ "enabled": val });
                                    }
                                }}
                            />
                        </div>

                    </div>


                    <div className="filter-panel-div">
                        <label className="filter-panel-label">Grupa</label>


                        <GroupMultiSelect className="filter-panel-input"
                            value={filters.groupId}
                            onChange={(event) => {
                                this.saveFiltersFromMultiselect(event, "groupId");
                            }} />


                    </div>



                </div>
                <Button className="filter-panel-label" onClick={() => {
                    const onFilter = this.props.onFilter;
                    if (onFilter) {
                        onFilter();
                    }
                }} label="Filtruj"></Button>
                <Button className="filter-panel-label" onClick={() => {
                    const filters = {
                        "id": "",
                        "login": "",
                        "firstName": "",
                        "lastName": "",
                        "email": "",
                        "installer": "",
                        "enabled": "",
                        "groupId": [],

                    };
                    this.setState(filters);
                    const onCleanButton = this.props.onCleanButton;
                    if (onCleanButton) {
                        onCleanButton(filters);
                    }

                }} label="Wyczyść" style={{ "marginLeft": "5px" }} >
                </Button>
            </div>

        );
    }
}

export default AccountFilterPanel;
