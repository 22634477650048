import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Messages} from "primereact/messages";
import React from 'react';
import {withRouter} from "react-router-dom";
import '../../static/css/App.css';
import {
    ASSIGN_ORDER_ERROR_MESSAGE_DETAIL,
    ERROR_MESSAGE_TITLE,
    OPEN_CHAT_ERROR_MESSAGE_DETAIL
} from "../../config/constants";
import OrderService from "../../services/OrderService";
import ChatService from "../../services/ChatService";
import ErrorService from "../../services/ErrorService";
import _ from "lodash";

class AssignBeforeOpenChatDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
           disabled: false,
        };
    }

    assignUserAndOpenChat = (rowData) => {
        this.setState({disabled: true}, function () {
            this.assignUser(rowData.id,
                (response) => {
                    this.justOpenChat(rowData);
                },
                (error) => {
                    ErrorService.logServerError(error);
                    this.showError(ERROR_MESSAGE_TITLE, this.getErrorMessage(error));
                    this.setState({disabled: false});
                })
        });
    }

    justOpenChat = (rowData) => {
        this.setState({disabled: true}, function () {
            ChatService.chat(rowData.id, (errorData) => {
                let msg = {title:ERROR_MESSAGE_TITLE,msg:OPEN_CHAT_ERROR_MESSAGE_DETAIL};
                msg =ErrorService.mapResponseBodyToErrorTexts(errorData,msg);
                this.props.parent.showError(msg.title, msg.msg);
                this.setState({disabled: false});
            }, () => {
                this.setState({
                    notificationMap: ChatService.hideBadge(this.props.notificationMap || {}, rowData.id),
                    disabled: false
                }, () => this.props.parent.refreshOrder())
            })
            this.props.onHide();
        });
    }

    assignUser(orderId, onSuccess, onError) {
        if (this.props.assign) {
            this.props.assign(orderId, onSuccess, onError);
        } else {
            OrderService.assingOplUser(orderId, onSuccess, onError);
        }
    }

    getErrorMessage(error) {
        let errorMessage;
        let errorCode = _.get(error, "response.data.errorCode", null);
        switch (errorCode) {
            case 'OPL_ALREADY_ASSIGNED_TO_ORDER':
                errorMessage = "Inny użytkownik OPL jest przypisany aktualnie do zlecenia!";
                break;
            default:
                errorMessage = ASSIGN_ORDER_ERROR_MESSAGE_DETAIL;
                break;
        }
        return errorMessage;
    }

    showError = (summary, detail) => {
        this.messages.show({severity: 'error', summary: summary, detail: detail});
    }

    showSuccess = (summary, detail) => {
        this.messages.show({severity: 'success', summary: summary, detail: detail});
    }

    render() {
        const {rowData} = this.props;

        const dialogBody = "Czy chcesz przypisać się do zlecenia?";

        const dialogFooter = (
            <>
                <div className={"dialogFooter"}>
                    <span className={"margin-right-assign"}>
                        <Button label="TAK" icon="pi pi-check" className={"p-button-success"}
                                disabled={this.state.disabled}
                                onClick={(event) => {
                                    this.assignUserAndOpenChat(rowData);
                                }}/>
                    </span>
                    <span className={"margin-right-assign"}>

                        <Button label="NIE" icon="pi pi-times" className="p-button-danger"
                                disabled={this.state.disabled}
                                onClick={(event) => {
                                    this.justOpenChat(rowData);
                                }}/>
                    </span>
                </div>
                <Messages ref={(el) => this.messages = el} className={'call-error'}/>
            </>
        );

        return (
            <>
                <Dialog focusOnShow={true} closeOnEscape={true} showHeader={false}
                        footer={dialogFooter}
                        visible={this.props.visible} className={'call-pt-dialog'}
                        modal={true}
                        onHide={() => {
                            if (this.props.onHide) this.props.onHide();
                        }}><span className={"bold"}>{dialogBody}</span>
                </Dialog>
            </>
        );
    }
}

export default withRouter(AssignBeforeOpenChatDialog);