import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {Messages} from "primereact/messages";
import {OverlayPanel} from "primereact/overlaypanel";
import React from 'react';
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import {
    ASSIGNMENT_MANAGEMENT_ROLE_KEY, ASSIGN_ORDER_ERROR_MESSAGE_DETAIL,
    DEFAULT_MODIFICATION_DATE_TIME_FROM_IN_DAYS,
    DEFAULT_ROWS_PER_PAGE_SIZES,
    ERROR_MESSAGE_TITLE,
    CHAT_NOTIFICATION_ALLOWED_ROLES, UN_ASSIGN_ORDER_ERROR_MESSAGE_DETAIL
} from "../../config/constants";
import ChatService from "../../services/ChatService";
import CurrentAccountService from "../../services/CurrentAccountService";
import ErrorService from "../../services/ErrorService";
import OrderEventUtil, {mapToFinishedWorkSuccess} from "../../services/OrderEventUtil";
import OrderService from "../../services/OrderService";
import OplStatusMapper from "../OplStatusMapper";
import '../../static/css/App.css';
import '../../static/css/Order.css';
import './OrderDataTable.css';
import {InstallerRow} from '../InstallerRow';
import {AreaBody, DateTimeTemplate, FirstLastNameLoginBody, LocationBody, TechnologyDlaBody} from "../OrderTableCommon";
import {DateTimeField} from "../Utils";
import _ from "lodash";

class OrderDataTableWithRouter extends React.Component {

    // visible={"timeSlot,installationStartDateTime,installerFinishedWorkDateTime,installerFinishedWorkSuccess,wifiAnalyze,resignationDateTime,clientPostPoneDateTime,techPostPoneDateTime,enrouteDateTime,handoverDateTime,delay"}
    constructor(props) {
        super(props);


        this.state = {
            style: {
                timeSlot: {
                    textAlign: "center",
                    display: "none"
                },
                installationStartDateTime: {
                    textAlign: "center",
                    display: "none",
                    whiteSpace: "nowrap"
                },
                installerFinishedWorkDateTime: {
                    textAlign: "center",
                    display: "none"
                },
                installerFinishedWorkSuccess: {
                    textAlign: "center",
                    display: "none"
                },
                oplStatus: {
                    textAlign: "center",
                    display: "none",
                    whiteSpace: "nowrap"
                },
                wifiAnalyze: {
                    textAlign: "center",
                    display: "none"
                },
                resignationDateTime: {
                    textAlign: "center",
                    "whiteSpace": "nowrap",
                    display: "none"
                },
                clientPostPoneDateTime: {
                    textAlign: "center",
                    display: "none",
                    minWidth: "10em"
                },
                noResponseDateTime: {
                    textAlign: "center",
                    display: "none",
                    // whiteSpace: "pre-line",
                    minWidth: "10em"
                },
                waitingForClientDateTime: {
                    textAlign: "center",
                    display: "none",
                    // whiteSpace: "pre-line",
                    minWidth: "10em"
                },
                questionForContractDateTime: {
                    textAlign: "center",
                    display: "none",
                    // whiteSpace: "pre-line",
                    minWidth: "10em"
                },
                techPostPoneDateTime: {
                    textAlign: "center",
                    display: "none",
                    // whiteSpace: "nowrap"
                    minWidth: "10em"
                },
                enrouteDateTime: {
                    textAlign: "center",
                    display: "none",
                    minWidth: "10em"
                    // whiteSpace: "nowrap",
                },
                handoverDateTime: {
                    textAlign: "center",
                    display: "none",
                    // whiteSpace: "nowrap"
                    minWidth: "10em"
                },
                delay: {
                    textAlign: "center",
                    display: "none"
                },
                ptInClientLocation: {
                    textAlign: "center",
                    display: "none"
                },
                location: {
                    textAlign: "center",
                    display: "none"
                },
                assignAndOpenOrder: {
                    display: "none"
                },
                chatStatus: {
                    minWidth: "16em"
                },
                modificationDateTime: {},
                rejectionReason: {},
                timeBetweenCompleteOrderByInstallerAndForwardedToDispatcherBody: {},
                timeBetweenOplAssigmentAndForwardedToDispatcherBody: {},
                handledByUser: {}
            }
        };


        if (this.props.showAll) {
            for (const i in this.state.style) {
                delete this.state.style[i]["display"]; //https://stackoverflow.com/questions/5310304/remove-json-element
            }
        }


        // props.visible="sortField,serviceId";
        const visible = this.props.visible;
        if (visible) {
            const array = visible.split(",");
            for (const i in array) {
                delete this.state.style[array[i]]["display"]; //https://stackoverflow.com/questions/5310304/remove-json-element
            }
        }

        const hidden = this.props.hidden;
        if (hidden) {
            if (Array.isArray(hidden)) {
                for (let index in hidden) {
                    this.state.style[hidden[index]]["display"] = "none";
                }
            }
        }
    }

    installerFinishedWorkSuccess = (rowData, column) => {
        let finishedWork = _.get(rowData, "orderEventAggregatedData.finishedWork");
        let success = _.get(rowData, "orderEventAggregatedData.finishedWork.success");
        return (
            <span>{finishedWork && mapToFinishedWorkSuccess(success)} {success} </span>
        );
    }

    installerFinishedWorkDateTime = (rowData, column) => {
        let installerFinishedWorkDateTime = _.get(rowData, "orderEventAggregatedData.finishedWork.creationDateTime");
        return (
            <span>{installerFinishedWorkDateTime &&
                <DateTimeField
                    dateTimeString={installerFinishedWorkDateTime}/>}</span>
        );
    }

    enrouteDateTime = (rowData, column) => {
        let enrouteDateTime = _.get(rowData, "orderEventAggregatedData.enrouteDateTime");
        return (
            <span>{enrouteDateTime &&
                <DateTimeField
                    dateTimeString={enrouteDateTime}/>}</span>
        );
    }

    resignationDateTime = (rowData, column) => {
        let resignationDateTime = _.get(rowData, "orderEventAggregatedData.resignationDateTime");
        return (
            <span>{resignationDateTime &&
                <DateTimeField
                    dateTimeString={resignationDateTime}/>}</span>
        );
    }
    clientPostPoneDateTime = (rowData, column) => {
        let clientPostponeDateTime = _.get(rowData, "orderEventAggregatedData.clientPostponeDateTime");
        return (
            <span>{clientPostponeDateTime &&
                <DateTimeField
                    dateTimeString={clientPostponeDateTime}/>}</span>
        );
    }
    noResponseDateTime = (rowData, column) => {
        let noResponseDateTime = _.get(rowData, "orderEventAggregatedData.noResponseDateTime");
        return (
            <span>{noResponseDateTime &&
                <DateTimeField
                    dateTimeString={noResponseDateTime}/>}</span>
        );
    }
    waitingForClientDateTime = (rowData, column) => {
        let waitingForClientDateTime = _.get(rowData, "orderEventAggregatedData.waitingForClientDateTime");
        return (
            <span>{waitingForClientDateTime &&
                <DateTimeField
                    dateTimeString={waitingForClientDateTime}/>}</span>
        );
    }
    questionForContractDateTime = (rowData, column) => {
        let questionForTheContractDateTime = _.get(rowData, "orderEventAggregatedData.questionForTheContractDateTime");
        return (
            <span>{questionForTheContractDateTime &&
                <DateTimeField
                    dateTimeString={questionForTheContractDateTime}/>}</span>
        );
    }

    techPostPoneDateTime = (rowData, column) => {
        let techPosponeDateTime = _.get(rowData, "orderEventAggregatedData.techPosponeDateTime");
        return (
            <span>{techPosponeDateTime &&
                <DateTimeField
                    dateTimeString={techPosponeDateTime}/>}</span>
        );
    }

    delay = (rowData, column) => {
        let delay = _.get(rowData, "orderEventAggregatedData.delayInMinutes");
        delay = delay ? delay + "min" : "";
        return (
            <span>{delay}</span>

        );
    }

    ptInClientLocation = (rowData, column) => {
        let toShow;
        if (rowData[column.field]) {
            toShow = rowData[column.field].ptInClientLocation === true ? "W lokalu" : "Poza lokalem";
        } else {
            toShow = "Brak informacji";
        }
        return (
            <span>{toShow}</span>
        );
    }

    timeBetweenOrderCompletedAndOplAssigmentBody = (rowData, column) => {
        let time = _.get(rowData, "timeBetweenOrderCompletedAndOplAssigment");
        time = time || time === 0 ? time + " min" : null;
        return (
            <> {time} </>
        );
    }

    timeBetweenOplAssigmentAndForwardedToDispatcherBody = (rowData, column) => {
        let time = _.get(rowData, "timeBetweenOplAssigmentAndForwardedToDispatcher");
        time = time || time === 0 ? time + " min" : null;
        return (
            <> {time} </>
        );
    }

    timeBetweenCompleteOrderByInstallerAndForwardedToDispatcherBody = (rowData, column) => {
        let time1 = _.get(rowData, "timeBetweenOrderCompletedAndOplAssigment");
        let time2 = _.get(rowData, "timeBetweenOplAssigmentAndForwardedToDispatcher");
        let time = time1 + time2
        time = time || time === 0 ? time + " min" : null;
        return (
            <> {time} </>
        );
    }

    delta = (rowData, column) => {
        return (
            <> {this.getDelta(column.field, rowData)} </>
        );
    }


    getDelta(deltaName, rowData) {
        if (rowData && rowData.events && rowData.events.length > 0) {
            const obj = OrderEventUtil.getDeltas(rowData);
            const delta = obj[deltaName];
            return delta || delta === 0 ? `${delta} min` : null;
        }

    }


    componentDidMount() {
        this.scrollByTouch(this.props.dataTableId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.orders.length > 0 && prevProps.orders !== this.props.orders) {
            if (CurrentAccountService.areAllowedRolesInCurrentAccountRoles(CHAT_NOTIFICATION_ALLOWED_ROLES)) {
                ChatService.getNotificationInfo(this.props.orders.map(item => item.id), data => {
                    this.setState({
                        notificationMap: data.reduce((map, item) => ({...map, [item.orderId]: item}), {})
                    });
                }, error => ErrorService.logServerError(error));
            }
        }
    }

    generateLinkToOrder(rowData) {
        const id = rowData.id;
        if (this.props.itemLink) {
            return this.props.itemLink(rowData);
        } else if (this.props.historyMode) {
            return `${id}?history=true`;
        } else if (this.props.skipLastURLSegmentOfItem) {
            return `${id}`;
        }
        return `${window.location.pathname.split("/").pop()}/${id}`;

    }

    showError(summary, detail) {
        if (this.messages) this.messages.show({severity: 'error', summary: summary, detail: detail});
    }

    hasUnreadMessages(rowData) {
        const orderId = rowData.id;
        return !!this.state.notificationMap && !!this.state.notificationMap[orderId] ? this.state.notificationMap[orderId].showBadge : false;
    }

    chatWasOpenedByOpl(rowData) {
        return rowData.chatWasOpened;
    }

    assignUserSuccess = (rowData) => {
        this.setState({disabled: false});
        this.props.history.push(this.generateLinkToOrder(rowData));
    }

    assignUserError = (error) => {
        this.setState({disabled: false});
        ErrorService.logServerError(error);
        let errorMessage = ErrorService.getAtriumErrorMessage(error, ASSIGN_ORDER_ERROR_MESSAGE_DETAIL);
        this.showError(ERROR_MESSAGE_TITLE, errorMessage);
    }

    assignUser(rowData) {
        this.setState({disabled: true});
        if (this.props.assign) {
            this.props.assign(rowData.id, () => this.assignUserSuccess(rowData), this.assignUserError);
        } else {
            OrderService.assingOplUser(rowData.id, () => this.assignUserSuccess(rowData), this.assignUserError);
        }
    }

    unassignUser(orderId) {
        this.setState({disabled: true});
        if (this.props.unassign) {
            this.props.unassign(orderId, this.unassignUserSuccess, this.unassignUserError);
        } else {
            OrderService.unassignOplUser(orderId, this.unassignUserSuccess, this.unassignUserError);
        }

    }

    unassignUserSuccess = () => {
        this.setState({disabled: false});
        this.props.refreshTable();
    }

    unassignUserError = (error) => {
        this.setState({disabled: false});
        ErrorService.logServerError(error);
        let errorMessage = ErrorService.getAtriumErrorMessage(error, UN_ASSIGN_ORDER_ERROR_MESSAGE_DETAIL);
        this.showError(ERROR_MESSAGE_TITLE, errorMessage);
    }


    isOPLUserAssingedToOrder() {
        return this.props.order ? this.props.order.assignedOplUser : null;
    }

    shouldDisplayUnassignButton(rowData) {
        return CurrentAccountService.getId() === rowData.assignedOplUser.id || CurrentAccountService.hasRole(ASSIGNMENT_MANAGEMENT_ROLE_KEY);
    }

    render() {
        var orderCount = this.props.totalRecords ? this.props.totalRecords : 0;
        var header = <div className="p-clearfix" style={{'lineHeight': '1.87em'}}>
            {this.props.headerTitle}
            <Button icon="pi pi-search" style={{'float': 'right'}}
                    onClick={this.props.toogleFilter}/>

            <Button icon="pi pi-refresh" style={{'float': 'right', 'marginRight': '5px'}}
                    onClick={this.props.refreshTable ? this.props.refreshTable : () => {
                    }}/>
            <Button type="button" icon="pi pi-info" style={{'float': 'left', 'marginRight': '5px'}}
                    onClick={(e) => this.op.toggle(e)}/>
        </div>;
        var footer = `Liczba wszystkich zleceń: ${orderCount}`;


        return (


            <div className="">
                <Messages ref={(el) => this.messages = el}/>
                <DataTable id={this.props.dataTableId ? this.props.dataTableId : "orderTableId"}
                           ref={(el) => this.dt = el} responsive={false}
                    // frozenWidth="200px"
                    //  scrollable={false} scrollHeight="1000px"
                    // columnResizeMode="expand"
                           value={this.props.orders} paginator={true} rows={this.props.rows}
                           totalRecords={this.props.totalRecords}
                           lazy={true} first={this.props.first} loading={this.props.loading} autoLayout={true}
                           header={header} footer={footer} className="high-priority-table"
                           onPage={this.props.onPage} style={{textAlign: "center", display: "grid"}}
                           paginatorTemplate="PageLinks FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                           rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_SIZES}
                           sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                           onSort={(e) => {
                               this.setState({sortField: e.sortField, sortOrder: e.sortOrder});
                               const sortBy = `${e.sortField},${e.sortOrder === 1 ? "asc" : "desc"}`;
                               if (this.props.onSort) {
                                   this.props.onSort(sortBy);
                               }
                           }
                           }>
                    {this.props.showPriorityColumn ?
                        <Column field="isHighPriority" header="Priorytet" body={(rowData) => {
                            return rowData.isHighPriority ? <div className="high-priority" style={{
                                width: "100%",
                                height: "100%",
                                whiteSpace: "nowrap",
                                color: "red"
                            }}>WYSOKI</div> : "";
                        }}/>
                        :
                        null
                    }
                    <Column field="id" header="Id FM" style={{textAlign: "center"}}
                            filterMatchMode="custom" sortable={true}/>
                    <Column style={this.state.style.assignAndOpenOrder} field="id" header="Pobierz zlecenie"
                            body={(rowData) => {
                                if (!rowData.assignedOplUser) {
                                    return <div>
                                        <Button tooltip={"Pobierz zlecenie"} icon="pi pi-download"
                                                disabled={this.state.disabled || (this.props.assignAndOpenOrderBtnDisabled ? this.props.assignAndOpenOrderBtnDisabled(rowData) : rowData.assignedOplUser)}
                                                onClick={() => {
                                                    this.assignUser(rowData);
                                                }}/>
                                    </div>;
                                }
                                if (this.shouldDisplayUnassignButton(rowData)) {
                                    return <div>
                                        <Button tooltip={"Odpisz użytkownika od zlecenia"} icon="pi pi-times"
                                                className="p-button-danger"
                                                disabled={this.state.disabled}
                                                onClick={() => {
                                                    this.unassignUser(rowData.id);
                                                }}/>
                                    </div>;
                                }
                                return <div>
                                    <Button tooltip={"Pobierz zlecenie"} icon="pi pi-download" disabled={true}/>
                                </div>;


                            }}/>
                    <Column field="id" header="Podgląd zlecenia" body={(rowData) => {
                        return <div>
                            <Link icon="pi pi-eye" to={this.generateLinkToOrder(rowData)}>
                                <Button tooltip={"Pogląd zlecenia"} icon="pi pi-eye"/>
                            </Link>
                        </div>;
                    }}/>
                    <Column field="chat" style={this.state.style.chatStatus} header="Status czatu" body={(rowData) => {
                        return <div>
                            {!this.chatWasOpenedByOpl(rowData) &&
                                <span className={"chat-label not-opened-chat-label"}>Czat nie był otwierany</span>}
                            {!this.hasUnreadMessages(rowData) && <span
                                className={"chat-label no-unread-messages-chat-label"}>Brak nowych wiadomości</span>}
                            {this.hasUnreadMessages(rowData) && <span
                                className={"chat-label unread-messages-chat-label"}>Nieprzeczytane wiadomości</span>}
                        </div>;
                    }}/>
                    <Column sortField={"status"} field="status.verboseName" header="Status" sortable={true}/>
                    <Column sortField={"oplStatus"} field="oplStatus" header="Status DS" sortable={true}
                            body={(rowData, column) => {
                                return <>
                                    {OplStatusMapper.mapToVerboseName(rowData[column.field])}
                                </>;
                            }}
                            style={this.state.style.oplStatus}/>

                    <Column field="neoId" header="Id usługi" sortable={true}/>
                    <Column field="number" header="Numer zlecenia" style={{textAlign: "center"}} sortable={true}/>
                    <Column field="externalOrderId" header="Numer zamówienia" style={{textAlign: "center"}}
                            sortable={true}/>

                    <Column body={this.installerFinishedWorkSuccess}
                            header="Status zakończenia interwencji"
                            style={this.state.style.installerFinishedWorkSuccess}/>
                    <Column body={this.installerFinishedWorkDateTime}
                            header="Data zakończenie interwencji"
                            style={this.state.style.installerFinishedWorkDateTime}/>

                    <Column field="timeSlot" body={DateTimeTemplate} header="Data umówienia"
                            style={this.state.style.timeSlot} sortable={true}/>
                    <Column field="location" header="Adres" style={this.state.style.location} body={LocationBody}/>
                    <Column field="networkOwner.verboseName" header="Właściciel sieci"
                            style={this.state.style.location}/>
                    <Column field="serviceOperator.verboseName" header="Operator zamawiający"
                            style={this.state.style.location}/>
                    <Column sortField={"area"} field="area.verboseName" header="Strefa" style={{textAlign: "center"}}
                            sortable={true} body={AreaBody}/>

                    {/* https://git.chupacabra.tp.pl/photomontage/deadpool-backend/-/issues/38 */}
                    {/* <Column header="Firma" style={{ textAlign: "center" }}
                        body={CompanyTemplateFromArea} /> */}

                    <Column field="installers" header="Serwisant" style={{textAlign: "center"}} body={InstallerRow}/>
                    <Column field="assignedOplUser" header="Przypisany użytkownik" body={FirstLastNameLoginBody}/>
                    <Column field="handledByUser" header="Obsłużył w OPL" body={FirstLastNameLoginBody}
                            style={this.state.style.handledByUser}/>

                    <Column header="Czas oczekiwania na podjęcie zlecenia przez użytkownika OPL"
                            className="" style={{textAlign: "center"}}
                            body={this.timeBetweenOrderCompletedAndOplAssigmentBody}/>
                    <Column header="Czas od podjęcia zlecenia do przekazania dyspozytorowi"
                            style={{textAlign: "center"}}
                            body={this.timeBetweenOplAssigmentAndForwardedToDispatcherBody}/>
                    <Column header="Łączny czas obsługi zlecenia"
                            style={{textAlign: "center"}}
                            body={this.timeBetweenCompleteOrderByInstallerAndForwardedToDispatcherBody}/>

                    <Column field="technology.verboseName" header="Technologia" style={{textAlign: 'center'}}/>
                    <Column field="technologyDla.verboseName" header="Technologia DLA" style={{textAlign: 'center'}}
                            body={TechnologyDlaBody}/>
                    <Column field="service.verboseName" header="Usługa" style={{textAlign: "center"}}/>
                    <Column field="completionDateTime" body={DateTimeTemplate} header="Przekazano do OPL"
                            style={{textAlign: "center"}} sortable={true}/>
                    <Column field="modificationDateTime" body={DateTimeTemplate} header="Zmodyfikowano"
                            style={this.state.style.modificationDateTime} sortable={true}/>
                    <Column field="rejectionReason.verboseName" header="Powód odrzucenia zgłoszenia"
                            style={this.state.style.rejectionReason}/>
                    <Column body={this.enrouteDateTime} header="W drodze"
                            style={this.state.style.enrouteDateTime}/>
                    <Column field="installationStartDateTime" body={DateTimeTemplate} header="Start instalacji"
                            style={this.state.style.installationStartDateTime}/>
                    <Column body={this.delay} header="Opóźnienia"
                            style={this.state.style.delay}/>
                    <Column body={this.resignationDateTime} header="Rezygnacja"
                            style={this.state.style.resignationDateTime}/>
                    <Column body={this.clientPostPoneDateTime} header="Odroczenie z przyczyn klienta"
                            style={this.state.style.clientPostPoneDateTime}/>
                    <Column body={this.noResponseDateTime} header="Brak kontaktu z klientem"
                            style={this.state.style.noResponseDateTime}/>
                    <Column body={this.techPostPoneDateTime} header="Odroczenie z przyczyn technicznych"
                            style={this.state.style.techPostPoneDateTime}/>
                    <Column body={this.waitingForClientDateTime} header="Oczekiwanie na klienta"
                            style={this.state.style.waitingForClientDateTime}/>
                    <Column body={this.questionForContractDateTime} header="Pytanie do umowy"
                            style={this.state.style.questionForContractDateTime}/>
                    {/* <Column field="ptInClientLocation" body={this.ptInClientLocation}
                        header="Czy PT jest w lokalu Klienta?"
                        style={this.state.style.ptInClientLocation} /> */}

                </DataTable>
                <OverlayPanel ref={(el) => this.op = el}>
                    <div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <i className="pi pi-info-circle" style={{'fontSize': '3em', color: '#007ad9'}}/>
                            <b>Informacje:</b>
                        </div>
                        <ul>
                            <li>Możesz przesuwać tabelę trzymając wciśnięte lewy oraz prawy przycisk myszy</li>
                            <li>Możesz przesuwać tabelę trzymając wciśnięty środkowy przycisk myszy</li>
                            <li>Domyślnie wyświetlane są zlecenie z czasem modyfikacji
                                do {DEFAULT_MODIFICATION_DATE_TIME_FROM_IN_DAYS} dni.
                                Jeśli chcesz wyszukać zlecenia starsze
                                niż {DEFAULT_MODIFICATION_DATE_TIME_FROM_IN_DAYS} dni zmień datę w filtrze.
                            </li>
                        </ul>

                    </div>
                </OverlayPanel>
            </div>

        );
    }

    scrollByTouch(dataTableId) {
        // https://codepen.io/thenutz/pen/VwYeYEE
        // const slider = document.querySelector("#highprioritytable .p-datatable-wrapper");
        const slider = document.querySelector("#" + (dataTableId ? dataTableId : "orderTableId") + " .p-datatable-wrapper");
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener('mousedown', (e) => {

            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
            slider.style.cursor = "pointer";

            if (typeof e === 'object') {
                switch (e.button) {
                    case 0:
                        console.log('Left button clicked.');
                        break;
                    case 1:
                        console.log('Middle button clicked.');
                        isDown = true;
                        break;
                    case 2:
                        console.log('Right button clicked.');
                        break;
                    default:
                        console.log(`Unknown button code: ${e.button}`);
                }
            }

        });
        slider.addEventListener('mouseleave', () => {
            isDown = false;
        });
        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.style.cursor = "";
        });
        slider.addEventListener('mousemove', (e) => {
            if (!isDown) {
                return;
            }
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;

        });
    }

    createStickyHeader() {
        const header = document.querySelector('.p-datatable-thead');
        console.log(`ss${header.style}`);
        header.style.background = "white";

        var viewportOffset = header.getBoundingClientRect();
        // these are relative to the viewport, i.e. the window
        var top = viewportOffset.top;

        console.log(`TOP ${top}`);

        window.addEventListener("scroll", function () {

                var viewportOffset = header.getBoundingClientRect();
                // these are relative to the viewport, i.e. the window
                var top = viewportOffset.top;

                console.log(`TOP ${top}`);


                console.log(`scroll${window.scrollY}`);
                if (window.scrollY > 400) {
                    header.style.top = "0px";
                    header.style.position = "fixed";
                } else {
                    header.style.position = "";
                    header.style.top = "";

                }
            }
        );
    }

}

const OrderDataTable = withRouter(OrderDataTableWithRouter);
export default OrderDataTable;