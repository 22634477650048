import React from "react";
import {withRouter} from 'react-router-dom';
import '../../static/css/App.css';
import AddEditNews from "./AddEditNews";

class EditNews extends React.Component {



    render() {

        return (<AddEditNews editMode={true}/>);

    }
}

export default withRouter(EditNews);