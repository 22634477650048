import React, {Component} from 'react';
import {Dropdown} from "primereact/dropdown";
import {PtInClientLocation} from "../../config/constants";


class PtInClientLocationDropdown extends Component {


    state = {
        values: [
            {"name": PtInClientLocation.IN.name, "id":PtInClientLocation.IN.value},
            {"name": PtInClientLocation.OUT.name, "id":PtInClientLocation.OUT.value},
        ],
        value: null
    }

    componentDidMount() {
    }


    render() {

        return (
            <Dropdown id={this.props.id}
                showClear={typeof this.props.showClear !== 'undefined' ? this.props.showClear : true}
                className={this.props.className} optionLabel="name"
                style={this.props.style}
                value={this.props.value}
                options={this.state.values}
                dataKey={"id"}
                onChange={(event) => {
                    return this.props.onChange(event.value);
                }}/>

        );
    }
}

export default PtInClientLocationDropdown;
