import React from 'react';
import {Dropdown} from "primereact/dropdown";
import MonitoringSettingsService from "../services/MonitoringSettingsService";

class DealerSupportRagResponseDropdown extends React.Component {
    state = {
        items: [],
        item: {}
    };

    componentDidMount() {
        MonitoringSettingsService.read((items) => {
            const all = items.map(item => {
                return {"name": item.message, "key": item.id};
            });
            let item = {};
            if (all && all.length > 0) {
                item = all[0];
            }
            this.setState({items: all, item: item});
            this.props.onChange({value: item});
        }, (error) => {
            console.log(`MonitoringSettingsService read all rag responses ->error${JSON.stringify(error)}`);
        });
    }

    render() {
        return (<Dropdown showClear={false} className={this.props.className} optionLabel="name" value={this.state.item}
                          options={this.state.items}
                          onChange={(event) => {
                              this.setState({item: event.value});
                              return this.props.onChange(event);
                          }}/>);
    }
}

export default DealerSupportRagResponseDropdown;