import '../../static/css/App.css';
import React from 'react';
import { createParams, DateTimeTemplate, NewsIdTemplate } from "../../shared/OrderTableCommon";
import NewsService from "../../services/NewsService";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputTextarea } from "primereact/inputtextarea";
import { DEFAULT_ROWS_PER_PAGE_SIZES, DEFAULT_SORT_BY_FOR_ORDER } from "../../config/constants";
import { groupBody } from '../account/GroupBody';

class NewsList extends React.Component {

    state = {
        news: [],
        loading: false,
        first: 1,
        rows: 20, // Ilość wierszy na stronie
        totalRecords: 0,// Ilośc wszystkich elementów w bazie
        filters: {},
        area: {},
        sortBy: DEFAULT_SORT_BY_FOR_ORDER,
        filter: false

    }

    constructor(props) {
        super(props);
        this.onPage = this.onPage.bind(this);
    }

    componentDidMount() {
        this.getItems(this.state.filters, this.state.sortBy, 1, 0);
    }

    onPage(event) {
        this.setState({
            loading: true
        });
        const startIndex = event.first;
        const pageNumber = startIndex / event.rows + 1;
        this.setState({ pageNumber: pageNumber, startIndex: startIndex, rows: event.rows }, () => {
            this.getItems(this.state.filters, this.state.sortBy, this.state.pageNumber, this.state.startIndex);
        });
    }


    getItems(filters, sortBy, pageNumber, startIndex) {

        let params = createParams(filters, sortBy);
        NewsService.getNews(params, pageNumber, this.state.rows, (page) => {

            this.setState({
                items: page.content,
                totalRecords: page.totalElements,
                loading: false,
                first: startIndex,
            })
        },
            (error) => {
                this.setState({
                    loading: false
                });
                console.log("Error:" + error)
            });
    }

    sent = (rowData, column) => {
        return (
            <>
                {rowData[column.field] ? "Tak" : "Nie"}
            </>

        );
    }

    body = (rowData, column) => {
        return (<>
            <InputTextarea rows={4} cols={50} value={rowData[column.field]} autoResize={true}
                readOnly={true} />
        </>);
    }

    editBody = (rowData, column) => {
        return (
            <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>

                <Button disabled={rowData["enabled"]} icon="" label={"Edytuj"} style={{ 'float': 'center' }}
                    onClick={() => this.props.history.push("/news/edit/" + rowData["id"])} />
            </div>);
    }

    enableBody = (rowData, column) => {
        let id = rowData["id"];
        let enabled = rowData["enabled"];
        return (
            <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>

                <Button disabled={rowData["sent"]}
                    style={{ "marginLeft": "10px", "marginTop": "5px" }}
                    className="o-value" onClick={(event) => {
                        NewsService.enable(id,
                            (data) => {
                                this.getItems();
                            },
                            (error) => {
                                console.log("Error: " + error)
                            })

                    }} label={enabled?"Wyłącz":"Włącz"}></Button>

            </div>);
    }



    render() {


        var orderCount = this.state.totalRecords;

        var header =
            <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>

                <Button icon="pi pi-plus" style={{ 'float': 'right' }}
                    onClick={() => this.props.history.push("/news/add")} />
            </div>;
        var footer = "Liczba wszystkich aktualności: " + orderCount;


        return (
            <div>


                <DataTable ref={(el) => this.dt = el} responsive={false}
                    value={this.state.items} paginator={true} rows={this.state.rows}
                    totalRecords={this.state.totalRecords}
                    lazy={true} first={this.state.first} loading={this.state.loading} autoLayout={true}
                    header={header} footer={footer}
                    onPage={this.onPage} 
                    paginatorTemplate="PageLinks FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                    rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_SIZES}
                    sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                    onSort={(e) => {
                        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder });
                        let sortBy = e.sortField + "," + (e.sortOrder === 1 ? "asc" : "desc");
                        this.setState({ sortBy: sortBy }, () => {
                            this.getItems(this.state.filters, this.state.sortBy, 1, 0);
                        });

                    }
                    }>
                    <Column field="id" header="Id"  
                        filterMatchMode="custom" sortable={true} />
                    <Column field="title" header="Tytuł"  sortable={true} />
                    <Column field="body" header="Tekst wiadomości" body={this.body}
                        style={{ textAlign: "center", width: "10px" }} />
                    <Column field="sent" header="Wysłane"  body={this.sent} sortable={true} />

                    <Column field="startDateTime" body={DateTimeTemplate} header="Wyświetlane od"
                         sortable={true} />
                    <Column field="endDateTime" body={DateTimeTemplate} header="Wyświetlane do"
                         sortable={true} />
                    <Column field="creationDateTime" body={DateTimeTemplate} header="Utworzono"
                         sortable={true} />

                    <Column field="modificationDateTime" body={DateTimeTemplate} header="Data modyfikacji"
                         sortable={true} />
                    <Column field="groups" header="Grupy" body={groupBody}  />

                    <Column field="creator.login" header="Stworzone przez"  />
                    <Column field="lastEditor.login" header="Ostatnio edytował"  />
                    <Column field="sender.login" header="Wysłane przez"  />
                    <Column header="Edycja"  body={this.editBody} />
                    <Column header="Włącz/Wyłącz"  body={this.enableBody} />

                </DataTable>

            </div>

        );
    }


}

export default NewsList;