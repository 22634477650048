import React from "react";
import TechnologyService from "../../services/dict/TechnologyService";
import {MultiSelect} from "primereact/multiselect";
import AreaService from "../../services/dict/AreaService";

class AreaMultiSelect extends React.Component {
    state = {
        values: [],
        val: null
    }

    componentDidMount() {
        AreaService.getAll((values) => {
            this.setState({values: values});
        }, (error) => {
            console.log(`AreaMultiSelect error ${JSON.stringify(error)}`);
        });
    }

    render() {
        return (
            <MultiSelect showClear={true} className={this.props.className} optionLabel="verboseName"
                         value={this.props.value ? this.props.value : this.state.val} options={this.state.values}
                         filter={true} placeholder={this.props.placeholder} dataKey={"id"}
                         onChange={(event) => {
                             this.setState({"val": event.value});
                             if (this.props.onChange) {
                                 this.props.onChange(event);
                             }
                         }}/>
        );
    }
}

export default AreaMultiSelect;