import axios from "axios";
import ErrorService from "./ErrorService";
import _ from "lodash";

const CHAT_REF_KEY = 'chatRef';
const CHAT_SESSION_KEY = 'sessionId';

let chatRef;

const ChatService = {

    loggedInAndOpened() {
        const sessionId = localStorage.getItem(CHAT_SESSION_KEY);
        return !!sessionId && chatRef && !chatRef.closed;
    },

    buildChatUrl(data) {
        let url = data.chatUrl;

        if (data.sessionId) {
            url = `${url}/${data.sessionId}`;
            localStorage.setItem(CHAT_SESSION_KEY, data.sessionId);
        }

        return `${url}/${data.channelName}`;
    },

    openChat(url) {
        chatRef = window.open(url, CHAT_REF_KEY);
    },

    chat(id, onError, onSuccess) {
        return ChatService.createChat(id, data => {
            if (onSuccess) {
                onSuccess();
            }
            ChatService.openChat(ChatService.buildChatUrl(data));
        }, errorResponse => {
                ErrorService.logServerError(errorResponse);
                let dataOrNull = _.get(errorResponse, "response.data", null);
                onError(dataOrNull);
            }
        );
    },

    hideBadge(notificationMap, orderId) {
        notificationMap[orderId] = {
            ...notificationMap[orderId],
            showBadge: false
        };

        return notificationMap;
    },

    /* server */

    createChat(id, onSuccess, onError) {
        let renewParameters = {};

        if (!this.loggedInAndOpened()) {
            renewParameters = {
                sessionId: localStorage.getItem(CHAT_SESSION_KEY),
                checkSession: true,
            };
        } else {
            renewParameters = {
                checkSession: false,
            };
        }

        return axios.post(`/ocl/chat/channel/${id}`, renewParameters).then(function (response) {
            if (response.status === 200) {
                onSuccess(response.data);
            } else {
                const error = `Incorrect response status ${response.status}`;
                onError(error);
            }
        }).catch(function (error) {
            onError(error);
        });
    },

    getNotificationInfo(ids, onSuccess, onError) {
        return axios.get("/chat/notification", {
            params: {
                orderIds: ids
            }
        }).then(function (response) {
            if (response.status === 200) {
                onSuccess(response.data);
            } else {
                const error = `Incorrect response status ${response.status}`;
                onError(error);
            }
        }).catch(function (error) {
            onError(error);
        });
    },

    getPushNotifications(onSuccess, onError) {
        return axios.get("/chat/notification/push").then(function (response) {
            if (response.status === 200) {
                onSuccess(response.data);
            } else {
                const error = `Incorrect response status ${response.status}`;
                onError(error);
            }
        }).catch(function (error) {
            onError(error);
        });
    }

};

export default ChatService;
