import axios from 'axios';
import {SERVER_API_URL} from './constants';
import AuthService from "../services/AuthService";

var qs = require('qs');

const TIMEOUT = 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;
axios.interceptors.response.use(null, function (error) {
    const status = error.status || error.response.status;
    if (status === 401) {
        console.log("401 from server. Please log in!");
        AuthService.clearLocalStorageAndForwardToLogin(window);
    }
    return Promise.reject(error);
});

axios.defaults.paramsSerializer = params => {
    return qs.stringify(params, {indices: false});
};
