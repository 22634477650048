export const testOrder = {
    "area": {
        "id": 1,
        "name": "WAW",
        "type": {
            "id": 1,
            "name": "Centrum",
            "verboseName": "Centrum"
        },
        "verboseName": "Warszawa"
    },
    "comment": "Archived",
    "contractStatus": {
        "id": 1,
        "name": "CUSTOMER_HAS_CONTRACT",
        "verboseName": "Klient ma umowę"
    },
    "creationDateTime": "2019-06-14T10:13:05.463+02:00",
    "delays": [{
        "delayInMinutes": 13
    }],
    "events": [{
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "creationDate": "2019-06-14T10:13:05.475+02:00",
        "id": 1672,
        "status": {
            "id": 27,
            "name": "CREATED",
            "verboseName": "Stworzone"
        },
        "type": "STATUS_CHANGE"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "comment": "Jestem w drodze do klienta.",
        "creationDate": "2019-06-14T10:13:05.581+02:00",
        "id": 1673,
        "installer": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1",
            "phoneNumber": "50550550"
        },
        "status": {
            "id": 8,
            "name": "PT_ENROUTE",
            "verboseName": "W drodze"
        },
        "type": "STATUS_CHANGE"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "comment": "Niestety klient zrezygnował.",
        "creationDate": "2019-06-14T10:13:05.633+02:00",
        "id": 1674,
        "installer": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1",
            "phoneNumber": "50550550"
        },
        "status": {
            "id": 4,
            "name": "PT_RESIGNATION",
            "verboseName": "Rezygnacja klienta"
        },
        "type": "STATUS_CHANGE"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "comment": "Niestety przez korki na drodze wystąpiło opóźnienie.",
        "creationDate": "2019-06-14T10:13:05.682+02:00",
        "id": 1675,
        "installer": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1",
            "phoneNumber": "50550550"
        },
        "status": {
            "id": 3,
            "name": "PT_LATE",
            "verboseName": "Spóźnienie"
        },
        "type": "STATUS_CHANGE"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "comment": "Brak odpowiedzi od klienta.",
        "creationDate": "2019-06-14T10:13:05.765+02:00",
        "id": 1676,
        "installer": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1",
            "phoneNumber": "50550550"
        },
        "status": {
            "id": 6,
            "name": "PT_NO_RESPONSE",
            "verboseName": "Brak odpowiedzi"
        },
        "type": "STATUS_CHANGE"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "comment": "Czekam na klienta.",
        "creationDate": "2019-06-14T10:13:05.825+02:00",
        "id": 1677,
        "installer": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1",
            "phoneNumber": "50550550"
        },
        "status": {
            "id": 11,
            "name": "PT_WAITING_FOR_CLI",
            "verboseName": "Oczekiwanie na klienta"
        },
        "type": "STATUS_CHANGE"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "comment": "Niestety nie jestem w stanie kontynuować zlecenia. Proszę o przekazanie zlecenia innej ekipie.",
        "creationDate": "2019-06-14T10:13:05.878+02:00",
        "id": 1678,
        "installer": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1",
            "phoneNumber": "50550550"
        },
        "status": {
            "id": 7,
            "name": "PT_HANDOVER",
            "verboseName": "Przekazanie zlecenia"
        },
        "type": "STATUS_CHANGE"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "comment": "Zlecenie zostało zawieszone.",
        "creationDate": "2019-06-14T10:13:05.905+02:00",
        "id": 1679,
        "status": {
            "id": 29,
            "name": "SUSPENDED",
            "verboseName": "Zawieszone"
        },
        "type": "STATUS_CHANGE"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "comment": "Niestety nie jestem w stanie kontynuować zlecenia. Proszę o przekazanie zlecenia innej ekipie.",
        "creationDate": "2019-06-14T10:13:05.962+02:00",
        "id": 1680,
        "installer": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1",
            "phoneNumber": "50550550"
        },
        "status": {
            "id": 9,
            "name": "PT_TECH_POSTPONED",
            "verboseName": "Przełożone z przyczyn tecznicznych"
        },
        "type": "STATUS_CHANGE"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "creationDate": "2019-06-14T10:13:05.988+02:00",
        "id": 1681,
        "type": "OPL_ASSIGNED_TO_ORDER"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "comment": "Zlecenie zostało zawieszone.",
        "creationDate": "2019-06-14T10:13:06.036+02:00",
        "id": 1682,
        "status": {
            "id": 29,
            "name": "SUSPENDED",
            "verboseName": "Zawieszone"
        },
        "type": "STATUS_CHANGE"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "comment": "Klient ma pytanie o umowę. Podobno umowa miała trwać tylko 1 rok?",
        "creationDate": "2019-06-14T10:13:06.087+02:00",
        "id": 1683,
        "installer": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1",
            "phoneNumber": "50550550"
        },
        "status": {
            "id": 31,
            "name": "PT_QUESTIONS_FOR_THE_CONTRACT",
            "verboseName": "Zapytanie do umowy"
        },
        "type": "STATUS_CHANGE"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "creationDate": "2019-06-14T10:13:06.115+02:00",
        "id": 1684,
        "type": "OPL_ASSIGNED_TO_ORDER"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "comment": "Sprawa została rozwiązana. Proszę o kontynuację zlecenia.",
        "creationDate": "2019-06-14T10:13:06.152+02:00",
        "id": 1685,
        "status": {
            "id": 30,
            "name": "RESUMED",
            "verboseName": "Wznowione i przekazane do serwisanta"
        },
        "type": "STATUS_CHANGE"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "comment": "Z drobnymi przygodami rozpocząłem instalację.",
        "creationDate": "2019-06-14T10:13:06.226+02:00",
        "id": 1686,
        "installer": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1",
            "phoneNumber": "50550550"
        },
        "status": {
            "id": 10,
            "name": "PT_START_INSTALLATION",
            "verboseName": "Start instalacji"
        },
        "type": "STATUS_CHANGE"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "creationDate": "2019-06-14T10:13:06.330+02:00",
        "id": 1687,
        "type": "WIFI_ANALYZE_ADDED"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "comment": "Wypełnione urządzenia.",
        "creationDate": "2019-06-14T10:13:06.393+02:00",
        "id": 1688,
        "installer": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1",
            "phoneNumber": "50550550"
        },
        "status": {
            "id": 17,
            "name": "SELL_CPE_NEW",
            "verboseName": "Sprzedaż modemu"
        },
        "type": "COMPLETED_BY_INSTALLER"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "creationDate": "2019-06-14T10:13:06.482+02:00",
        "id": 1689,
        "type": "OPL_ASSIGNED_TO_ORDER"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "comment": "handleSellCPERejected",
        "creationDate": "2019-06-14T10:13:06.531+02:00",
        "id": 1690,
        "status": {
            "id": 23,
            "name": "SELL_CPE_REJECTED",
            "verboseName": "Odrzucone z kolejki sprzedaż modemu"
        },
        "type": "STATUS_CHANGE"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "comment": "Wypełnione urządzenia.",
        "creationDate": "2019-06-14T10:13:06.571+02:00",
        "id": 1691,
        "installer": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1",
            "phoneNumber": "50550550"
        },
        "status": {
            "id": 17,
            "name": "SELL_CPE_NEW",
            "verboseName": "Sprzedaż modemu"
        },
        "type": "COMPLETED_BY_INSTALLER"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "creationDate": "2019-06-14T10:23:06.647+02:00",
        "id": 1692,
        "type": "OPL_ASSIGNED_TO_ORDER"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "creationDate": "2019-06-14T10:13:06.700+02:00",
        "id": 1693,
        "installer": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1",
            "phoneNumber": "50550550"
        },
        "success": true,
        "type": "WORK_FINISHED_BY_INSTALLER"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "comment": "to disptacher",
        "creationDate": "2019-06-14T10:43:06.725+02:00",
        "id": 1694,
        "status": {
            "id": 2,
            "name": "DISPATCHER",
            "verboseName": "Przelazane do dyspozytora"
        },
        "type": "STATUS_CHANGE"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "comment": "Accepetd",
        "creationDate": "2019-06-14T10:13:06.762+02:00",
        "id": 1695,
        "status": {
            "id": 33,
            "name": "DISPATCHER_ACCEPTED",
            "verboseName": "Zaakceptowane przez dyspozytora"
        },
        "type": "STATUS_CHANGE"
    }, {
        "account": {
            "firstName": "Franek",
            "id": 8,
            "lastName": "Serwisant1",
            "login": "installer1"
        },
        "comment": "Archived",
        "creationDate": "2019-06-14T10:13:06.788+02:00",
        "id": 1696,
        "status": {
            "id": 1,
            "name": "ARCHIVE",
            "verboseName": "Zarchiwizowane"
        },
        "type": "STATUS_CHANGE"
    }],
    "id": 137,
    "installationStartDateTime": "2019-06-14T10:13:06.214+02:00",
    "installers": [{
        "company": {
            "id": 1,
            "name": "ELTEL",
            "verboseName": "ElTel"
        },
        "firstName": "Franek",
        "id": 8,
        "lastName": "Serwisant1",
        "login": "installer1",
        "phoneNumber": "50550550"
    }],
    "modificationDateTime": "2019-06-14T10:13:06.774+02:00",
    "neoId": "neoid_15_6_1936925505",
    "number": "number_15_6_1936925505",
    "orderDevices": [{
        "device": {
            "id": 709,
            "model": {
                "id": 1,
                "name": "WIFIBOX",
                "verboseName": "WiFi Box"
            },
            "sn": "SN_1662940988",
            "status": {
                "id": 1,
                "name": "INSTALLED",
                "verboseName": "Zainstalowane"
            }
        },
        "placeInstallation": {
            "id": 1,
            "name": "IN",
            "verboseName": "IN"
        }
    }],
    "service": {
        "id": 1,
        "name": "S_1P",
        "verboseName": "S_1P"
    },
    "status": {
        "id": 1,
        "name": "ARCHIVE",
        "verboseName": "Zarchiwizowane"
    },
    "technology": {
        "id": 6,
        "name": "MOCA",
        "verboseName": "MOCA"
    },
    "timeSlot": "2019-06-15T10:13:05.449+02:00"
};
