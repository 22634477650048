import '../../static/css/App.css';
import React from 'react';
import TechnologyService from "../../services/dict/TechnologyService";
import Dictionary from "./Dictionary";

class Technology extends React.Component {

    render() {
        return (
            <Dictionary title="Typy technologii"
                fetchValues={(onSuccess, onError) => {
                    TechnologyService.getAll((data) => {
                        onSuccess(data);
                    },
                    (error) => {
                        onError(error);
                    });
                }} />
        );
    }

}

export default Technology;

