import RestService from "../RestService";

const PATH = "/technology";

const TechnologyService = {

    update(data, onSuccess, onError) {
        RestService.update(`${PATH}/${data["id"]}`, data, onSuccess, onError);
    },

    getAll(onSuccess, onError) {
        return RestService.get(PATH, onSuccess, onError);
    },

    updateDla(data, onSuccess, onError) {
        RestService.update(`${PATH}/dla/${data["id"]}`, data, onSuccess, onError);
    },

    getAllDla(onSuccess, onError) {
        return RestService.get(`${PATH}/dla`, onSuccess, onError);
    },

};

export default TechnologyService;

