import React from 'react';
import { DEFAULT_MODIFICATION_DATE_TIME_FROM_IN_DAYS, DEFAULT_PAGE_SIZE, DEFAULT_SORT_BY_FOR_ORDER, QueueType } from "../../../config/constants";
import BotOkiPreviewService from "../../../services/BotOkiPreviewService";
import OrderDataTable from "../../../shared/orderDataTable/OrderDataTable";
import OrderFilterPanel, { defaultOnFilterChange } from "../../../shared/OrderFilterPanel";
import { createParams } from "../../../shared/OrderTableCommon";
import {nowMinusDays, filtersNotEmptySkipModificationDateTimeFrom} from '../../../shared/Utils';
import '../../../static/css/App.css';
import OrderFilterLoadService from "../../../shared/OrderFilterLoadService";

class BotOkiPreviewHistoryList extends React.Component {

    state = {
        orders: [],
        loading: false,
        first: 1,
        rows: DEFAULT_PAGE_SIZE, // Ilość wierszy na stronie
        totalRecords: 0,// Ilośc wszystkich elementów w bazie
        filters: {modificationDateTimeFrom:nowMinusDays(DEFAULT_MODIFICATION_DATE_TIME_FROM_IN_DAYS)},
        area: {},
        sortBy: DEFAULT_SORT_BY_FOR_ORDER,
        filter: false

    }

    constructor(props) {
        super(props);
        this.onPage = this.onPage.bind(this);
    }

    componentDidMount() {
        this.setState({ filters: new OrderFilterLoadService().getFilter(QueueType.HISTORY), loading: true },()=>{
            this.getOrders(this.state.filters, this.state.sortBy, 1, 0);

            this.setState({
                filter:filtersNotEmptySkipModificationDateTimeFrom(this.state.filters)
            });

        });

    }

    onPage(event) {
        this.setState({
            loading: true
        });
        const startIndex = event.first;
        const pageNumber = startIndex / event.rows + 1;
        this.setState({ pageNumber: pageNumber, startIndex: startIndex, rows: event.rows }, () => {
            this.getOrders(this.state.filters, this.state.sortBy, this.state.pageNumber, this.state.startIndex);
        });    }



    getOrders(filters, sortBy, pageNumber, startIndex) {

        let params = createParams(filters, sortBy);
        BotOkiPreviewService.getHistoryOrders(params, pageNumber, this.state.rows, (page) => {
                this.setState({
                    orders: page.content,
                    totalRecords: page.totalElements,
                    loading: false,
                    first: startIndex,
                })
            },
            (error) => {
                this.setState({
                    loading: false
                });
                console.log("Error:" + error)
            });
    }


    render() {

        return (
            <div>
                {<OrderFilterPanel
                    // visible="sortField,serviceId"
                    onFilterChange={defaultOnFilterChange(this)}
                    onCleanButton={(filters)=>{
                        new OrderFilterLoadService().resetFilter((filters) => {
                            this.setState({filters:filters},()=>{
                                this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                            });
                        },QueueType.HISTORY)
                    }}
                    onFilter={() => {
                        this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                    }}
                    filters={this.state.filters} filter={this.state.filter}
                    onSortFieldChange={(val, property) => {
                        this.setState({[property]: val});
                    }}
                    defaultModificationDateTimeFrom={DEFAULT_MODIFICATION_DATE_TIME_FROM_IN_DAYS}
                />}


                <OrderDataTable showAll={false} visible={"location"}
                                totalRecords={this.state.totalRecords} toogleFilter={() => this.setState({filter: !this.state.filter})}
                                headerTitle={"Historia fotomontaży"} orders={this.state.orders} rows={this.state.rows}
                                first={this.state.first} loading={this.state.loading} onPage={this.onPage}
                                refreshTable={() => { this.getOrders(this.state.filters, this.state.sortBy, 1, 0); }}
                                historyMode={true} openChatWithoutAssignment={true}
                                onSort={(sortBy) => {
                                    this.setState({sortBy: sortBy});
                                    this.getOrders(this.state.filters, sortBy, 1, 0);
                                }}
                />
            </div>

        );
    }


}

export default BotOkiPreviewHistoryList;

