import '../../static/css/App.css';
import axios from "axios";
import React from 'react';


const User = ({user}) => {
    return (
        <tr>
            <td>{user.id}</td>
            <td>{user.username}</td>
            <td>{user.email}</td>
        </tr>
    );
};

class UserList extends React.Component {


    state = {

        users: [],
        joke: null
    }


    componentDidMount() {


        axios.get('http://localhost:8080/test/users')
            .then((response) => {
                localStorage.setItem('user', JSON.stringify(response.data));
                console.log(`users${response.status}`);
                if (response.status === 200 && Array.isArray(response.data)) {
                    console.log(`TUTAJ${response.data}`);
                    this.setState({users: response.data});
                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
            });


        const url = `https://sv443.net/jokeapi/v2/joke/Any`;
        axios.get(url)
            .then((response) => {
                console.log("Joke");
                this.setState({
                    joke: response.data
                });
            })
            .catch(() => {
                this.setState({
                    error: true
                });
            });
    }


    log = () => {
        this.setState({users: []});
        console.log(JSON.stringify(this.state));
    }


    render() {


        return (
            <div className="App">

                Joke={JSON.stringify(this.state.joke)}

                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Item Name</th>
                            <th>Item Price</th>
                        </tr>
                    </thead>

                    <tbody>

                        {this.state.users.map((user) => {

                            return <User key={user.id} user={user}/>;
                        })}


                    </tbody>
                </table>

                <button onClick={this.log}> LOGGG</button>

            </div>

        );
    }
}

export default UserList;
