import '../../static/css/App.css';
import React from 'react';
import OrderTypeService from "../../services/dict/OrderTypeService";
import Dictionary from "./Dictionary";

class OrderType extends React.Component {

    render() {
        return (
            <Dictionary
                title="Typy zleceń"
                fetchValues={(onSuccess, onError) => {
                    OrderTypeService.getAll((data) => {
                        onSuccess(data);
                    },
                    (error) => {
                        onError(error);
                    });
                }} />
        );
    }

}

export default OrderType;

