import React from 'react';

import {withRouter} from "react-router";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {InputTextarea} from "primereact/inputtextarea";
import {DateTimeField} from "../../shared/Utils";

class OPLNotesTableWithRouter extends React.Component {


    state = {}

    getDatasource() {
        if (this.props.dataSource) {
            let events = this.props.dataSource();
            if (events) {
                events = events.filter((element) => {
                    return element['type'] === 'OPL_NOTE_ADDED_EVENT';
                });
                events= events.sort((a,b)=> {
                    return new Date(b.creationDate) - new Date(a.creationDate);
                });
            }
            return events;
        }
        return [];
    }

    comment = (rowData, column) => {
        return (
            <InputTextarea className="o-value" readOnly={true} rows={4} cols={30} value={rowData[column.field]} style={{'width':'100%'}} autoResize={true}/>
        );
    }
    dateTime = (rowData, column) => {
        return (
            <>
                {rowData[column.field] && <DateTimeField dateTimeString={rowData[column.field]}/>}
            </>
        );
    }


    render() {


        var header =
            <div className="p-clearfix" style={{'lineHeight': '1.87em'}}>
                Dane dostępowe
                <Button icon="pi pi-search" style={{'float': 'right'}}
                    onClick={() => {
                        if (this.props.toogleFilter) {
                            this.props.toogleFilter();
                        }
                    }}/>
            </div>;

        return (

            <>
                {this.getDatasource() && this.getDatasource().length>0 && <DataTable className={this.props.dataTableClassName} ref={(el) => this.dt = el}
                    value={this.getDatasource()} rows={this.props.rows}

                    autoLayout={true}
                    header={this.props.showHeader ? header : null}
                    paginator={this.props.paginator} lazy={this.props.lazy}
                    onPage={this.props.onPage}
                    first={this.props.first} totalRecords={this.props.totalRecords}
                    paginatorTemplate="RowsPerPageDropdown PageLinks FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink">


                    {/*<Column field="id" header="Id" body={AIdTemplate}/>*/}
                    <Column field="comment" header="Komentarz" body={this.comment} style={{'width':'100%'}}/>
                    <Column field="creationDate" body={this.dateTime} header="Data i czas wykonania operacji"/>
                    <Column field="account.login" header="Operacja wykonana przez"/>


                </DataTable>
                }
            </>
        );
    }
}

const OPLNotesTable = withRouter(OPLNotesTableWithRouter);
export default OPLNotesTable;
