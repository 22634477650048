import axios from "axios/index";
import ErrorService from "./ErrorService";


function is2XX(response) {
    return Math.floor(response.status / 100) === 2;
}

const RestService = {

    create(url, data, onSuccess, onError) {

        axios.post(url, data)
            .then(function (response) {
                console.log("RestService->create");
                if (response.status === 201) {
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                } else {
                    console.log(`RestService->create: incorrect status= ${response.status}`);
                    if (onError) {
                        onError();
                    }
                }
            })
            .catch(function (error) {
                console.log(`RestService->create: error${error}`);
                ErrorService.logServerError(error);
                if (onError) {
                    onError(error);
                }
            });

    },
    post(url, data, onSuccess, onError) {

        axios.post(url, data)
            .then(function (response) {
                console.log("RestService->post");
                if (is2XX(response)) {
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                } else {
                    console.log(`RestService->post: incorrect status= ${response.status}`);
                    if (onError) {
                        onError();
                    }
                }
            })
            .catch(function (error) {
                console.log(`RestService->post: error${error}`);
                ErrorService.logServerError(error);
                if (onError) {
                    onError(error);
                }
            });

    },

    update(url, data, onSuccess, onError) {

        axios.put(url, data)
            .then(function (response) {
                console.log("RestService->update");
                if (is2XX(response)) {
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                } else {
                    console.log(`RestService->update: incorrect status= ${response.status}`);
                    if (onError) {
                        onError();
                    }
                }
            })
            .catch(function (error) {
                console.log(`RestService->update: error${error}`);
                ErrorService.logServerError(error);
                if (onError) {
                    onError(error);
                }
            });

    },

    put(url,onSuccess, onError) {

        axios.put(url)
            .then(function (response) {
                console.log("RestService->put");
                if (is2XX(response)) {
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                } else {
                    console.log(`RestService->put: incorrect status= ${response.status}`);
                    if (onError) {
                        onError();
                    }
                }
            })
            .catch(function (error) {
                console.log(`RestService->put: error${error}`);
                ErrorService.logServerError(error);
                if (onError) {
                    onError(error);
                }
            });

    },
    putWithParams(url,params,onSuccess, onError) {
        if (!params) {
            params = {};
        }
        axios.put(url,{
            params: params
        })
            .then(function (response) {
                console.log("RestService->put");
                if (is2XX(response)) {
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                } else {
                    console.log(`RestService->put: incorrect status= ${response.status}`);
                    if (onError) {
                        onError();
                    }
                }
            })
            .catch(function (error) {
                console.log(`RestService->put: error${error}`);
                ErrorService.logServerError(error);
                if (onError) {
                    onError(error);
                }
            });

    },
    delete(url, onSuccess, onError) {

        axios.delete(url)
            .then(function (response) {
                console.log("RestService->delete");
                if (is2XX(response)) {
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                } else {
                    console.log(`RestService->delete: incorrect status= ${response.status}`);
                    if (onError) {
                        onError();
                    }
                }
            })
            .catch(function (error) {
                console.log(`RestService->delete: error${error}`);
                ErrorService.logServerError(error);
                if (onError) {
                    onError(error);
                }
            });

    },
    get(url, onSuccess, onError) {
        return axios.get(url)
            .then(function (response) {
                if (response.status === 200) {
                    onSuccess(response.data);
                } else {
                    console.log(`RestService->get: incorrect status= ${response.status}`);
                    const error = `Incorrect response status ${response.status}`;
                    if (onError) {
                        onError(error);
                    }
                }

            })
            .catch(function (error) {
                if (onError) {
                    onError(error);
                }
            });
    },

    getWithParams(url, params, onSuccess, onError) {
        if (!params) {
            params = {};
        }
        return axios.get(url, {
            params: params
        })
            .then(function (response) {
                // console.log("RestService->get");
                if (is2XX(response)) {
                    onSuccess(response.data);
                } else {
                    console.log(`RestService->get: incorrect status= ${response.status}`);
                    const error = `Incorrect response status ${response.status}`;
                    if (onError) {
                        onError(error);
                    }
                }

            })
            .catch(function (error) {
                if (onError) {
                    onError(error);
                }
            });
    },

    getWithPagination(url, params, pageNumber, pageSize, onSuccess, onError) {

        if (!params) {
            params = {};
        }
        params["page"] = pageNumber;
        params["size"] = pageSize;

        return axios.get(url, {
            params: params
        })
            .then(function (response) {
                console.log("RestService->getWithPagination");
                if (response.status === 200) {
                    onSuccess(response.data);
                } else {
                    console.log(`RestService->getWithPagination: incorrect status= ${response.status}`);
                    const error = `Incorrect response status ${response.status}`;
                    if (onError) {
                        onError(error);
                    }
                }

            })
            .catch(function (error) {
                if (onError) {
                    onError(error);
                }
            });
    },

    heartbeat(url) {

        return axios.get(url)
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Sending heartbeat to server");
                } else {
                    console.log(`Error in sending heartbeat: ${response.status}`);
                }

            })
            .catch(function (error) {
                console.log(`Error in sending heartbeat: ${error}`);
            });
    },


};

export default RestService;

