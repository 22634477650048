import '../../static/css/App.css';
import React from 'react';
import Dictionary from "./Dictionary";
import ContractStatusService from "../../services/dict/ContractStatusService";


class ContractStatus extends React.Component {

    render() {

        return (

            <Dictionary title="Statusy umowy"
                fetchValues={(onSuccess, onError) => {
                    ContractStatusService.getAll((data) => {
                        onSuccess(data);
                    },
                    (error) => {
                        onError(error);
                    });
                }}
                update={(data, onSuccess) => {
                    ContractStatusService.update(data, onSuccess);
                }}/>

        );
    }

}

export default ContractStatus;

