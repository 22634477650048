import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputTextarea } from "primereact/inputtextarea";
import React from 'react';
import OrderEventUtil from "../../services/OrderEventUtil";
import {AIdTemplate, InstallerOrAccountBody} from "../../shared/OrderTableCommon";
import { DateTimeField } from "../../shared/Utils";
import '../../static/css/App.css';
import OplStatusMapper from "../../shared/OplStatusMapper";
import EventStateMapper from "../../shared/EventStateMapper";
import InterventionStatusMapper from "../../shared/InterventionStatusMapper";
import _ from "lodash";

class OrderEventTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

    }
    eventType = (rowData, column) => {
        return (
            <span>{OrderEventUtil.getVerboseNameOfOrderType(rowData[column.field])}</span>

        );
    }
    otherInfo = (rowData) => {
        return (
            <>
                <span>
                    {rowData["type"] === "INSTALLER_REMOVED_FROM_ORDER_EVENT" ?
                        rowData["installer"] ?
                            `Usunięty serwisant(Installer): ${rowData["installer"]["identifier"]}`
                            : null : null}

                    {rowData["type"] === "INSTALLER_ADDED_TO_ORDER_EVENT" ?
                        rowData["installer"] ?
                            `Dodany serwisant(Installer): ${rowData["installer"]["identifier"]}`
                            : null : null}

                    {rowData["type"] === "OPL_STATUS_CHANGE_EVENT" ?
                        `Status DS: ${OplStatusMapper.mapToVerboseName(rowData.oplStatus)}` : null}

                    {rowData["type"] === "TIMESLOT_CHANGE_EVENT" ?
                        <>
                            {rowData["oldTimeSlot"] ?
                                <>  {"Zmieniona data umówienia: "}
                                    {<DateTimeField dateTimeString={rowData["oldTimeSlot"]} />}
                                </>
                                : null
                            }
                        </>
                        : null}

                    {rowData["type"] === "ORDER_ONTPP" ?
                        rowData["ontppMappingStatus"] ?
                            `Status mapowania z ONTP&P: ${rowData["ontppMappingStatus"]["verboseName"]}`
                            : null : null}

                    {rowData["type"] === "OPL_ASSIGNED_TO_ORDER" || rowData["type"] === "OPL_UNASSIGNED_TO_ORDER"
                    || rowData["type"] === "AUTOMATIC_OPL_UNASSIGN_FROM_ORDER_EVENT"
                    || rowData["type"] === "ORDER_REJECTED_EVENT"
                    || rowData["type"] === "AUTOMATIC_BOT_UNASSIGN_FROM_ORDER_EVENT" ?
                        <>
                            {"Status Atrium: " + (rowData['atriumStatus'] ? rowData['atriumStatus'] : "Nieznany") + " opis: " + (rowData['atriumDescription'] ? rowData['atriumDescription'] : "Brak")}
                        </> : null}


                    {rowData["type"] === "WORK_FINISHED_BY_INSTALLER" ?
                        <>
                            {"Status zakończenia interwencji: " + InterventionStatusMapper.mapToVerboseName(rowData["success"])  }
                        </> : null}

                        {rowData["type"] === "PT_LATE" ?
                        <>
                            <div>{"Opóźnienie: " + _.get(rowData,"delayInMinutes")+"min"} </div>
                            {"Instalator: " + _.get(rowData,"installer.identifier")}
                        </> : null}

                        {rowData["type"] === "STATUS_CHANGE" ?
                        <>
                            {"Status Atrium: " + rowData['atriumStatus'] ? rowData['atriumStatus'] : "Nieznany" + " opis: " + rowData['atriumDescription'] ? rowData['atriumDescription'] : "Brak"}
                        </> : null}
                        
                </span>

            </>
        );
    }


    comment = (rowData, column) => {
        return (
            <span className="o-value">
                <InputTextarea readOnly={true} rows={4} cols={30} value={rowData[column.field]} autoResize={false} />
            </span>

        );
    }
    dateTime = (rowData, column) => {
        return (
            <div>
                {rowData[column.field] && <DateTimeField dateTimeString={rowData[column.field]} />}
            </div>
        );
    }

    render() {

        return (

            <div className="">
                {/*<div className="p-grid  p-col-12 o-label">*/}
                <DataTable ref={(el) => this.dt = el}
                    value={this.props.events} rows={this.state.rows}

                    first={this.state.first} autoLayout={true}>

                    <Column field="id" header="Id" body={AIdTemplate} />
                    <Column field="type" header="Typ" body={this.eventType} />
                    <Column field="creationDate" body={this.dateTime} header="Data i czas wykonania operacji" />
                    <Column field="installer" header="Operacja wykonana przez" body={InstallerOrAccountBody}
                            accountField="account"/>
                    <Column field="comment" header="Komentarz" body={this.comment} />
                    <Column field="status.verboseName" header="Nowy status" />
                    <Column header="Dodatkowe informacje" body={this.otherInfo} />

                </DataTable>
            </div>

        );

    }
}

export default OrderEventTable;
