import '../../../static/css/App.css';
import React from 'react';

import Order from "../../other/Order";
import BotDsPreviewService from "../../../services/BotDsPreviewService";
import {lastCreationDate, setHistoryMetadata} from "../../../shared/Utils";

class BotDsOrder extends React.Component {


    _isMounted = false;

    state = {
        order: {
            area: [],
            installers: [],
            businessProcess: {},
            technology: {},
            service: {},
            status: {},
            simInstallationStatus: {},
            rejectionReason: {},
            orderDevices: [],
        },
        events: []

    }

    componentDidMount() {
        this._isMounted = true;
        this.getOrder();
    }

    getOrder() {
        BotDsPreviewService.getById(this.props.match.params.id,
            (data) => {
                setHistoryMetadata(data);
                if (this._isMounted) this.setState({order: data})
            },
            (error) => {
                console.log("Error: " + error)
            })
    }

    getEvents(id) {
        BotDsPreviewService.getEventsByIdWithTimestamp(id, lastCreationDate(this.state.events),
            (data) => {
                if (data) this.setState({events: data})
            },
            (error) => {
                console.log("Error: " + error)
            })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {

        //https://reacttraining.com/react-router/web/example/url-params
        const {match} = this.props;

        return (

            <div  style={{"paddingTop": "7px"}}>
                <Order dataSource={this.state.order} match={match} refreshOrder={()=>this.getOrder()}
                       refreshEvents={() => this.getEvents(this.state.order.id)} events={this.state.events}
                       monitoringPreviewOrBots={true}/>

            </div>
        )

    }
}

export default BotDsOrder;