import React from 'react';
import { DEFAULT_PAGE_SIZE, DEFAULT_SORT_BY_FOR_ORDER, QueueType } from "../../config/constants";
import AuthService from "../../services/AuthService";
import OrderPreviewService from "../../services/OrderPreviewService";
import OrderDataTable from "../../shared/orderDataTable/OrderDataTable";
import OrderFilterLoadService from '../../shared/OrderFilterLoadService';
import OrderFilterPanel, { defaultOnFilterChange } from "../../shared/OrderFilterPanel";
import { createParams } from "../../shared/OrderTableCommon";
import { filtersNotEmptySkipModificationDateTimeFrom } from '../../shared/Utils';

class OrderPreviewList extends React.Component {

    state = {
        orders: [],
        loading: false,
        first: 1,
        rows: DEFAULT_PAGE_SIZE, // Ilość wierszy na stronie
        totalRecords: 0,// Ilośc wszystkich elementów w bazie
        filters: {},
        area: {},
        sortBy: DEFAULT_SORT_BY_FOR_ORDER,
        filter: false

    }

    constructor(props) {
        super(props);
        this.onPage = this.onPage.bind(this);
    }


    componentDidMount() {
        this.setState({ filters: new OrderFilterLoadService().getFilter(QueueType.HISTORY) },()=>{
            this.getOrders(this.state.filters, this.state.sortBy, 1, 0);

            this.setState({
                filter:filtersNotEmptySkipModificationDateTimeFrom(this.state.filters)
            });

        });

    }

    onPage(event) {
        this.setState({
            loading: true
        });
        const startIndex = event.first;
        const pageNumber = startIndex / event.rows + 1;

        this.setState({pageNumber:pageNumber, startIndex:startIndex, rows: event.rows},()=>{
            this.getOrders(this.state.filters, this.state.sortBy, this.state.pageNumber, this.state.startIndex);
        });
    }
    getOrders(filters, sortBy, pageNumber, startIndex) {

        const params = createParams(filters, sortBy);
        OrderPreviewService.getMainQueueOrders(params, pageNumber, this.state.rows, (page) => {

            this.setState({
                orders: page.content,
                totalRecords: page.totalElements,
                loading: false,
                first: startIndex,
            });
        },
        (error) => {
            this.setState({
                loading: false
            });
            AuthService.clearLocalStorageAndForwardToLoginWhen401(this, error);
            console.log(`Error:${error.response.status}`);
        });
    }
    render() {

        const filterColumns = {
            finishedWorkSuccess: true,
            finishedWorkDateTime: true,
            ptInClientLocation: true,
            wifiAnalyzeAdded: true,
        };
        return (
            <div>
                {  <OrderFilterPanel
                    filters={this.state.filters} filter={this.state.filter}
                    onFilterChange={defaultOnFilterChange(this)}
                    onCleanButton={(filters) => {
                        new OrderFilterLoadService().resetFilter((filters) => {
                            this.setState({filters:filters},()=>{
                                this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                            });
                        },QueueType.HISTORY);
                    }}
                    visibleObject={filterColumns}
                    onFilter={() => {
                        this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                    }}
                    onSortFieldChange={(val, property) => {
                        this.setState({ [property]: val });
                    }}
                />}



                <OrderDataTable showAll={false} visible={"location"}
                    totalRecords={this.state.totalRecords} toogleFilter={() => this.setState({ filter: !this.state.filter })}
                    headerTitle={"Podgląd zleceń"} orders={this.state.orders} rows={this.state.rows}
                    first={this.state.first} loading={this.state.loading} onPage={this.onPage}
                    refreshTable={() => {
                        this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                    }}
                    onSort={(sortBy) => {
                        this.setState({ sortBy: sortBy });
                        this.getOrders(this.state.filters, sortBy, 1, 0);

                    }} />
            </div>

        );
    }
}

export default OrderPreviewList;
