import { Growl } from "primereact/growl";
import React from 'react';
import { DEFAULT_PAGE_SIZE, MODIFICATION_DATE_TIME_ASC, QueueType, REFRESH_TABLE_INTERVAL } from "../../config/constants";
import AuthService from "../../services/AuthService";
import MonitoringService from "../../services/MonitoringService";
import QueueOrderNotificationService from "../../services/QueueOrderNotificationService";
import OrderDataTable from "../../shared/orderDataTable/OrderDataTable";
import OrderFilterLoadService from "../../shared/OrderFilterLoadService";
import OrderFilterPanel, { defaultOnFilterChange } from "../../shared/OrderFilterPanel";
import { createParams } from "../../shared/OrderTableCommon";
import { filtersNotEmptySkipModificationDateTimeFrom, showQueueNewOrdersNotifications } from "../../shared/Utils";
import '../../static/css/App.css';
import ChatService from "../../services/ChatService";



class MonitoringTodayOrders extends React.Component {

    state = {
        orders: [],
        loading: false,
        first: 1,
        rows: DEFAULT_PAGE_SIZE, // Ilość wierszy na stronie
        totalRecords: 0,// Ilośc wszystkich elementów w bazie
        filters: {},
        area: {},
        sortBy: MODIFICATION_DATE_TIME_ASC,
        filter: false,
        previousModificationTime: new Date(),
        pageNumber: 1,
        startIndex: 0

    }

    constructor(props) {
        super(props);
        this.onPage = this.onPage.bind(this);
    }

    refreshTable() {
        this.getOrders(this.state.filters, this.state.sortBy, this.state.pageNumber, this.state.startIndex, (data) => {
            showQueueNewOrdersNotifications(this,QueueOrderNotificationService.getNewOrdersFromMonitoringTodayQueue);
        });

    }

    componentDidMount() {
        this.setState({ filters: new OrderFilterLoadService().getFilter(QueueType.MAIN) },()=>{
            this.interval = setInterval(() => this.refreshTable(), REFRESH_TABLE_INTERVAL);
            this.refreshTable();

            this.setState({
                filter:filtersNotEmptySkipModificationDateTimeFrom(this.state.filters)
            });

        });

    }


    componentWillUnmount() {
        clearInterval(this.interval);
    }

    onPage(event) {

        this.setState({
            loading: true
        });
        const startIndex = event.first;
        const pageNumber = startIndex / event.rows + 1;

        this.setState({pageNumber:pageNumber, startIndex:startIndex, rows: event.rows},()=>{
            this.getOrders(this.state.filters, this.state.sortBy, this.state.pageNumber, this.state.startIndex);
        });

    }



    getOrders(filters, sortBy, pageNumber, startIndex, onSuccess) {

        const params = createParams(filters, sortBy);
        MonitoringService.getToday(params, pageNumber, this.state.rows, (page) => {

            this.setState({
                orders: page.content,
                totalRecords: page.totalElements,
                loading: false,
                first: startIndex,
            });
            if (onSuccess) {
                onSuccess(page.content);
            }
        },
        (error) => {
            this.setState({
                loading: false
            });
            console.log(`Error:${error}`);
            AuthService.clearLocalStorageAndForwardToLoginWhen401(this, error);
        });
    }


    render() {

        const filterColumns = {
            ptInClientLocation: true
        };

        return (
            <div>
                {  <OrderFilterPanel
                    // visible="sortField,serviceId"
                    visibleObject={filterColumns}
                    onFilterChange={defaultOnFilterChange(this)}
                    filters={this.state.filters} filter={this.state.filter}
                    onCleanButton={(filters) => {
                        new OrderFilterLoadService().resetFilter((filters) => {
                            this.setState({filters:filters},()=>{
                                this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                            });
                            
                        },QueueType.MAIN);
                    }}
                    onFilter={() => {
                        this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                    }}
                    onSortFieldChange={(val, property) => {
                        this.setState({ [property]: val });
                    }}
                />}

                <Growl ref={(el) => this.growl = el} position="topright"/>

                <OrderDataTable visible={"location,timeSlot,installationStartDateTime,resignationDateTime,clientPostPoneDateTime,techPostPoneDateTime,noResponseDateTime,enrouteDateTime,handoverDateTime,delay,ptInClientLocation"}
                    totalRecords={this.state.totalRecords} toogleFilter={() => this.setState({ filter: !this.state.filter })}
                    headerTitle={"Dzisiaj"} orders={this.state.orders} rows={this.state.rows}
                    first={this.state.first} loading={this.state.loading} onPage={this.onPage} skipLastURLSegmentOfItem={true}
                    refreshTable={() => {
                        this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                    }}
                    openChatWithoutAssignment={true}
                    onSort={(sortBy) => {
                        this.setState({ sortBy: sortBy }, () => {
                            this.refreshTable();
                        });
                    }} />

            </div>

        );
    }


}

export default MonitoringTodayOrders;

