import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
import React from 'react';
import { withRouter } from "react-router";
import '../../static/css/Login.css';
import logo from '../../static/images/orange.png';
import ChangePasswordDialog from './ChangePasswordDialog';
import CurrentAccountService from '../../services/CurrentAccountService';
import AuthService from '../../services/AuthService';


/**
 * Gdy administrator zmieni Ci hasło lub stworzy nowe konto z randomowym hasłem,
 * podczas pierwszego logowania przechodzisz na ten widok i zmieniasz hasło.
 */

class ChangePassword extends React.Component {


    state = {

    }

    render() {
        const {history} = this.props;
        return (
            <div>

                <img src={logo} style={{
                    "display": "block",
                    "marginLeft": "auto",
                    "marginRight": "auto"
                }} alt="logo"/>


                <div className="login-div">
                    <h3>Wymagana zmiana hasła!</h3>
                    <ChangePasswordDialog visible={this.state.changePasswordDialogVisible} onHide={() => this.setState({changePasswordDialogVisible: false})}

                        onSuccess={()=>{
                            CurrentAccountService.fetchCurrentUser(()=>{
                                AuthService.signout(()=>{
                                    history.push("/login");
                                });
                            });
                        }}/>
                    <div>

                        <div className="login-button">
                            <button type="submit" className="btn waves-effect waves-light"
                                onClick={() => this.setState({changePasswordDialogVisible:true})}
                                name="action">Zmień hasło
                            </button>
                        </div>


                    </div>

                </div>
            </div>
        );
    }
}

//https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/withRouter.md

export default withRouter(ChangePassword);
