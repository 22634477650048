import '../static/css/App.css';
import React from 'react';
import {Button} from "primereact/button";
import {withRouter} from 'react-router-dom';
import {Messages} from "primereact/messages";
import OrderService from "../services/OrderService";
import ErrorService from "../services/ErrorService";


const defaultSummary = "Wystąpił błąd!";
const defaultDetail = "Operacja zakończyła się niepowodzeniem.";

class AssignPanel extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            comment: null,
        };
    }

    componentDidMount() {
        if(this.props.shouldAssign) {
            this.assignToOrder(this.props.match.params.id);
        }
    }

    showError = (summary, detail) => {
        this.messages.show({severity: 'error', summary: summary, detail: detail});
    }

    isOPLUserAssignedToOrder() {
        return this.props.order ? this.props.order.assignedOplUser : null;
    }

    assignToOrder(orderId) {
        this.setState({disabled: true}, function () {
            if (this.props.assign) {
                this.props.assign(orderId, this.assignToOrderSuccess, this.assignToOrderError);
            } else {
                OrderService.assingOplUser(orderId, this.assignToOrderSuccess, this.assignToOrderError);
            }
        });
    }

    assignToOrderSuccess = () => {
        if (this.props.refreshOrder) {
            this.props.refreshOrder();
        }
        this.setState({ disabled: false });
    }

    assignToOrderError = (error) => {
        ErrorService.logServerError(error);
        if (error.response && error.response.data) {
            let errorMessage = ErrorService.getAtriumErrorMessage(error, defaultDetail);
            this.showError(defaultSummary, errorMessage);
        }
        this.setState({ disabled: false });
    }

    unassignFromOrder(orderId, onSuccess) {
        this.setState({disabled: true}, function () {
            if (this.props.unassign) {
                this.props.unassign(orderId, this.unassignFromOrderSuccess, this.unassignFromOrderError);
            } else {
                OrderService.unassignOplUser(orderId, this.unassignFromOrderSuccess, this.unassignFromOrderError);
            }
        });
    }

    unassignFromOrderSuccess = (onSuccess) => {
        if (this.props.refreshOrder) {
            this.props.refreshOrder();
        }
        if (onSuccess) {
            onSuccess();
        }
        this.setState({disabled: false});
    }

    unassignFromOrderError = (error) => {
        ErrorService.logServerError(error);
        let atriumErrorMessage = ErrorService.getAtriumErrorMessage(error);
        let errorMessage = atriumErrorMessage ? atriumErrorMessage : defaultDetail;
        this.showError(defaultSummary, errorMessage);
        this.setState({disabled: false});
    }

    render() {

        //https://reacttraining.com/react-router/web/example/url-params
        const {match} = this.props;

        return (
            <>
                <Messages ref={(el) => this.messages = el}/>
                <div className="p-grid nogutter p-col-12 p-justify-start">
                    <label className="o-label" htmlFor="in">
                        {this.isOPLUserAssignedToOrder() ? "Odpisz się:" : "Przypisz się:"}
                    </label>
                    <Button disabled={this.state.disabled || this.isOPLUserAssignedToOrder()}
                            style={{"marginLeft": "10px", "marginTop": "5px"}}
                            className="o-value" onClick={() => {
                        this.assignToOrder(match.params.id);
                    }} label="Przypisz"/>
                    <Button disabled={this.state.disabled || !this.isOPLUserAssignedToOrder()}
                            style={{"marginLeft": "10px", "marginTop": "5px"}}
                            className="o-value" onClick={() => {
                        this.unassignFromOrder(match.params.id, () => {
                            this.props.history.goBack();
                        });
                    }} label="Odpisz"/>
                </div>
            </>

        );

    }
}

export default withRouter(AssignPanel);
