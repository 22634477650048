import React from 'react';

export const NotFound = () => {
    return (
        <div>
            <p>NOT FOUND !!!</p>
        </div>
    );
};

export default NotFound;
