import _ from "lodash";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import React from 'react';
import { withRouter } from "react-router-dom";
import GenesysService from "../../services/GenesysService";
import OplAccountService from "../../services/OplAccountService";
import '../../static/css/App.css';
import {TIME_TO_CLOSE_PT_CALL_DIALOG_AFTER_SUCCESS_IN_MILLIS} from "../../config/constants";

class CallPtDialog extends React.Component {

    state = {
        callButtonEnabled: true,
        dismissButtonEnabled: true,
        inputPhoneNumberEnabled: true,
        successCall: false
    }


    /*
    https://pl.reactjs.org/docs/react-component.html#componentdidupdate
    */
    componentDidUpdate(prevProps) {
      
        if ( !prevProps.visible  && this.props.visible) {
          this.getOplPhoneNumber();
        }
      }

    call = (data) => {
        this.setState({ callButtonEnabled: false, dismissButtonEnabled: false, inputPhoneNumberEnabled: false });
        GenesysService.makeDialCall(data,
            (response) => {
                this.showSuccess("Udało się wywołać usługę", " - czekaj na połączenie z PT...")
            },
            (error) => {
                this.showError("Wystąpił błąd!", this.getErrorMessage(error));
                this.setState({ callButtonEnabled: true, dismissButtonEnabled: true,
                    inputPhoneNumberEnabled: true, successCall: false });
            })
    }

    onClose = () => {
        this.setState({ callButtonEnabled: true, dismissButtonEnabled: true, inputPhoneNumberEnabled: true });
        this.props.onHide();
    }

    //TODO: do ustalenia treści komunikatów
    getErrorMessage(error) {
        let errorMessage;
        let errorCode = _.get(error, "response.data.errorCode", null);
        switch (errorCode) {
            case 'CANNOT_FIND_INSTALLER_NUMBER':
                errorMessage = "PT nie posiada numeru kontaktowego w systemie.";
                break;
            case 'GENESYS_ERROR':
                errorMessage = "Błąd systemu Genesys.";
                break;
            case 'INCORRECT_INSTALLER_NUMBER':
                errorMessage = "Niepoprawny telefon instalatora(PT) w systemie.";
                break;
            case "GENESYS_CONNECT_EXCEPTION":
            case "GENESYS_SOCKET_TIMEOUT_EXCEPTION":
                errorMessage = "Nie można nawiązać połączenia z systemem Genesys.";
                break;
            default:
                errorMessage = "Wewnętrzny błąd systemu. Spróbuj ponownie.";
                break;
        }
        return errorMessage;
    }

    handleOnChange = (e) => {
        this.setState({ oplPhoneNumber: e.target.value });
    }

    getOplPhoneNumber() {
        OplAccountService.getPhoneNumber(
            (data) => {
                let oplPhoneNumber = _.get(data, "phoneNumber", null);
                this.setState({ oplPhoneNumber: oplPhoneNumber });
            },
            (error) => {
                console.log("Error: " + error);
            });
    }

    showError = (summary, detail) => {
        this.messages.show({ severity: 'error', summary: summary, detail: detail});
    }

    showSuccess = (summary, detail) => {
        this.setState({successCall: true});
        this.messages.show({ severity: 'success', summary: summary, detail: detail, life: TIME_TO_CLOSE_PT_CALL_DIALOG_AFTER_SUCCESS_IN_MILLIS});
    }

    enabledPtCallButton = () => {
        if (this.state.oplPhoneNumber && this.state.oplPhoneNumber.length===9) {
            return this.state.callButtonEnabled;
        }
        return false;
    }

    render() {
        const { installerId } = this.props;

        const dialogHeader = "Podaj swój numer telefonu (użytkownika OPL) w celu nawiązania połączenia \n(wymagane dokładnie 9 cyfr):";

        const ptNumberInfo = "Numer instalatora (PT) jest pobierany automatycznie! Nie podawaj go w powyższym polu!"

        const dialogFooter = (
            <>
                <div className={"dialogFooter"}>
                    <span className={"marginRight"}>
                        <InputText tooltip={this.state.oplPhoneNumber ? "" : "Podaj swój numer telefonu"} className="marginRight validate"
                            placeholder={"np. 227777888"}
                            id="oplNumber"
                            type="text" required={true}
                            disabled={!this.state.inputPhoneNumberEnabled}
                            value={this.state.oplPhoneNumber}
                            onChange={this.handleOnChange}
                            keyfilter={"pnum"}
                            minLength={9}
                            maxLength={9}
                        />
                    </span>
                    <span className={"marginRight"}>

                        <Button label="Zadzwoń" icon="pi pi-phone" className={"p-button-success"}
                                disabled={!this.enabledPtCallButton()}
                            onClick={(event) => {
                                this.call({ oplNumber: this.state.oplPhoneNumber, installerId: installerId });
                            }} />
                    </span>
                    <span className={"marginRight"}>
                        <Button label="Anuluj" icon="pi pi-times" className="p-button-danger" disabled={!this.state.dismissButtonEnabled}
                            onClick={(event) => {
                                this.props.onHide();
                            }} />
                    </span>

                </div>
                <Messages ref={(el) => this.messages = el} className={'call-error'} onRemove={ this.state.successCall && this.onClose} />
                <div className={'pt-number-warn'}>{ptNumberInfo}</div>
            </>
        );

        return (
            <>
                <Dialog header={dialogHeader}
                    focusOnShow={true} closeOnEscape={true} closable={false}
                    footer={dialogFooter}
                    visible={this.props.visible} className={'call-pt-dialog'}
                    modal={true}
                    onHide={() => { if (this.props.onHide) this.props.onHide(); }}>
                </Dialog>
            </>
        );
    }
}
export default withRouter(CallPtDialog);