import { Button } from 'primereact/button';
import { Chips } from 'primereact/chips';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import React from 'react';
import { withRouter } from 'react-router-dom';
import DeviceModelService from "../../services/dict/DeviceModelService";
import '../../static/css/App.css';


class DeviceModel extends React.Component {


    state = {
        values: [],
        rowIdsToSendUpdateToServer: new Set(),
    }

    constructor(props) {
        super(props);

        this.verboseNameEditor = this.verboseNameEditor.bind(this);
        this.inputTextEditor = this.inputTextEditor.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.identificatorsEditor = this.identificatorsEditor.bind(this);
        this.identificatorsBody = this.identificatorsBody.bind(this);
        this.onEditorValueChange = this.onEditorValueChange.bind(this);

    }

    componentDidMount() {
        this.getValues();

    }

    getValues() {

        DeviceModelService.getAll((data) => {
            this.setState({
                values: data,
            });
        },
        (error) => {
            this.setState({
                loading: false
            });
            console.log(`Error:${error}`);
        });
    }

    verboseNameEditor(props) {
        return this.inputTextEditor(props, 'verboseName');
    }

    inputTextEditor(props, field) {
        return <InputText type="text" value={props.rowData[field]}
            onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
    }

    onEditorValueChange(props, value) {

        const updatedValues = [...props.value];
        updatedValues[props.rowIndex][props.field] = value;
        const rowIdsToSendUpdateToServer = new Set(this.state.rowIdsToSendUpdateToServer);
        const id = updatedValues[props.rowIndex]["id"];
        rowIdsToSendUpdateToServer.add(id);
        this.setState({ values: updatedValues, rowIdsToSendUpdateToServer: rowIdsToSendUpdateToServer });
    }

    updateValue = (data) => {
        DeviceModelService.update(data, () => {
            const rowIdsToSendUpdateToServer = new Set(this.state.rowIdsToSendUpdateToServer);
            rowIdsToSendUpdateToServer.delete(data["id"]);
            this.setState({ rowIdsToSendUpdateToServer: rowIdsToSendUpdateToServer });
        });
    }

    showError = (summary, detail) => {
        this.messages.show({ severity: 'error', summary: summary, detail: detail });
    }

    actionTemplate(rowData) {
        return <div>
            <Button type="button" disabled={!this.state.rowIdsToSendUpdateToServer.has(rowData["id"])} icon="pi pi-save"
                className="p-button-success" onClick={() => {
                    this.updateValue(rowData);
                }}> </Button>

        </div>;
    }

    identificatorsEditor(props) {
        return <div>

            <Dialog header="Edycja identyfikatorów" closable={false} visible={true} style={{ maxWidth: '50%' }} modal={false} onHide={() => this.setState({ visible: false })}>
                <Chips
                    style={{}}
                    value={props.rowData["identificators"] ? props.rowData["identificators"] : []}
                    onChange={(e) => {
                        this.onEditorValueChange(props, e.value);
                        console.log(`E.value = ${+ e.value}`);
                    }}></Chips>
            </Dialog>

        </div>;
    }

    identificatorsBody(rowData, column) {
        return <ul style={{ textAlign: 'left', "paddingLeft": "15px", "wordBreak": "break-word" }}>
            {
                rowData[column.field] ? rowData[column.field].map((ident) => {
                    return <li>{ident}</li>;
                }) : ""

            }</ul>;
    }

    render() {

        var header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> Modele urządzeń

            {this.props.addUrl &&
                <Button icon="pi pi-plus" style={{ 'float': 'right', 'marginLeft': '10px' }}
                    onClick={() => this.props.history.push(this.props.addUrl)} />
            }
            <Button
                icon="pi pi-refresh" style={{ 'float': 'right' }}
                onClick={() => this.getValues()} />


        </div>;

        return (

            <div className="">

                <div className="p-grid nogutter p-col-12">
                    <Messages ref={(el) => this.messages = el} />
                </div>
                <DataTable value={this.state.values} editable={true} header={header} responsive={true}>
                    <Column field="id" header="Identyfikator" filter={true}
                        style={{ textAlign: 'center' }} sortable={true} />
                    <Column field="name" header="Nazwa" style={{ textAlign: 'center' }} filter={true} sortable={true} />
                    <Column field="verboseName" header="Wartość wyświetlana" editor={this.verboseNameEditor}
                        sortable={true}
                        style={{ textAlign: 'center' }} filter={true} />
                    <Column field="identificators"
                        body={this.identificatorsBody}
                        header="Identyfikatory"
                        editor={this.identificatorsEditor}
                        filter={true} sortable={true} />
                    <Column body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                </DataTable>
                <br></br>
                <div style={{ "display": "flex", marginBottom: "10px" }} >
                    <span className="p-component" >
                        Uwaga!!! Słowniki i konfiguracja procesów biznesowych jest przechowywana w pamięci podręcznej.
                        W celu aktualizacji tych danych na aplikacji mobilnej należy przejść do zakładki Ustawienia-&gt;Pamięć podręczna.
                    </span>

                </div>
            </div>


        );
    }


}

export default withRouter(DeviceModel);

