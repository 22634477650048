import { Button } from 'primereact/button';
import React from 'react';
import { withRouter } from "react-router";

class TakeLastFromQueueBtn extends React.Component {

    state={
        disabled:false
    }

    render() {

        return (<Button type="button" style={{ 'margin': '5px 5px 5px 0px' }} label="Pobierz fotomontaż"
            tooltip="Przypisuje użytkownika do pierwszego (wolnego) zlecenia na liście."
            disabled={this.state.disabled}
            onClick={(e) => {
                this.setState({disabled:true},()=>{
                    let getOrderId = this.props.getOrderId;
                    if (getOrderId) {
                         getOrderId((idDto)=>{
                             if(idDto && idDto.id){
                                 this.moveToOrderView(idDto.id);
                             }
                             this.setState({disabled:false});
                            
                        },(error=>{this.setState({disabled:false});}));
                    }
                });

            }} />);
    }

    moveToOrderView(orderId) {
        let urlToPush = "./" + orderId;
        let pathName = window.location.pathname;
        if (pathName.endsWith("order")) {
            urlToPush = "./order/" + orderId;
        }
        this.props.history.push(urlToPush);
    }
}

export default withRouter(TakeLastFromQueueBtn);
