import axios from "axios";
import RestService from "./RestService";


const PATH = "/news";

const NewsService = {



    getNews(params, pageNumber, pageSize, onSuccess, onError) {

        if (!params) {
            params = {};
        }
        params["page"] = pageNumber;
        params["size"] = pageSize;

        return axios.get(PATH, {
            params: params
        })
            .then(function (response) {
                if (response.status === 200) {
                    onSuccess(response.data)
                } else {
                    let error = "Incorrect response status " + response.status;
                    onError(error);
                }

            })
            .catch(function (error) {
                onError(error);
            });


    },

    getNewsNotifications(onSuccess, onError) {
        RestService.getWithPagination(PATH + "/notification", {}, 0, 1000, onSuccess, onError);
    },

    hasUnreadMessages(onSuccess, onError) {
        RestService.get(PATH + "/notification/unread", onSuccess, onError);
    },

    updateLastMsgReadDateTime(dateTime,onSuccess, onError) {
        RestService.update(PATH + "/notification", {dateTime:dateTime}, onSuccess, onError);
    },


    getById(id, onSuccess, onError) {

        return axios.get(PATH + "/" + id)
            .then(function (response) {
                if (response.status === 200) {
                    onSuccess(response.data)
                } else {
                    let error = "Incorrect response status " + response.status;
                    onError(error);
                }

            })
            .catch(function (error) {
                onError(error);
            });


    },
    enable(id, onSuccess, onError) {

        return axios.put(PATH + "/" + id + "/enable")
            .then(function (response) {
                if (response.status === 200) {
                    onSuccess(response.data)
                } else {
                    let error = "Incorrect response status " + response.status;
                    onError(error);
                }

            })
            .catch(function (error) {
                onError(error);
            });


    },
    add(data, onSuccess, onError) {

        return axios.post(PATH, data)
            .then(function (response) {
                if (response.status === 201) {
                    onSuccess(response.data)
                } else {
                    let error = "Incorrect response status " + response.status;
                    onError(error);
                }

            })
            .catch(function (error) {
                onError(error);
            });


    },

    edit(data, onSuccess, onError) {

        return axios.put(PATH + "/" + data["id"], data)
            .then(function (response) {
                if (response.status === 200) {
                    onSuccess(response.data)
                } else {
                    let error = "Incorrect response status " + response.status;
                    onError(error);
                }

            })
            .catch(function (error) {
                onError(error);
            });


    },


}

export default NewsService;