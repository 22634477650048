import { OplStatus } from "../config/constants";


const OplStatusMapper = {


    mapToVerboseName(type) {
        if (type) {
            switch (type) {
            case OplStatus.IN_PROGRESS:
                return "W trakcie";
            default:
            }
        }

        return "Brak";
    },




};

export default OplStatusMapper;
