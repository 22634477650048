import '../../static/css/App.css';
import React from 'react';
import {InputTextarea} from "primereact/inputtextarea";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {withRouter} from 'react-router-dom';
import {Messages} from "primereact/messages";
import OkiReplacementService from "../../services/OkiReplacementService";
import ErrorService from "../../services/ErrorService";
import AssignPanel from "../../shared/AssignPanel";
import OrderRejectionReasonDiv from "../../shared/OrderRejectionReasonDiv";
import {forwardToDispatcher, rejectAndForwardToInstaller} from "../../config/constants";
import CurrentAccountService from '../../services/CurrentAccountService';
import classNames from "classnames";

class OkiReplacementOperation extends React.Component {


    //https://www.robinwieruch.de/react-warning-cant-call-setstate-on-an-unmounted-component/
    _isMounted = false;

    state = {
        selectedOperation: "",
        comment: "",
        orderRejectionReason: null
    }

    componentDidMount() {
        this._isMounted = true;

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    showError = (summary, detail) => {
        this.messages.show({severity: 'error', summary: summary, detail: detail});
    }


    invokeOperation = (orderId) => {
        this.setState({disabled: true}, function () {
            let data = {};
            if (this.state.comment) {
                if(this.state.comment.length > 180) {
                    this.showError("Zbyt długi komentarz!", "Maksymalna liczba znaków w komentarzu to 180.");
                    return;
                }
                data = {comment: this.state.comment};
            }

            if (this.state.selectedOperation) {
                if (this.state.selectedOperation.key === "dysp") {
                    this.forwardToDispatcher(orderId, data);
                } else if (this.state.selectedOperation.key === "reject") {
                    if (this.state.orderRejectionReason) {
                        data["orderRejectionReasonId"] = this.state.orderRejectionReason["key"];
                    }
                    this.rejectAndForwardToInstaller(orderId, data);
                }
            }
        });
    }

    forwardToDispatcher(orderId, data) {
        OkiReplacementService.forwardToDispatcher(orderId, data, () => {
            this.setState({disabled: false});
            this.props.history.push("/okiReplacement/order");
        }, (error) => {
            ErrorService.getErrorMsgToShow(error, (summary, detail) => {
                this.showError(summary, detail);
                this.setState({disabled: false});
            });
        });
    }


    rejectAndForwardToInstaller(orderId, data) {
        OkiReplacementService.rejectAndForwardToInstaller(orderId, data, () => {
            this.setState({disabled: false});
            this.props.history.push("/okiReplacement/order");
        }, (error) => {
            ErrorService.getErrorMsgToShow(error, (summary, detail) => {
                this.showError(summary, detail);
                this.setState({disabled: false});
            });
        });
    }


    getOperations() {
        const operations = [];

        operations.push(forwardToDispatcher);
        operations.push(rejectAndForwardToInstaller);

        return operations;
    }

    isOPLUserAssingedToOrder() {
        return this.props.order.assignedOplUser;
    }

    isCurrentOPLUserAssignedToOrder() {
        if (this.props.order.assignedOplUser) {
            let login = this.props.order.assignedOplUser.login;
            return CurrentAccountService.getLogin() === login;
        }

    }

    render() {

        //https://reacttraining.com/react-router/web/example/url-params
        const {match} = this.props;

        return (

            <div className="p-grid nogutter p-col-4 p-component p-align-start p-col-align-start">

                <AssignPanel refreshOrder={this.props.refreshOrder} order={this.props.order}
                             shouldAssign={this.state.shouldAssign}/>
                {this.getOperations(this.props.order).length > 0 && this.isCurrentOPLUserAssignedToOrder() ?
                    <>
                        <Messages ref={(el) => this.messages = el}/>
                        <div className="p-grid nogutter p-col-12">
                            <label className="p-col-3 o-label" htmlFor="in">Przekaż zlecenie:</label>
                            <span className="o-value">
                                <Dropdown showClear={true}
                                          optionLabel="name"
                                          value={this.state.selectedOperation}
                                          options={this.getOperations(this.props.order)}
                                          onChange={(event) => {
                                              this.setState({selectedOperation: event.value});
                                          }}/>
                            </span>
                        </div>

                        <OrderRejectionReasonDiv selectedOperation={this.state.selectedOperation}
                                                 onChange={(event) => {
                                                     this.setState({orderRejectionReason: event.value});
                                                 }
                                                 }/>

                        <div className="p-grid nogutter p-col-12">
                            <label
                                className={classNames("p-col-3", "o-label", {"p-error": this.state.comment.length > 180})}>Komentarz:</label>
                            <span className="o-value p-float-label">
                                <InputTextarea id={"commentTextArea"} rows={4} cols={30} autoResize={true}
                                               value={this.state.comment}
                                               className={classNames({"p-invalid": this.state.comment.length > 180})}
                                               onChange={(e) => {
                                                   this.setState({comment: e.target.value})
                                               }}/>
                                <label
                                    htmlFor="commentTextArea">{this.state.comment ? this.state.comment.length + "/180" : ""}</label>
                            </span>
                        </div>

                        <div className="p-grid nogutter p-col-12 p-justify-start">
                            <span className="p-col-3 o-label"/>
                            <Button disabled={this.state.disabled || !this.state.selectedOperation}
                                    style={{"marginLeft": "10px", "marginTop": "5px"}}
                                    className="o-value" onClick={() => {
                                this.invokeOperation(match.params.id);
                            }} label="Przekaż"/>
                        </div>
                    </>
                    : null}
            </div>
        );

    }
}

export default withRouter(OkiReplacementOperation);
