import React, {Component} from 'react';
import OrderStatusService from "../../services/dict/OrderStatusService";
import {MultiSelect} from "primereact/multiselect";


class OrderStatusMultiSelect extends Component {


    state = {
        values: [],
        val: null

    }

    componentDidMount() {
        OrderStatusService.getAll((values) => {
            if (this.props.statusFilter) {
                if (values) {
                    values = values.filter(this.props.statusFilter);
                }
            }
            this.setState({values: values});
        }, (error) => {
            console.log(`OrderStatusMultiSelect->error${JSON.stringify(error)}`);
        });

    }


    render() {

        return (
            <MultiSelect showClear={true} className={this.props.className} filter={true}
                optionLabel="verboseName" value={this.props.value?this.props.value:this.state.val} options={this.state.values}
                placeholder={this.props.placeholder}
                dataKey={"id"}
                onChange={(event) => {
                    this.setState({"val": event.value});
                    if (this.props.onChange) {
                        this.props.onChange(event);
                    }
                }}/>

        );
    }
}

export default OrderStatusMultiSelect;
