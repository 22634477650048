import '../../../static/css/App.css';
import React from 'react';
import {
    createParams
} from "../../../shared/OrderTableCommon";
import OrderFilterPanel, {defaultOnFilterChange} from "../../../shared/OrderFilterPanel";
import OrderDataTable from "../../../shared/orderDataTable/OrderDataTable";
import BotDsPreviewService from "../../../services/BotDsPreviewService";
import {DEFAULT_PAGE_SIZE, DEFAULT_SORT_BY_FOR_ORDER, QueueType, REFRESH_TABLE_INTERVAL} from "../../../config/constants";
import {
    filtersNotEmptySkipModificationDateTimeFrom,
    showQueueNewOrdersNotifications
} from "../../../shared/Utils";
import {Growl} from "primereact/growl";
import QueueOrderNotificationService from "../../../services/QueueOrderNotificationService";
import OrderFilterLoadService from "../../../shared/OrderFilterLoadService";

class BotDsOrders extends React.Component {

    state = {
        orders: [],
        loading: false,
        first: 1,
        rows: DEFAULT_PAGE_SIZE, // Ilość wierszy na stronie
        totalRecords: 0,// Ilośc wszystkich elementów w bazie
        filters: {},
        area: {},
        sortBy: DEFAULT_SORT_BY_FOR_ORDER,
        filter: false,
        previousModificationTime: null

    }

    constructor(props) {
        super(props);
        this.onPage = this.onPage.bind(this);
    }

    refreshTable() {
        this.getOrders(this.state.filters, this.state.sortBy, 1, 0, (data) => {
            showQueueNewOrdersNotifications(this, QueueOrderNotificationService.getNewOrdersFromDsBotMainQueue);
        });

    }

    componentDidMount() {
        this.setState({ filters: new OrderFilterLoadService().getFilter(QueueType.MAIN), loading: true },()=>{
            this.interval = setInterval(() => this.refreshTable(), REFRESH_TABLE_INTERVAL);
            this.refreshTable();

            this.setState({
                filter:filtersNotEmptySkipModificationDateTimeFrom(this.state.filters)
            });

        });

    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    onPage(event) {
        this.setState({
            loading: true
        });
        const startIndex = event.first;
        const pageNumber = startIndex / event.rows + 1;

        this.setState({ pageNumber: pageNumber, startIndex: startIndex, rows: event.rows }, () => {
            this.getOrders(this.state.filters, this.state.sortBy, this.state.pageNumber, this.state.startIndex);
        });    }




    getOrders(filters, sortBy, pageNumber, startIndex,onSuccess) {

        let params = createParams(filters, sortBy);
        BotDsPreviewService.getMainQueueOrders(params, pageNumber, this.state.rows, (page) => {

                this.setState({
                    orders: page.content,
                    totalRecords: page.totalElements,
                    loading: false,
                    first: startIndex,
                })
                if (onSuccess) {
                    onSuccess(page.content);
                }
            },
            (error) => {
                this.setState({
                    loading: false
                });
                console.log("Error:" + error)
            });
    }


    render() {


        return (
            <div>
                {<OrderFilterPanel
                    // visible="sortField,serviceId"
                    onFilterChange={defaultOnFilterChange(this)}
                    onFilter={() => {
                        this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                    }}
                    filters={this.state.filters} filter={this.state.filter }
                    onCleanButton={(filters)=>{
                        new OrderFilterLoadService().resetFilter((filters) => {
                            this.setState({filters:filters},()=>{
                                this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                            });
                        },QueueType.MAIN)
                    }}
                    onSortFieldChange={(val, property) => {
                        this.setState({[property]: val});
                    }}
                />}
                <Growl ref={(el) => this.growl = el} position="topright"/>

                <OrderDataTable showAll={false}
                                totalRecords={this.state.totalRecords} toogleFilter={() => this.setState({filter: !this.state.filter})}
                                headerTitle={"Zlecenia Robot DS "} orders={this.state.orders} rows={this.state.rows}
                                first={this.state.first} loading={this.state.loading} onPage={this.onPage}
                                onSort={(sortBy) => {
                                    this.setState({sortBy: sortBy});
                                    this.getOrders(this.state.filters, sortBy, 1, 0);
                                }}
                />

            </div>

        );
    }


}

export default BotDsOrders;

