import RestService from "./RestService";

const PATH = "/ragResponse";

const MonitoringSettingsService = {
    newMessage(message, onSuccess, onError) {
        RestService.post(`${PATH}/newMessage`, {message: message}, onSuccess, onError);
    },
    moveOrderingUp(id, onSuccess, onError) {
        RestService.get(`${PATH}/moveOrderingUp/${id}`, onSuccess, onError);
    },
    moveOrderingDown(id, onSuccess, onError) {
        RestService.get(`${PATH}/moveOrderingDown/${id}`, onSuccess, onError);
        RestService.getWithParams()
    },
    create(data, onSuccess, onError) {
        RestService.create(PATH, data, onSuccess, onError);
    },
    read(onSuccess, onError) {
        RestService.get(PATH, onSuccess, onError);
    },
    update(data, onSuccess, onError) {
        RestService.update(`${PATH}/${data["id"]}`, data, onSuccess, onError);
    },
    delete(data, onSuccess, onError) {
        RestService.delete(`${PATH}/${data["id"]}`, onSuccess, onError);
    },
    readGlobalConfig(onSuccess, onError) {
        RestService.get("/global/appUniversalConfiguration/", onSuccess, onError);
    },
    updateGlobalConfig(data, onSuccess, onError) {
        RestService.update("/global/appUniversalConfiguration/", data, onSuccess, onError);
    },
    updateRagResponseTime(data, onSuccess, onError) {
        RestService.update("/global/appUniversalConfiguration/ragResponseTime", data, onSuccess, onError);
    }

};

export default MonitoringSettingsService;