import queryString from "query-string";
import MonitoringOperationUtil from "../modules/monitoring/MonitoringOperationUtil";

class AvailableAssignmentService {

    isNoNeedToAssign(searchParam, order, monitoringPreviewOrBots) {
        if (this.isInHistory(searchParam)) {
            return true;
        } else if (monitoringPreviewOrBots) {
            return !this.validateMonitoringStatus(searchParam, order);
        }
        return false;
    }

    isInHistory(searchParam) {
        return queryString.parse(searchParam)["history"] === 'true';
    }

    validateMonitoringStatus(searchParam, order) {
        return this.isOplStatus(searchParam) && MonitoringOperationUtil.showMonitoringOperation(order);
    }

    isOplStatus(searchParam) {
        return queryString.parse(searchParam)["oplStatus"] === 'true';
    }

}

export default AvailableAssignmentService;
