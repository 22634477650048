import { Growl } from 'primereact/growl';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { FTTH_MONITORING_READONLY_ROLE_KEY, FTTH_MONITORING_ROLE_KEY } from "../../config/constants";
import CurrentAccountService from "../../services/CurrentAccountService";
import MonitoringService from "../../services/MonitoringService";
import {lastCreationDate, setHistoryMetadata, showMsgAboutOrderAssignmentChange} from '../../shared/Utils';
import '../../static/css/App.css';
import Order from "../other/Order";
import MonitoringOperation from "./MonitoringOperation";
import _ from "lodash";

class MonitoringOrder extends React.Component {


    //https://www.robinwieruch.de/react-warning-cant-call-setstate-on-an-unmounted-component/
    _isMounted = false;

    state = {
        order: {
            area: [],
            installers: [],
            businessProcess: {},
            technology: {},
            service: {},
            status: {},
            simInstallationStatus: {},
            rejectionReason: {},
            orderDevices: [],
            shouldAssign: false
        },
        events: []
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({shouldAssign: this.props.location && this.props.location.pathname && _.endsWith(this.props.location.pathname, "assign")});
        this.getOrder();
    }

    getOrder() {

        if (CurrentAccountService.hasRole(FTTH_MONITORING_ROLE_KEY) || CurrentAccountService.hasRole(FTTH_MONITORING_READONLY_ROLE_KEY)) {
            let modificationDateTime;
            if (this.state.order) {
                modificationDateTime = this.state.order.modificationDateTime;
            }
            MonitoringService.getByIdWithTimestamp(this.props.match.params.id, modificationDateTime,
                (data) => {
                    showMsgAboutOrderAssignmentChange(data, this.growl, this.state.order);
                    if (this._isMounted && data) {
                        setHistoryMetadata(data);
                        this.setState({ order: data });
                    }

                },
                (error) => {
                    console.log(`Error: ${error}`);
                });
        }
    }

    getEvents(id) {
        MonitoringService.getEventsByIdWithTimestamp(id, lastCreationDate(this.state.events),
            (data) => {
                if (data) this.setState({events: data})
            },
            (error) => {
                console.log("Error: " + error)
            })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {

        //https://reacttraining.com/react-router/web/example/url-params
        const { match } = this.props;

        return (
            <div >
                <>
                    <Growl ref={(el) => this.growl = el} position="topright"/>
                    <MonitoringOperation order={this.state.order} showOperationComment={this.props.showOperationComment}
                                         refreshOrder={() => this.getOrder()} shouldAssign={this.state.shouldAssign}/>
                    <Order refreshOrderIntervalMode={true} showOPLNotes={true} dataSource={this.state.order}
                           match={match} refreshOrder={() => {this.getOrder()}}
                           monitoringPreviewOrBots={true}
                           refreshEvents={() => this.getEvents(this.state.order.id)} events={this.state.events}/>
                </>

            </div>
        );

    }


}

export default withRouter(MonitoringOrder);
