import RestService from "./RestService";


const CURRENT_USER_KEY = "currentUser";


const CurrentAccountService = {


    hasRoleInArray(role, roles) {
        if (role && roles) {
            const result = roles.find((r) => r["name"] === role);
            return !!result;
        }

        return false;
    },

    hasRole(role) {
        const roles = CurrentAccountService.getRoles();
        if (role && roles) {
            const result = roles.find((r) => r["name"] === role);
            return !!result;
        }

        return false;
    },

    areAllowedRolesInCurrentAccountRoles(allowedRoles) {
        const currentAccountRoles = this.getRoles();
        if (allowedRoles && currentAccountRoles) {
            const result = allowedRoles.some(r => currentAccountRoles.find(ro => ro["name"] === r));
            return !!result;
        }
        return false;
    },

    getRoles() {
        const currentUser = localStorage.getItem(CURRENT_USER_KEY);
        if (currentUser){
            let allRoles = JSON.parse(currentUser).roles;
            const groups = JSON.parse(currentUser).groups;
            if (groups){
                for (let i=0; i<groups.length; i += 1){
                    allRoles = allRoles.concat(groups[i].roles);
                }
            }
            return allRoles;
        }
        return [];

    },

    getLogin() {
        const currentUser = localStorage.getItem(CURRENT_USER_KEY);
        return currentUser ? JSON.parse(currentUser).login : [];
    },
    getId() {
        const currentUser = localStorage.getItem(CURRENT_USER_KEY);
        return currentUser ? JSON.parse(currentUser).id : [];
    },

    fetchCurrentUser(onSuccess, onError) {

        RestService.get("/auth/user", (data) => {
            localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(data));
            if (onSuccess) {
                onSuccess(data);
            }
        }, () => {
            if (onError) {
                onError();
            }
        });


    },


};

export default CurrentAccountService;

