import React from "react";
import {withRouter} from 'react-router-dom';
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import '../../static/css/App.css';
import {Dialog} from "primereact/dialog";
import {Message} from "primereact/message";
import {Messages} from "primereact/messages";
import {defaultDetail, defaultSummary} from "../../config/constants";
import ErrorService from "../../services/ErrorService";
import AreaService from "../../services/dict/AreaService";

class AddArea extends React.Component {


    _isMounted = false;

    state = {
        successDialogVisible: false,
        id: null,
        nameError: null,
        verboseNameError: null,
        regionError: null,
        areaCode: null,
        item: {}

    }


    showError = (summary, detail) => {
        this.messages.show({severity: 'error', summary: summary, detail: detail});
    }

    componentDidMount() {
        this._isMounted = true;
    }


    hideSuccessDialog = () => {
        this.setState({successDialogVisible: false});
        this.props.history.push("/dictionary/area" );
    }
    showSuccessDialog = () => {
        this.setState({successDialogVisible: true});
    }

    saveValueFromInput(event, property) {
        const val = event.target ? event.target.value : null;
        const stateVal = this.state.item;
        stateVal[property] = val;
        this.setState({item: stateVal});
    }

    validate() {
        let isValid = true;

        if (!this.state.item.name || this.state.item.name.length < 1) {
            this.setState({nameError: "Nazwa nie może być pusta."});
            isValid = false;
        }

        if (!this.state.item.areaCode || this.state.item.areaCode.length < 1) {
            this.setState({areaCodeError: "Kod nie może być pusty."});
            isValid = false;
        }

        if (!this.state.item.verboseName || this.state.item.verboseName.length < 1) {
            this.setState({verboseNameError: "Nazwa wyświetlana nie może być pusta."});
            isValid = false;
        }

        return isValid;
    }

    add() {
        return () => {
            this.setState({nameError:null,verboseNameError:null,regionError:null,areaCodeError:null});
            const isValid = this.validate();

            if (isValid) {
                AreaService.create(this.state.item,
                    (data) => {
                        if (this._isMounted) {
                            this.setState({id: data.id});
                            this.showSuccessDialog();
                        }
                    },
                    (error) => {
                        ErrorService.logServerError(error);
                        this.showError(defaultSummary, defaultDetail);
                    });
            }

        };
    }


    render() {


        return <div className="p-grid nogutter  p-component p-col-8">

            <div className="p-grid nogutter p-col-12">
                <Messages ref={(el) => this.messages = el}/>
            </div>

            <div className="p-grid nogutter p-col-12">
                <h3>Dodawanie nowej strefy:</h3>
            </div>

            <div className="p-grid nogutter p-col-12">
                <label className="p-col-2 o-label">Nazwa:</label>
                <span className="o-value">
                    <InputTextarea rows={1} cols={70} value={this.state.item.title}
                        autoResize={true}
                        readOnly={false} onChange={(event) => this.saveValueFromInput(event, "name")}/>
                </span>
                {this.state.nameError &&
                <div className="validation-msg o-value"><Message severity="error" text={this.state.nameError}/></div>}
            </div>

            <div className="p-grid nogutter p-col-12">
                <label className="p-col-2 o-label">Nazwa wyświetlana:</label>
                <span className="o-value">
                    <InputTextarea rows={4} cols={70} value={this.state.item.verboseName} autoResize={true}
                        readOnly={false} onChange={(event) => this.saveValueFromInput(event, "verboseName")}/>
                </span>
                {this.state.verboseNameError &&
                <div className="validation-msg o-value"><Message severity="error" text={this.state.verboseNameError}/></div>}
            </div>

            <div className="p-grid nogutter p-col-12">
                <label className="p-col-2 o-label">Kod OCL:</label>
                <span className="o-value">
                    <InputTextarea rows={1} cols={70} value={this.state.item.areaCode}
                        autoResize={true}
                        readOnly={false} onChange={(event) => this.saveValueFromInput(event, "areaCode")}/>
                </span>
                {this.state.areaCodeError &&
                <div className="validation-msg o-value"><Message severity="error" text={this.state.areaCodeError}/></div>}
            </div>

            <Button style={{"marginLeft": "10px", "marginTop": "5px"}} className="o-value" onClick={this.add()} label={"Dodaj"}/>

            <Dialog header="Operacja wykonana prawidłowo" visible={this.state.successDialogVisible}
                onHide={this.hideSuccessDialog}>
                <div className=" p-col-12 p-col p-grid p-nogutter p-justify-center p-align-center">
                    <Button label="OK" onClick={this.hideSuccessDialog}/>
                </div>
            </Dialog>

        </div>;
    }



}

export default withRouter(AddArea);
