import React from 'react'
import DeviceTable from './DeviceTable';
import ActivationHistoryData from "./ActivationHistoryData";

class ActivationHistory extends React.Component {

    state = {
        rows: 100,
        entries: []
    }

    componentDidMount() {
    }

    render() {
        const { header, item, data, metadata, showHistoryActivationHistory } = this.props
        let lastData = data ? data[0] : [];
        let devices = (lastData && lastData.orderDevices) ? data[0].orderDevices : [];
        return (
            <div className="rTable p-nogutter">
                <div className="sectionHeader">{header}</div>
                <div className="rTable">
                    <DeviceTable devices={devices} rows={this.state.rows}
                        metadata={metadata} showHistoryActivationHistory={showHistoryActivationHistory} />
                </div>
                <div className="rTable">
                    <ActivationHistoryData activationData={lastData} />
                </div>

                <div className="rTable marginTopBottom">
                        <div className="divInlineFlexFooter">
                            <div className={"buttonL equal"}/>
                            <div className="rTableCell">Urządzenia, które nie uległy zmianie</div>
                            <div className={"buttonL notEqual"}/>
                            <div className="rTableCell"> Nowe lub zmodyfikowane urządzenia</div>
                        </div>
                </div>
            </div>

        );
    }
}

export default ActivationHistory;
