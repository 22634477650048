import RestService from "../RestService";

const PATH = "/serviceOption";

const ServiceOptionService = {


    add(data, onSuccess, onError) {
        RestService.create(PATH, data, onSuccess, onError);
    },

    update(data, onSuccess, onError) {

        RestService.update(`${PATH}/${data["id"]}`, data, onSuccess, onError);
    },

    remove(data, onSuccess, onError) {

        RestService.delete(`${PATH}/${data["id"]}`, onSuccess, onError);
    },

    getAll(onSuccess, onError) {

        return RestService.get(PATH, onSuccess, onError);
    },


};

export default ServiceOptionService;

