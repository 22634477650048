import axios from "axios/index";
import RestService from "../RestService";

const PATH = "/area";

const AreaService = {

    create(data, onSuccess, onError) {
        RestService.create(PATH, data, onSuccess, onError);
    },

    update(data, onSuccess, onError) {
        RestService.update(`${PATH}/${data["id"]}`, data, onSuccess, onError);
    },

    findAllWithUrl: function (resultPath, onSuccess, onError) {
        return axios.get(resultPath)
            .then(function (response) {
                if (response.status === 200) {
                    onSuccess(response.data);
                } else {
                    const error = `Incorrect response status ${response.status}`;
                    if (onError) {
                        onError(error);
                    }
                }

            })
            .catch(function (error) {
                if (onError) {
                    onError(error);
                }
            });
    },

    getAll(onSuccess, onError) {

        return this.findAllWithUrl(PATH, onSuccess, onError);
    },

    getLikeVerboseNameWithLimit(verboseName, limit, onSuccess, onError) {

        return this.findAllWithUrl(`${PATH}/verboseName/${verboseName}?limit=${limit}`, onSuccess, onError);
    },

    delete(data, onSuccess, onError) {

        axios.delete(`${PATH}/${data["id"]}`)
            .then(function (response) {
                console.log(`OK${response}`);
                if (response.status === 200) {
                    if (onSuccess) {
                        onSuccess();
                    }
                } else {
                    console.log("Error status");
                    if (onError) {
                        onError();
                    }
                }
            })
            .catch(function (error) {
                console.log(`Error${error}`);
                if (onError) {
                    onError(error);
                }
            });

    },


};

export default AreaService;

