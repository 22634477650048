import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";

import '../../static/css/App.css';
import Main from "./Main";
import Login from ".././auth/Login";
import PrivateRoute from "../../shared/PrivateRoute";
import 'moment-timezone';
// import 'primereact/resources/themes/luna-blue/theme.css'
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import ChangePassword from '../auth/ChangePassword';

class App extends Component {
    render() {
        //https://stackoverflow.com/questions/47476186/when-user-is-not-logged-in-redirect-to-login-reactjs
        // https://reacttraining.com/react-router/web/guides/quick-start
        return (
            //<BrowserRouter basename={process.env.REACT_APP_ROUTER_BASE || ''} >
            /* change "homepage" attribute in package.json*/
            // <BrowserRouter basename={'/dpPanel/prodsupport'} >
            <BrowserRouter basename={'/dpPanel'} >
                <Switch>
                    <Route exact path="/login" component={Login}/>
                    <Route exact path="/changePassword" component={ChangePassword}/>
                    <PrivateRoute component={Main}/>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
