import RestService from "./RestService";

//https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript


const PATH = "/ds/bot/preview/order";

const BotDsPreviewService = {


    getById(id, onSuccess, onError) {
        return RestService.get(PATH + "/" + id, onSuccess, onError);
    },


    getMainQueueOrders(params, pageNumber, pageSize, onSuccess, onError) {

        RestService.getWithPagination(PATH, params, pageNumber, pageSize, onSuccess, onError);
    },

    getHistoryOrders(params, pageNumber, pageSize, onSuccess, onError) {

        RestService.getWithPagination(PATH + "/history", params, pageNumber, pageSize, onSuccess, onError);
    },

    getEventsByIdWithTimestamp(id, modificationDateTime, onSuccess, onError) {
        let params = { 'timestamp': modificationDateTime };
        return RestService.getWithParams(PATH + "/" + id + "/events", params, onSuccess, onError);
    },

};

export default BotDsPreviewService;

