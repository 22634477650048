import '../../../static/css/App.css';
import React from 'react';
import GlobalPropertiesService from "../../../services/dict/GlobalPropertiesService";
import GlobalDictionary from "./GlobalDictionary";


class GlobalProperties extends React.Component {

    render() {

        return (

            <GlobalDictionary title="Globalne ustawienia"
                fetchValues={(onSuccess, onError) => {
                    GlobalPropertiesService.getAll((data) => {
                        onSuccess(data);
                    },
                    (error) => {
                        onError(error);
                    });
                }}
                update={(data, onSuccess, onError) => {
                    GlobalPropertiesService.update(data, onSuccess, onError);
                }}

                add={(data, onSuccess) => {
                    GlobalPropertiesService.add(data, onSuccess);
                }}/>

        );
    }

}

export default GlobalProperties;

