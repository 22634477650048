import React from 'react';
import ReactDOM from 'react-dom';
import App from "./modules/main/App";
import "./config/axios";


// ========================================

ReactDOM.render(

    <App/>,
    document.getElementById('root')
);
