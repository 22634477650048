import '../../static/css/App.css';
import React from 'react';
import Dictionary from "./Dictionary";
import AreaService from "../../services/dict/AreaService";


class Area extends React.Component {

    render() {
        return (
            <Dictionary
                title="Strefy utrzymaniowe"
                areaMode={true}
                fetchValues={(onSuccess, onError) => {
                    AreaService.getAll((data) => {
                        onSuccess(data);
                    },
                    (error) => {
                        onError(error);
                    });
                }}
                addUrl={"/dictionary/area/add"}
                update={(data, onSuccess) => {
                    AreaService.update(data, onSuccess);
                }}
                remove={(data, onSuccess, onError) => {
                    AreaService.delete(data, onSuccess, onError);
                }}
            />
        );
    }

}

export default Area;

