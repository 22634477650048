import React from "react";
import Moment from "react-moment";
import {
    DATE_TIME_FORMAT,
    MIN_DATE_IN_MONTH,
    NEW_ORDER_DIALOG_LIFE,
    NO_AREA,
    NOTIFICATION_LIMIT
} from "../config/constants";
import AuthService from "../services/AuthService";
// import soundfile from "../static/sound/eventually.mp3"
// import soundfile from "../static/sound/accomplished.mp3"
import soundfile from "../static/sound/slow-spring-board.mp3";
import CurrentAccountService from "../services/CurrentAccountService";
import ActivationHistoryMetadataCreator from "../services/ActivationHistoryMetadataCreator";
import _ from "lodash";
import ChatService from "../services/ChatService";
import ErrorService from "../services/ErrorService";

export const updateTableWithNewData = (thisPointer, page, startIndex) => {
    thisPointer.setState({
        orders: page.content,
        totalRecords: page.totalElements,
        loading: false,
        first: startIndex,
    });
};

export const onError = (thisPointer, error) => {
    thisPointer.setState({
        loading: false
    });
    AuthService.clearLocalStorageAndForwardToLoginWhen401(thisPointer, error);
    console.log(`Error:${error}`);
};

export const DateTimeField = ({ dateTimeString }) => {

    return (
        <Moment format={DATE_TIME_FORMAT}>
            {dateTimeString}
        </Moment>
    );
};

export function createFirstNameAndLastNameLoginSt(account) {
    return account ? `${account.firstName} ${account.lastName} (${account.login})` : "";
}

export function createFirstNameAndLastNameIdentifierSt(installer) {
    let firstName = installer.firstName ? installer.firstName: "";
    let lastName = installer.lastName ? installer.lastName: "";
    return installer ? `${firstName} ${lastName} (${installer.identifier})` : "";
}

export function createFirstNameAndLastNamePhoneNumberSt(account) {
    return account ? `${account.firstName} ${account.lastName} (${account.phoneNumber})` : "";
}

export function sortByDate(array, key) {
    if (array) {
        return array.sort(function (first, second) {
            if (second[key]) {
                let x = new Date(first[key]);
                let y = new Date(second[key]);
                return (y != null) - (x != null) || y - x;
            } else {
                return -1;
            }
        });
    }
}


export const debug = (event) => {
    console.log(`ON PAGE:${JSON.stringify(event)}`);
    console.log(`ON PAGE: filters${JSON.stringify(this.state.filters)}`);
    console.log(`ON PAGE: sortField ${JSON.stringify(this.state.sortField)}`);
    console.log(`ON PAGE: sortOrder ${JSON.stringify(this.state.sortOrder)}`);
};

const a = new AudioContext(); // browsers limit the number of concurrent audio contexts, so you better re-use'em

function beep(vol, freq, duration) {
    const v = a.createOscillator();
    const u = a.createGain();
    v.connect(u);
    v.frequency.value = freq;
    v.type = "square";
    u.connect(a.destination);
    u.gain.value = vol * 0.01;
    v.start(a.currentTime);
    v.stop(a.currentTime + duration * 0.001);
}

export const soundNotification = () => {
    // beep(999, 400, 800);
    var audio = new Audio(soundfile);
    audio.play();
};


export function chat(orderId, header) {
    ChatService.chat(orderId, (error) => {
        ErrorService.logServerError(error);
        if (header) {
            header.showError();
        }
    });
}

// https://developers.google.com/web/fundamentals/push-notifications/display-a-notification
// https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API
function notifyMe(title, body, orderId, header) {
    let notification = null;

    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
        // If it's okay let's create a notification
        var options = {
            body: body,
            actions: [],
            data: "WWWWWW"
        };
        notification = new Notification(title, options);
        // ServiceWorkerRegistration.showNotification("sss", options);
        // ServiceWorkerRegistration.showNotification(
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
                var options = {
                    body: body,
                    actions: [],
                    data: "WWWWWW"
                };
                notification = new Notification(title, options);
            }
        });
    }

    if (orderId && notification) {
        notification.onclick = function(event) {
            event.preventDefault(); // prevent the browser from focusing the Notification's tab
            chat(orderId, header);
        }
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
}

export const notify = (title, body, orderId, header) => {
    notifyMe(title, body, orderId, header);
};

export const showQueueNewOrdersNotifications = (currentObj, getNewOrders) => {
    let modificationDateTime = currentObj.state.previousModificationTime;
    // After table first table load - setting modificationDateTime and skipping downloading new orders
    // previousModificationTime is setting(new) when view is loaded (should be always not null)
    // but to be sure setting  previousModificationTime
    if (!modificationDateTime) {
        currentObj.setState({ previousModificationTime: new Date() });
    } else {
        getNewOrders(modificationDateTime, (data) => {
            showNewOrdersNotificationAndGetLastModificationDateTime(data, modificationDateTime,
                currentObj.growl, (newModificationDateTime) => {
                    currentObj.setState({ previousModificationTime: newModificationDateTime });
                });
        },
            (error) => {
            });
    }

};

export const showNewOrdersNotificationAndGetLastModificationDateTime = (data, previousModificationTime,
    growl, setNewModificationTime) => {

    function showNotifications(filteredData, notificationsLength) {
        if (data && data.length > 0) {
            soundNotification();
            notify("Nowe/zmodyfikowane", `Liczba zleceń = ${notificationsLength}`);
            growl.show({
                life: NEW_ORDER_DIALOG_LIFE,
                severity: 'info',
                summary: "Nowe/zaktualizowane zlecenia",
                detail: `Liczba zleceń = ${notificationsLength}`
            });
            for (let i = 0; i < filteredData.length; i += 1) {
                growl.show({
                    life: NEW_ORDER_DIALOG_LIFE,
                    severity: 'info',
                    summary: "Nowe/zmodyfikowane zlecenie",
                    detail: `Numer zlecenia: ${data[i]["number"]}`
                });
            }

        }
    }

    function getLastModificationDateTime() {
        let latest = data[0];
        let i;
        for (i = 1; i < data.length; i += 1) {
            const c = data[i];
            if (c.modificationDateTime > latest.modificationDateTime) {
                latest = c;
            }
        }
        return latest["modificationDateTime"];
    }

    if (Array.isArray(data) && data.length > 0) {
        let dataLength = data.length;
        let filteredData = data.slice(0, Math.min(NOTIFICATION_LIMIT, dataLength));
        showNotifications.call(this, filteredData, dataLength);

        const lastModificationDateTime = getLastModificationDateTime();
        setNewModificationTime(lastModificationDateTime);
    }

};

export function isOrderListNotEmpty(orders) {
    return orders && orders.length > 0;
}

export function nowMinusDays(days) {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date;
}

export function nowMinusMonths(months) {
    const date = new Date();
    date.setMonth(date.getMonth() - months);
    return date;
}

export function minDate() {
    const date = new Date();
    date.setMonth(date.getMonth() - MIN_DATE_IN_MONTH);
    return date;
}

export function defaultMinDate() {
    const date = new Date();
    date.setMonth(date.getMonth() - 3);
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    firstDay.setMinutes(0);
    firstDay.setHours(0);
    return firstDay;
}

export function filtersNotEmptySkipModificationDateTimeFrom(filters) {
    let newFilters = Object.assign({}, filters);
    delete newFilters['modificationDateTimeFrom'];
    delete newFilters['_creationDateTime'];
    return filtersNotEmpty(newFilters);
}

export function filtersNotEmpty(filters) {
    if (filters) {
        for (const key in filters) {
            const val = filters[key];
            if (val) {
                if (Array.isArray(val)) {
                    if (val.length > 0) {
                        return true;
                    }

                } else if (typeof val === 'object') {
                    if (Object.keys(val).length > 0 && val.constructor === Object) {
                        return true;
                    }

                } else {
                    return true;
                }

            }
        }
    }
    return false;
}


/**
 * Example: parentObject = this.state
 * value = 5
 * jsonPath=account.installer.id
 * resp = {
 *     account:{
 *         installer:{
 *             id:5
 *         }
 *     }
 * }
 * @param parentObject
 * @param value
 * @param jsonPath
 */

export const JSONUtil = {

    getNestedJsonObject(parentObject, value, jsonPath) {
        let resp = {};
        const names = jsonPath.split(".");
        if (names.length > 1) {
            let firstElement = parentObject ? parentObject[names[0]] : {};
            if (!firstElement) {
                firstElement = {};
            }

            let currentElement = firstElement;
            for (let i = 1; i < names.length; i += 1) {
                if (i === names.length - 1) {
                    currentElement[names[i]] = value;
                } else {
                    let nextElement = currentElement[names[i]];
                    if (!nextElement) {
                        nextElement = {};
                    }
                    currentElement[names[i]] = nextElement;
                    currentElement = nextElement;
                }
            }
            resp = {
                [names[0]]: firstElement
            };
        } else {
            resp = { [jsonPath]: value };
        }
        return resp;
    },

};


export const OrderUtil = {

    installers(order) {
        if (!order.installers) {
            return "";
        }

        return order.installers.filter(installer => !!installer.identifier).map(installer => installer.identifier)
            .join(', ');
    },

    prepareInstallerTextWithSeparator(installer, isLast) {
        let installerText = this.prepareInstallerText(installer);
        if (isLast) {
            return installerText;
        } else {
            return installerText + ',';
        }
    },

    prepareInstallerText(installer) {
        if (installer.firstName && installer.lastName) {
            return installer.identifier + ' (' + installer.firstName + ' ' + installer.lastName + ')'
        }
        return installer.identifier;
    },

    prepareAccountText(account) {
       return account ? `${account['firstName']} ${account['lastName']} (${account['login']})` : "";
    },

    wasCurrentOPLEverAssignedToOrder(order) {
        if (order) {
            const assignedOplUsers = order.assignedOplUsers;
            if (assignedOplUsers) {
                return assignedOplUsers.map(oplUser => oplUser.id).includes(CurrentAccountService.getId());
            }
        }
        return false;
    },

    isCurrentOPLActuallyAssignedToOrder(order) {
        if (order) {
            const assignedOplUser = order.assignedOplUser;
            if (assignedOplUser) {
                return CurrentAccountService.getId() === order.assignedOplUser.id;
            }
        }
        return false;
    },

    isEmpty(installers) {
        return typeof installers === 'undefined' || installers.length <= 0;
    },

    isCreatedDuringActivation(order) {
        return order.area.verboseName === NO_AREA;
    }

};

export const showMsgAboutOrderAssignmentChange = (data, growl, order) => {
    if (data && order && order.assignedOplUser) {
        const assignedUserId = order.assignedOplUser.id;
        if (!data.assignedOplUser || data.assignedOplUser.id !== assignedUserId) {
            let message = "Nastąpiła zmiana przypisanego użytkownika do zlecenia.";
            const currentUserWasAssigned = order.assignedOplUser.id === CurrentAccountService.getId();
            if (currentUserWasAssigned) {
                if (order && order.events) {
                    const event = order.events.find(it => {
                        return it.type === 'OPL_UNASSIGNED_TO_ORDER' || it.type === 'AUTOMATIC_OPL_UNASSIGN_FROM_ORDER_EVENT';
                    });
                    if (event) {
                        message += `Zostałeś odsunięty od zlecenia przez: ${event.account.login}`;
                    }
                }
            }
            if (growl) {
                growl.show({
                    life: NEW_ORDER_DIALOG_LIFE,
                    severity: 'info',
                    summary: "Zlecenie zostało zaktualizowane",
                    detail: message
                });
            }
            if (currentUserWasAssigned) {
                alert(message);
            }
        }
    }
};

export const setTotalRecordsInPagination = (object, data, rowsPerPage, pageNumber, withTotalCount = false) => {
    if (!withTotalCount) {
        if (data && data.length) {
            let pageDataLength = data.length;
            if (pageDataLength === rowsPerPage) {
                object.setState({ totalRecords: rowsPerPage * pageNumber + 1 });
            } else {
                object.setState({ totalRecords: rowsPerPage * pageNumber });
            }
        }
    }
}

export const lastCreationDate = (events) => {
    if (events && events.length > 0) {
        return events.reduce((x, y) => {
            return new Date(x.creationDate) > new Date(y.creationDate) ? x.creationDate : y.creationDate;
        });
    }
}

export function setHistoryMetadata(data) {
    if (data && !_.isEmpty(data.activationHistory)) {
        let generatedMetamodel = ActivationHistoryMetadataCreator.generateMetamodel(data, data.activationHistory);
        if (generatedMetamodel) {
            data["metadata"] = generatedMetamodel;
        }
    }
}